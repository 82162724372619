import { useAppDispatch, useNotification } from "@app/hooks";
import { useUpdateQualificationMutation } from "@features/general/generalAPI";
import { getQualificationListSilently } from "@features/general/generalSlice";
import { useEffect } from "react";

const useUpdateQualification = ({ callback }: { callback: () => any }) => {
  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();
  const [updateQualification, { isLoading: isUpdating, isSuccess, isError }] =
    useUpdateQualificationMutation();

  useEffect(() => {
    if (isSuccess) {
      showNotification("Qualification is updated");
      dispatch(getQualificationListSilently());
      callback();
      return;
    }
    if (isError) {
      showNotification("Cannot update this qualification.", "error");
    }
  }, [isSuccess, isError, showNotification, callback, dispatch]);

  return {
    updateQualification,
    isUpdating,
  };
};

export default useUpdateQualification;
