import { createContext } from "react";

export type ThemeModeType = "dark" | "light";

export type ThemeModeContext = {
  mode?: ThemeModeType;
  switchTheme: (mode?: ThemeModeType) => any;
};

const ThemeModeContext = createContext<ThemeModeContext>({
  switchTheme: () => null,
});

export default ThemeModeContext;
