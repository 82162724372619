import {
  Theme,
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import { memo } from "react";
import { ReactElement } from "react";

const useStyles = makeStyles(
  (theme: Theme) => ({
    progress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -10,
      marginLeft: -10,
    },
    progressLarge: {
      marginTop: -11,
      marginLeft: -11,
    },
    progressSmall: {
      marginTop: -9,
      marginLeft: -9,
    },
  }),
  {
    classNamePrefix: "NSButton",
  }
);

interface NSButtonProps extends ButtonProps {
  loading?: boolean;
}

const NSButton = ({
  children,
  loading = false,
  ...props
}: NSButtonProps): ReactElement => {
  const classes = useStyles();

  const circularSize = useMemo(() => {
    switch (props.size) {
      case "small":
        return 18;
      case "large":
        return 22;
      default:
        return 20;
    }
  }, [props.size]);

  return (
    <Button variant="contained" {...props} disabled={loading || props.disabled}>
      {children}
      {loading && (
        <CircularProgress
          size={circularSize}
          className={clsx(classes.progress, {
            [classes.progressLarge]: props.size === "large",
            [classes.progressSmall]: props.size === "small",
          })}
        />
      )}
    </Button>
  );
};

export default memo(NSButton);
