import { useNotification, useScrollToElementByHash } from "@app/hooks";
import { Assessor, Nullable } from "@app/models";
import useConfirmation from "@components/Confirmation/useConfirmation";
import buildForm, { convertLabelToId } from "@components/FormBuilder";
import NSButton from "@components/NSButton";
import NSFormToolbar from "@components/NSFormToolbar";
import {
  useDeleteAssessorMutation,
  useGetAssessorQuery,
  useUpdateAssessorMutation,
} from "@features/assessor/assessorAPI";
import {
  Box,
  List,
  ListItem,
  Paper,
  Popover,
  Tab,
  Tabs,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useAssessorFormTab from "../contexts/AssessorFormTab";
import validateMandatoryFields from "../helpers/validateMandatoryFields";
import useAssessorFormDataPreparation from "../hooks/useAssessorFormDataPreparation";
import useAssessorFormDefinition from "../hooks/useAssessorFormDefinition";
import useAssessorInfoSocket from "../hooks/useAssessorInfoSocket";
import Portrait from "../Portrait";

const AssessorInfoContent = () => {
  const { id } = useParams<Record<"id", string>>();

  const {
    data: queryResponse,
    isFetching,
    isSuccess: isQuerySuccess,
    isError: isQueryError,
  } = useGetAssessorQuery(id);

  useScrollToElementByHash({ ready: isQuerySuccess, topSpacing: 108 });

  const [
    updateAssessor,
    {
      isLoading: isUpdating,
      data: mutationResponse,
      isSuccess: isMutationSuccess,
      isError: isMutationError,
      error: mutationError,
    },
  ] = useUpdateAssessorMutation();

  const [
    deleteAssessor,
    {
      isLoading: isDeleting,
      data: deletionResponse,
      isSuccess: isDeletionSuccess,
      isError: isDeletionError,
      error: deletionError,
    },
  ] = useDeleteAssessorMutation();

  const { state } = useLocation<Record<"assessor", Nullable<Assessor>>>();
  const [assessor, setAssessor] = useState<Nullable<Assessor>>(
    state?.assessor ?? null
  );
  const { showConfirmation } = useConfirmation();
  const { showNotification } = useNotification();
  const { formSections, formFields, formTabs } = useAssessorFormDefinition();
  const { prepareDataToUpdate } = useAssessorFormDataPreparation(formFields);
  useAssessorInfoSocket(assessor?.id);

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleShowMoreClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleShowMoreClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { tab, changeTab } = useAssessorFormTab();

  const handleChange = (
    _: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    changeTab(newValue);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    const sectionId = convertLabelToId(`assessor tab content ${tab}`);
    const tabSectionEl = document.getElementById(sectionId);
    if (!tabSectionEl) {
      return;
    }
    const textareaEls = Array.from(
      tabSectionEl.getElementsByTagName("textarea")
    ).filter((el) => !el.readOnly && el.style.height === "0px");
    if (textareaEls.length === 0) {
      return;
    }
    timeoutId = setTimeout(() => {
      textareaEls.forEach((el) => {
        el.focus();
        el.blur();
      });
    });
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [tab]);

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm();

  const watchStatus = watch("status", "");

  useEffect(() => {
    if (watchStatus) {
      clearErrors();
    }
  }, [watchStatus, clearErrors]);

  const setFormData = useCallback(
    (data: Assessor & Record<string, any>) => {
      formFields.forEach((f) => {
        if (!f.name) {
          return;
        }
        setValue(f.name, data[f.name] ?? "");
      });
    },
    [setValue, formFields]
  );

  /**
   * Update assessor from useGetAssessorQuery response
   */
  useEffect(() => {
    if (isFetching || !queryResponse?.assessor) {
      return;
    }
    setAssessor(queryResponse.assessor);
    setFormData(queryResponse.assessor);
  }, [queryResponse, isFetching, setFormData]);

  /**
   * Show notification for assessor query result
   */
  useEffect(() => {
    if (isQueryError || (isQuerySuccess && !queryResponse?.assessor)) {
      showNotification(
        queryResponse?.message ||
          "Unknown error! Cannot get this assessor details",
        "error"
      );
      history.push("/assessor/list");
    }
  }, [isQueryError, isQuerySuccess, queryResponse, history, showNotification]);

  /**
   * Show notification for assessor mutation result
   */
  useEffect(() => {
    if (isMutationSuccess || isMutationError) {
      if (mutationResponse?.assessor) {
        showNotification("Assessor is updated successfully");
        setAssessor(mutationResponse.assessor);
        setFormData(mutationResponse.assessor);
        return;
      }
      showNotification(
        mutationResponse?.message ||
          (mutationError as any)?.error.message ||
          "Unknown error! Cannot update this assessor",
        "error"
      );
    }
  }, [
    isMutationSuccess,
    isMutationError,
    mutationResponse,
    mutationError,
    setFormData,
    showNotification,
  ]);

  /**
   * Show notification for assessor deletion result
   */
  useEffect(() => {
    if (isDeletionSuccess || isDeletionError) {
      if (deletionResponse) {
        showNotification("Assessor is deleted successfully");
        history.push("/assessor/list");
        return;
      }
      showNotification(
        (deletionError as any)?.message ||
          "Unknown error! Cannot update this user.",
        "error"
      );
    }
  }, [
    deletionResponse,
    history,
    isDeletionError,
    isDeletionSuccess,
    deletionError,
    showNotification,
  ]);

  const submitUpdateRequest = useCallback(
    (data) => {
      if (!assessor?.id) {
        return;
      }
      const preparedData = prepareDataToUpdate(
        { ...data, id: assessor.id },
        { originalData: assessor }
      );
      const missingFields = validateMandatoryFields(data, data.status);
      if (missingFields.length > 0) {
        showNotification(
          "Mandatory fields are missing, please fill in all of them",
          "error"
        );
        missingFields.forEach((fieldName, idx) => {
          setTimeout(() => {
            setError(
              fieldName,
              {
                type: "required",
                message: "This field is required",
              },
              {
                shouldFocus: idx === 0,
              }
            );
          });
        });
        return;
      }
      updateAssessor(preparedData);
    },
    [updateAssessor, assessor, prepareDataToUpdate, setError, showNotification]
  );

  const submitDeletionRequest = useCallback(() => {
    const onConfirm = () => {
      if (!assessor) {
        return;
      }
      deleteAssessor(assessor.id);
    };

    handleShowMoreClose();
    showConfirmation({
      title: "Are you sure?",
      content:
        "Please double check before proceed. This assessor will not be in the list anymore.",
      onConfirm,
    });
  }, [handleShowMoreClose, assessor, deleteAssessor, showConfirmation]);

  return (
    <Box display="flex" flexDirection="column">
      <NSFormToolbar
        title="Assessor details"
        loading={isFetching || isDeleting}
      >
        {!isFetching && !isDeleting && (
          <>
            <NSButton
              loading={isUpdating}
              color="primary"
              onClick={handleSubmit(submitUpdateRequest)}
            >
              Update
            </NSButton>
            <NSButton className="pl-2 pr-2 ml-3" onClick={handleShowMoreClick}>
              <MoreHorizIcon />
            </NSButton>
            <Popover
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleShowMoreClose}
              PaperProps={{
                style: {
                  minWidth: 100,
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List dense>
                <ListItem
                  button
                  onClick={submitDeletionRequest}
                  className="color-error"
                >
                  DELETE ASSESSOR
                </ListItem>
              </List>
            </Popover>
          </>
        )}
      </NSFormToolbar>
      <Box display="flex" mx={-1}>
        <Box mx={1}>
          <Portrait assessor={assessor} />
        </Box>
        <Box flex={1} mx={1} overflow="auto">
          <Paper className="mb-2 overflow-hidden">
            <Tabs value={tab} onChange={handleChange} indicatorColor="primary">
              {formTabs.map((ft, idx) => (
                <Tab
                  id={`tab-${idx}`}
                  key={convertLabelToId(ft)}
                  label={ft}
                  disabled={isFetching || isDeleting}
                />
              ))}
            </Tabs>
          </Paper>
          {formSections.map((fs, idx) => {
            const sectionId = convertLabelToId(`assessor tab content ${idx}`);
            return (
              <Box
                id={sectionId}
                key={sectionId}
                component={Paper}
                p={2}
                display={tab === idx ? undefined : "none"}
                style={
                  isFetching || isDeleting
                    ? { pointerEvents: "none", opacity: 0.7 }
                    : undefined
                }
              >
                {buildForm({ control, errors, sections: fs })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default AssessorInfoContent;
