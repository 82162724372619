import { convertLabelToId } from "@components/FormBuilder";
import NSDivider from "@components/NSDivider";
import { useGetAssessorSectionsCompletionQuery } from "@features/assessor/assessorAPI";
import { Box, Link } from "@material-ui/core";
import clsx from "clsx";
import { Fragment, useCallback } from "react";
import { useParams } from "react-router-dom";
import useAssessorFormTab from "../contexts/AssessorFormTab";
import useAssessorFormDefinition from "../hooks/useAssessorFormDefinition";

const FormMap = () => {
  const { id } = useParams<Record<"id", string>>();
  const { formSections, formTabs } = useAssessorFormDefinition();
  const { data } = useGetAssessorSectionsCompletionQuery(id);
  const { changeTab } = useAssessorFormTab();

  const getSectionCompletion = useCallback(
    (sectionName: string) => {
      if (!data?.completions || !data.completions[sectionName]) {
        return {
          totalFields: 0,
          totalCompleteFields: 0,
          totalIncompleteFields: 0,
        };
      }
      const { completeFields, incompleteFields } =
        data.completions[sectionName];
      return {
        totalFields: completeFields.length + incompleteFields.length,
        totalCompleteFields: completeFields.length,
        totalIncompleteFields: incompleteFields.length,
      };
    },
    [data]
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      {formTabs.map((tabLabel, tabIdx) => {
        return (
          <Fragment key={`form-map--${convertLabelToId(tabLabel)}`}>
            <NSDivider
              label={tabLabel}
              className={clsx({
                "mt-2": tabIdx > 0,
              })}
            />
            {formSections[tabIdx].map((section) => {
              const sectionId = convertLabelToId(section.label);
              const { totalFields, totalCompleteFields } =
                getSectionCompletion(sectionId);
              return (
                <Link
                  key={sectionId}
                  className={clsx("mt-1.5", "cursor-pointer", {
                    "color-success": totalCompleteFields === totalFields,
                    "color-error": totalCompleteFields < totalFields,
                  })}
                  variant="body2"
                  onClick={() => changeTab(tabIdx, sectionId)}
                >
                  • {section.label}
                  {totalFields > 0 &&
                    ` (${totalCompleteFields}/${totalFields})`}
                </Link>
              );
            })}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default FormMap;
