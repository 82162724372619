/* eslint-disable no-useless-escape */

import { FormDataFieldType, FormDataType } from "@components/FormBuilder";
import { format, isFuture, isPast, isValid } from "date-fns";
import produce from "immer";
import { BaseModel } from "./models";

type DateValidationOptions =
  | {
      disableFuture?: boolean;
      disablePast?: boolean;
    }
  | undefined;

export const isValidDate = (
  input: string | Date | any,
  options?: DateValidationOptions
) => {
  if (typeof input === "string") {
    return /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(
      input
    );
  }

  const inputObj = new Date(input);
  let validationResult = isValid(inputObj);

  if (!validationResult) return validationResult;

  if (options?.disableFuture) {
    validationResult = !isFuture(inputObj);
  }
  if (options?.disablePast) {
    validationResult = !isPast(inputObj);
  }

  return validationResult;
};

export const getDataPatch = <T>(
  originalData: T,
  currentData: T
): Partial<T> => {
  const original = originalData as T & BaseModel;

  const current = currentData as T & BaseModel;

  const keys = Object.keys(original);

  const patch = keys.reduce<Partial<T>>((result, key) => {
    if (original[key] !== current[key]) {
      return {
        ...result,
        [key]: current[key],
      };
    }
    return result;
  }, {});

  return patch;
};

export const convertObjectToQuery = (
  inputObject?:
    | Record<string, string | number | boolean>
    | null
    | undefined
    | void
) => {
  if (!inputObject) return "";
  const keys = Object.keys(inputObject);

  return keys.reduce((result, key) => {
    const prefix = result ? "&" : "?";
    return `${result}${prefix}${key}=${inputObject[key]}`;
  }, "");
};

export const queryStringToObject = (queryString: string) => {
  if (!queryString) {
    return {};
  }
  const queryArr = queryString.replace("?", "").split("&");
  const obj: Record<string, string> = {};
  queryArr.forEach((item) => {
    const key = item.split("=")[0];
    const value = item.split("=")[1];
    obj[key] = value;
  });
  return obj;
};

export const queryObjectToString = (
  queryObject: Record<string, string | number>
) => {
  if (!queryObject || Object.keys(queryObject).length === 0) {
    return "";
  }
  const keys = Object.keys(queryObject);
  let queryString = "";

  keys.forEach((key) => {
    if (!queryString) {
      queryString += "?";
    } else {
      queryString += "&";
    }
    queryString += `${key}=${queryObject[key]}`;
  });

  return queryString;
};

/**
 * Generic prepare data function before send to BE
 */
export const prepareData = <T = FormDataType>(
  formData: FormDataType,
  formFields: FormDataFieldType[],
  options?: { withoutId?: boolean }
) => {
  const data = produce(formData, (draft) => {
    formFields.forEach((f) => {
      if (!f.name) {
        return;
      }
      switch (f.type) {
        case "checkbox":
          draft[f.name] = Boolean(draft[f.name]);
          return;
        case "date":
          /**
           * draft[f.name] === undefined: not updating is field
           * draft[f.name] === null: clear the value of this field
           * draft[f.name] === '': set it to null, clear the value of this field
           */
          if (draft[f.name] === undefined || draft[f.name] === null) {
            return;
          }
          if (!draft[f.name]) {
            draft[f.name] = null;
            return;
          }
          draft[f.name] = isValid(new Date(draft[f.name]))
            ? format(new Date(draft[f.name]), "yyyy-MM-dd")
            : null;
          return;
        case "select":
          if (!f.multiple) {
            // DO NOT remove '0' from the condition, it should be an accepted value.
            // '0' is the value of Male
            if (!draft[f.name] && draft[f.name] !== 0) {
              draft[f.name] = null;
            }
            return;
          }
          if (!draft[f.name]) {
            return [];
          }
          return;
        case "email":
          if (draft[f.name]) {
            draft[f.name] = draft[f.name].toLowerCase();
          }
          return;
        default:
          return;
      }
    });

    if (options?.withoutId) {
      delete draft.id;
    }
  });

  return data as T;
};

export const removeDuplicates = <
  T = number | string | boolean | null | undefined
>(
  inputArray: Array<T>
) => {
  return inputArray.filter((item, idx, arr) => arr.indexOf(item) === idx);
};

export const formatDateToDisplay = (date?: Date | any) => {
  if (!isValid(date)) {
    return;
  }
  return format(date, "dd/MM/yyyy");
};

export const formatDateTimeToDisplay = (
  date?: Date | any,
  withSecond = false
) => {
  if (!isValid(date)) {
    return;
  }
  return format(date, `dd/MM/yyyy HH:mm${withSecond ? ":ss" : ""}`);
};

export const formatDateToBackend = (date?: Date | any) => {
  if (!isValid(date)) {
    return;
  }
  return format(date, "yyyy-MM-dd");
};
