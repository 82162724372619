import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  LinkProps,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink, useParams } from "react-router-dom";
import { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles } from "@material-ui/styles";

const breadcrumbNameMap: Record<string, string> = {
  "/admin": "Administrator",
  "/admin/user": "User List",
  "/admin/user/new": "New User",
  "/admin/user/info": "Details",
  "/assessor": "Assessor List",
  "/assessor/conflict": "Conflict of Interest",
  "/assessor/new": "New Assessor",
  "/assessor/info": "Assessor Details",
  "/qualification": "Qualification List",
  "/qualification-level": "Qualification Level List",
  "/assessor-conflict": "Conflict of Interest List",
  "/era": "Employer Registered Assessors",
};

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

type NSBreadcrumbsProps = {
  isLoading?: boolean;
};

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

const useStyles = makeStyles(
  () => ({
    li: {
      "& *": {
        fontSize: "0.95rem",
      },
    },
  }),
  {
    classNamePrefix: "NSBreadcrumbs",
  }
);

const NSBreadcrumbs = ({ isLoading = false }: NSBreadcrumbsProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const params = useParams();

  const pathnames = useMemo(() => {
    const paramValues = Object.values(params);
    return pathname
      .split("/")
      .filter((x) => x !== "list")
      .filter((x) => x && !paramValues.includes(x));
  }, [pathname, params]);

  return (
    <Box display="flex" alignItems="center">
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        className="mb-3"
        classes={{ li: classes.li }}
      >
        <LinkRouter color="inherit" to="/">
          Home
        </LinkRouter>
        {pathnames.map((_, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;

          return last ? (
            <Typography
              color="textPrimary"
              style={{ fontWeight: 600 }}
              key={to}
            >
              <>{breadcrumbNameMap[to]}</>
            </Typography>
          ) : (
            <LinkRouter color="inherit" to={to} key={to}>
              <>{breadcrumbNameMap[to]}</>
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
      {isLoading && <CircularProgress size={14} className="mb-4 ml-4" />}
    </Box>
  );
};

export default memo(NSBreadcrumbs);
