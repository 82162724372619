import { useAppSelector } from "@app/hooks";
import NSFormToolbar from "@components/NSFormToolbar";
import { memo } from "react";
import SummaryCard, { SummaryCardsContainer } from "./SummaryCard";
import SummaryTitle from "./SummaryTitle";

const translateKeyToLabel = (key: string) => {
  switch (key) {
    case "assessor_dbs_expired_in_90_days":
      return "Expired in 90 days";
    case "assessor_dbs_expired_in_30_days":
      return "Expired in 30 days";
    case "assessor_dbs_expired_in_7_days":
      return "Expired in 7 days";
    case "assessor_dbs_expired":
      return "Already expired";
    default:
      return "";
  }
};

const translateKeyToColorClass = (key: string) => {
  switch (key) {
    case "assessor_dbs_expired_in_30_days":
      return "color-warning-level-1";
    case "assessor_dbs_expired_in_7_days":
      return "color-warning-level-2";
    case "assessor_dbs_expired":
      return "color-error";
    default:
      return "";
  }
};

const translateKeyToLink = (key: string) => {
  switch (key) {
    case "assessor_dbs_expired_in_90_days":
      return "/assessor/list?dbsExpiredIn=90";
    case "assessor_dbs_expired_in_30_days":
      return "/assessor/list?dbsExpiredIn=30";
    case "assessor_dbs_expired_in_7_days":
      return "/assessor/list?dbsExpiredIn=7";
    case "assessor_dbs_expired":
      return "/assessor/list?dbsExpiredIn=-1";
    default:
      return "/assessor/list";
  }
};

const Home = () => {
  const totalUsers = useAppSelector((state) => state.dashboard.totalUsers);
  const totalAssessors = useAppSelector(
    (state) => state.dashboard.totalAssessors
  );
  const summary = useAppSelector((state) => state.dashboard.summary);
  return (
    <>
      <NSFormToolbar title="Overview" />
      <SummaryCardsContainer>
        <SummaryCard
          value={totalUsers}
          description="Total users"
          to="/admin/user/list"
        />
        <SummaryCard
          value={totalAssessors}
          description="Total assessors"
          to="/assessor/list"
        />
      </SummaryCardsContainer>
      {summary && (
        <>
          <SummaryTitle title="Assessor DBS expiration tracking" />
          <SummaryCardsContainer>
            {summary.map((item) => (
              <SummaryCard
                key={item.key}
                description={translateKeyToLabel(item.key)}
                value={item.value}
                valueClass={translateKeyToColorClass(item.key)}
                to={translateKeyToLink(item.key)}
              />
            ))}
          </SummaryCardsContainer>
        </>
      )}
    </>
  );
};

export default memo(Home);
