import { memo } from "react";
import { useParams } from "react-router-dom";
import SectionNotAvailable from "../SectionNotAvailable";
import AssessorConflictsManagement from "./AssessorConflictsManagement";

const AssessorConflictsSection = () => {
  const { id } = useParams<Record<"id", string>>();

  if (!id) {
    return <SectionNotAvailable />;
  }

  return <AssessorConflictsManagement />;
};

export default memo(AssessorConflictsSection);
