import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { useCallback } from "react";
import useConfirmation from "./useConfirmation";

const useStyles = makeStyles(
  (theme) => ({
    dialogContent: {
      "& b": {
        color: theme.palette.text.primary,
      },
    },
  }),
  { classNamePrefix: "ConfirmationDialog" }
);

const ConfirmationDialog = () => {
  const classes = useStyles();
  const { isShowingConfirmation, closeConfirmation, confirmation } =
    useConfirmation();

  const handleClose = useCallback<Required<DialogProps>["onClose"]>(
    (_, reason) => {
      if (reason === "backdropClick") {
        return;
      }
      closeConfirmation();
    },
    [closeConfirmation]
  );

  const handleConfirm = useCallback(() => {
    if (confirmation?.onConfirm) {
      confirmation?.onConfirm();
    }
    closeConfirmation();
  }, [closeConfirmation, confirmation]);

  const handleReject = useCallback(() => {
    if (confirmation?.onReject) {
      confirmation?.onReject();
    }
    closeConfirmation();
  }, [closeConfirmation, confirmation]);

  return (
    <Dialog
      open={isShowingConfirmation}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      {isShowingConfirmation && (
        <>
          <DialogTitle>{confirmation?.title}</DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText>{confirmation?.content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirm} color="primary" autoFocus>
              Confirm
            </Button>
            <Button onClick={handleReject} color="default">
              Reject
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ConfirmationDialog;
