import { AssessorWithoutId } from "@app/models";
import { AssessorStatusKey } from "@features/assessor/assessorConstant";
import { isNil } from "lodash";

/**
 * Definition of field rules base on assessor status
 */

type MandatoryFieldsByStatus = {
  [key in AssessorStatusKey]: Array<keyof AssessorWithoutId>;
};

const mandatoryFields: MandatoryFieldsByStatus = {
  NAR: [
    "firstname",
    "surname",
    "status",
    "email",
    "telPrimary",
    "qualificationId",
    "qualificationLevelId",
  ],
  ACTIVE: [],
  INACTIVE: [],
};

mandatoryFields.ACTIVE = [
  ...mandatoryFields.NAR,
  // "signOffFromLead",
  // "emailGranted",
  // "ace360Granted",
];

/**
 * Validate the mandatory fields base on next status of assessor.
 * Return an array of field name which is invalid.
 */
const validateMandatoryFields = (
  assessor: AssessorWithoutId,
  nextStatus?: AssessorStatusKey
) => {
  const errorFields: Array<keyof AssessorWithoutId> = [];

  // Status is not changing
  if (!nextStatus) {
    return errorFields;
  }

  const fieldNames = mandatoryFields[nextStatus] ?? [];

  fieldNames.forEach((fieldName) => {
    const value = assessor[fieldName];
    if (
      isNil(value) ||
      value === "" ||
      value === false ||
      (Array.isArray(value) && value.length === 0)
    ) {
      errorFields.push(fieldName);
    }
  });

  return errorFields;
};

export default validateMandatoryFields;
