import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import Content from "./Content";
import SideBar from "./SideBar";
import TopBar from "./TopBar";

export interface LayoutProps {
  children: ReactNode;
}

const Layout = (props: LayoutProps): ReactElement => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    setOpen(isLg);
  }, [isLg]);

  const toggleSideBar = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <>
      <TopBar sideBarOpen={open} toggleSideBar={toggleSideBar} />
      <SideBar open={open} />
      <Content sideBarOpen={open}>{props.children}</Content>
    </>
  );
};

export default Layout;
