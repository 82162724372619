import { useSnackbar, VariantType } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { UserRole } from "./enums";
import type { AppDispatch, RootState } from "./store";

/**
 * Use throughout your app instead of plain `useDispatch`
 */
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

/**
 * Use throughout your app instead of plain `useSelector`
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Get the query params from URL
 */
export const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

/**
 * Hook to detect if user is Admin
 */
export const useIsAdmin = () => {
  const userRole = useAppSelector((state) => state.auth.role);
  const isAdmin = useMemo(() => userRole?.name === UserRole.Admin, [userRole]);
  return isAdmin;
};

const getOffset = (el: Element) => {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
};

export const useScrollToElementByHash = (config?: {
  ready?: boolean;
  topSpacing?: number;
}) => {
  const location = useLocation();
  const timerRef = useRef<NodeJS.Timeout>();

  const isReadyToScroll = useMemo(() => !config || config.ready, [config]);

  useEffect(() => {
    if (!location.hash) {
      return;
    }
    if (!isReadyToScroll) {
      return;
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      const el = document.querySelector(location.hash);
      if (!el) {
        return;
      }
      const { top } = getOffset(el);
      window.scrollTo({ top: top - (config?.topSpacing ?? 0) });
      el.classList.add("blink");
      setTimeout(() => {
        el.classList.remove("blink");
      }, 3000);
    });

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [location.hash, isReadyToScroll, config?.topSpacing]);
};

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showNotification = useCallback(
    (message: string | React.ReactNode, variant: VariantType = "success") => {
      enqueueSnackbar(message, {
        variant,
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    },
    [enqueueSnackbar]
  );

  return {
    showNotification,
    hideNotification: closeSnackbar,
  };
};

export const useDialog = () => {
  const [open, setOpen] = useState<boolean>(false);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    openDialog,
    closeDialog,
  };
};
