import {
  Checkbox,
  FormControlLabel,
  Grid,
  GridSize,
  Hidden,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { concat } from "lodash";
import { Fragment, ReactNode, ComponentType } from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import NSButton from "./NSButton";
import NSDatePicker, { NSDatePickerMode } from "./NSDatePicker";
import NSSelect from "./NSSelect";
import NSTextField from "./NSTextField";

type FormItemSlot = 1 | 2 | 3;

export declare type FormFieldName =
  | `${string}`
  | `${string}.${string}`
  | `${string}.${number}`;

export interface FormTitle {
  label: string;
  type: "title";
}

const formFieldTypeValues = [
  "checkbox",
  "hidden",
  "number",
  "password",
  "email",
] as const;
const formTextAreaFieldTypeValues = ["text"] as const;
const fromSelectFieldTypeValues = ["select"] as const;
const formDateFieldTypeValues = ["date"] as const;

type FormFieldTypeValue = typeof formFieldTypeValues[number];
type FormTextAreaFieldTypeValue = typeof formTextAreaFieldTypeValues[number];
type FormSelectFieldTypeValue = typeof fromSelectFieldTypeValues[number];
type FormDateFieldTypeValue = typeof formDateFieldTypeValues[number];

export const formDataFieldTypeValues = concat<string>(
  formFieldTypeValues,
  formTextAreaFieldTypeValues,
  fromSelectFieldTypeValues,
  formDateFieldTypeValues
);

export interface FormField {
  label?: string;
  name: FormFieldName;
  rules?: RegisterOptions;
  type: FormFieldTypeValue;
  slot?: FormItemSlot;
  disabled?: boolean;
  helperText?: string;
  maxLength?: number;
}

export interface FormTextAreaField extends Omit<FormField, "type"> {
  type: FormTextAreaFieldTypeValue;
  minRows?: number;
  maxRows?: number;
  rows?: number;
}

export interface FormSelectField {
  label?: string;
  name: FormFieldName;
  rules?: RegisterOptions;
  type: FormSelectFieldTypeValue;
  multiple?: boolean;
  selectOptions: { id: number | string; name: string; disabled?: boolean }[];
  slot?: FormItemSlot;
  disabled?: boolean;
  helperText?: string;
}

export interface FormDateField {
  label?: string;
  name: FormFieldName;
  rules?: RegisterOptions;
  type: FormDateFieldTypeValue;
  mode?: NSDatePickerMode;
  disableFuture?: boolean;
  disablePast?: boolean;
  slot?: FormItemSlot;
  disabled?: boolean;
  helperText?: string;
}

export interface FormBlankField {
  type: "blank";
  slot?: FormItemSlot;
}

export interface FormButton {
  label?: string;
  type: "button";
  slot?: FormItemSlot;
  onClick: () => any;
  isLoading?: boolean;
}

export interface FormCustomComponent {
  type: "custom";
  component: ReactNode;
}

/**
 * Type of the fields which responsible for display data
 */
export type FormDataFieldType =
  | FormField
  | FormTextAreaField
  | FormSelectField
  | FormDateField;

/**
 * Type of the fields which responsible for display non-data
 */
export type FormNonDataFieldType = FormTitle | FormBlankField;

/**
 * Type of the fields (both data and non-data fields)
 */
export type FormFieldType =
  | FormTitle
  | FormField
  | FormTextAreaField
  | FormSelectField
  | FormDateField
  | FormBlankField
  | FormButton
  | FormCustomComponent;

export interface FormSectionType {
  type?: "default";
  label: string;
  fields: Array<FormFieldType>;
}

export interface FormCustomSectionType {
  type: "custom";
  label: string;
  component: ComponentType<any>;
}

export type FormDataType = Record<FormFieldName, any>;

export const FormSection = ({ children }: { children: ReactNode }) => (
  <Grid container>{children}</Grid>
);

const useFormSectionTitleStyles = makeStyles(
  (theme: Theme) => {
    return {
      root: {
        position: "relative",
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(2),
        borderBottom: `2px solid ${theme.palette.info.main}`,
        width: "100%",
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
      rootFirst: {
        marginTop: theme.spacing(-0.5),
      },
      title: {
        fontWeight: 600,
        fontSize: "1.15rem",
      },
    };
  },
  { classNamePrefix: "FormSectionTitle" }
);

export const FormSectionTitle = ({
  id,
  title,
  first = false,
}: {
  id?: string;
  title: string;
  first?: boolean;
}) => {
  const classes = useFormSectionTitleStyles();
  return (
    <div id={id} className={clsx(classes.root, { [classes.rootFirst]: first })}>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};

const useFormSectionContentStyles = makeStyles(
  () => ({
    root: {
      "& > .FormSectionContentItem": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  }),
  {
    classNamePrefix: "FormSectionContent",
  }
);

export const FormSectionContent = ({ children }: { children: ReactNode }) => {
  const classes = useFormSectionContentStyles();
  return (
    <Grid item container spacing={2} className={classes.root}>
      {children}
    </Grid>
  );
};

export const FormSectionContentItem = ({
  id,
  children,
  blank = false,
  slot = 1,
}: {
  id?: string;
  children?: ReactNode;
  blank?: boolean;
  slot?: FormItemSlot;
}) => {
  const defaultMd = 4;
  const md = (slot * defaultMd) as GridSize;
  return (
    <Hidden xsDown={blank}>
      <Grid id={id} item xs={12} md={md} className="FormSectionContentItem">
        {children}
      </Grid>
    </Hidden>
  );
};

const useFormTitleStyles = makeStyles(
  (theme) => ({
    root: {
      fontWeight: 600,
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(17),
      position: "relative",
      paddingLeft: theme.spacing(2),
      "&:before": {
        content: '" "',
        position: "absolute",
        width: 4,
        height: "70%",
        backgroundColor: theme.palette.info.light,
        borderRadius: 2,
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
      },
    },
  }),
  {
    classNamePrefix: "FormTitle",
  }
);

export const FormTitle = ({ children }: { children?: ReactNode }) => {
  const classes = useFormTitleStyles();
  return (
    <Typography variant="h6" classes={{ root: classes.root }}>
      {children}
    </Typography>
  );
};

/**
 * Check if the field is data field (not the dummy display stuffs)
 */
export const validateDataField = (field: { type: FormFieldType["type"] }) => {
  return field.type !== "blank" && field.type !== "title";
};

export const convertLabelToId = (label: string) => {
  return label.toLowerCase().replace(/ /g, "-");
};

const getFieldError = (
  errors: DeepMap<FieldValues, FieldError>,
  fieldName: string
) => {
  let fieldError = errors[fieldName];
  if (fieldName.includes(".")) {
    const splitName: string[] = fieldName.split(".");
    let temp: any = null;
    splitName.forEach((name: string) => {
      if (!temp) {
        temp = errors[name];
        return;
      }
      temp = temp[name];
    });
    fieldError = temp;
  }
  return fieldError;
};

export type FormBuilderSections = Array<
  FormCustomSectionType | FormSectionType
>;

export type FormBuilderProps = {
  control: Control<FieldValues>;
  sections: FormBuilderSections;
  errors: DeepMap<FieldValues, FieldError>;
};

export interface FormSectionContentBuilderProps {
  /**
   * The section which is containing this content
   */
  section: FormSectionType;
  control: Control<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
}

export const buildFormSectionContent = ({
  section,
  control,
  errors,
}: FormSectionContentBuilderProps) => {
  return (
    <FormSectionContent>
      {section.fields.map((sectionField, sfIdx) => {
        const isDataField = validateDataField(sectionField);
        const controllerProps = {
          name: isDataField ? (sectionField as FormDataFieldType).name : "",
          control,
          rules: isDataField
            ? (sectionField as FormDataFieldType).rules
            : undefined,
        };
        switch (sectionField.type) {
          case "hidden":
            return null;
          case "title": {
            const titleKey = `${section.label}-title-${sfIdx}`;
            const titleId = convertLabelToId(sectionField.label);
            return (
              <FormSectionContentItem key={titleKey} id={titleId} slot={3}>
                <FormTitle>{sectionField.label}</FormTitle>
              </FormSectionContentItem>
            );
          }
          case "blank": {
            const blankKey = `${section.label}-blank-${sfIdx}`;
            return (
              <FormSectionContentItem
                slot={sectionField.slot}
                key={blankKey}
                blank
              />
            );
          }
          case "checkbox":
            return (
              <FormSectionContentItem
                slot={sectionField.slot}
                key={sectionField.name}
              >
                <Controller
                  {...controllerProps}
                  render={({
                    field: { onChange, onBlur, value, ref, name },
                  }) => {
                    const fieldError = getFieldError(errors, name);
                    return (
                      <FormControlLabel
                        className={clsx("mt-1", {
                          "color-error": Boolean(fieldError),
                        })}
                        label={sectionField.label}
                        control={
                          <Checkbox
                            color="primary"
                            onBlur={onBlur}
                            onChange={onChange}
                            checked={Boolean(value)}
                            inputRef={ref}
                            disabled={sectionField.disabled}
                          />
                        }
                      />
                    );
                  }}
                />
              </FormSectionContentItem>
            );
          case "date": {
            return (
              <FormSectionContentItem
                slot={sectionField.slot}
                key={sectionField.name}
              >
                <Controller
                  {...controllerProps}
                  render={({ field }) => {
                    const fieldError = getFieldError(errors, field.name);
                    return (
                      <NSDatePicker
                        mode={sectionField.mode}
                        label={sectionField.label}
                        error={Boolean(fieldError)}
                        helperText={
                          fieldError
                            ? fieldError.message || "This field is required"
                            : sectionField.helperText
                        }
                        {...field}
                        value={field.value || ""}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        disableFuture={sectionField.disableFuture}
                        disablePast={sectionField.disablePast}
                        disabled={sectionField.disabled}
                      />
                    );
                  }}
                />
              </FormSectionContentItem>
            );
          }
          case "select": {
            const displayEmpty = !sectionField.multiple;
            return (
              <FormSectionContentItem
                slot={sectionField.slot}
                key={sectionField.name}
              >
                <Controller
                  {...controllerProps}
                  defaultValue=""
                  render={({ field }) => {
                    const fieldError = getFieldError(errors, field.name);
                    return (
                      <NSSelect
                        {...field}
                        label={sectionField.label}
                        error={Boolean(fieldError)}
                        helperText={
                          fieldError
                            ? fieldError.message || "This field is required"
                            : sectionField.helperText
                        }
                        value={field.value ?? ""}
                        displayEmpty={displayEmpty}
                        multiple={sectionField.multiple}
                        disabled={sectionField.disabled}
                      >
                        {displayEmpty && (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                        {sectionField.selectOptions.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            disabled={item.disabled}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </NSSelect>
                    );
                  }}
                />
              </FormSectionContentItem>
            );
          }
          case "button": {
            const btnKey = `${section.label}-button-${sfIdx}`;
            return (
              <FormSectionContentItem slot={sectionField.slot} key={btnKey}>
                <NSButton
                  color="primary"
                  className="mt-3.5 mb-2"
                  onClick={sectionField.onClick}
                  loading={!!sectionField.isLoading}
                  variant="outlined"
                >
                  {sectionField.label}
                </NSButton>
              </FormSectionContentItem>
            );
          }
          case "custom":
            return sectionField.component;
          case "text":
          case "number":
          case "password":
          case "email":
          default: {
            let minRows: number | undefined = undefined;
            let maxRows: number | undefined = undefined;
            let multiline = false;
            if (sectionField.type === "text") {
              minRows = sectionField.minRows;
              maxRows = sectionField.maxRows;
              multiline = Boolean(
                (minRows && minRows > 1) || (maxRows && maxRows > 1)
              );
            }
            return (
              <FormSectionContentItem
                slot={sectionField.slot}
                key={sectionField.name}
              >
                <Controller
                  {...controllerProps}
                  render={({ field }) => {
                    const fieldError = getFieldError(errors, field.name);
                    return (
                      <NSTextField
                        type={sectionField.type}
                        label={sectionField.label}
                        error={Boolean(fieldError)}
                        helperText={
                          fieldError
                            ? fieldError.message || "This field is required"
                            : sectionField.helperText
                        }
                        minRows={minRows}
                        maxRows={maxRows}
                        multiline={multiline}
                        maxLength={sectionField.maxLength}
                        {...field}
                        value={field.value || ""}
                        disabled={sectionField.disabled}
                      />
                    );
                  }}
                />
              </FormSectionContentItem>
            );
          }
        }
      })}
    </FormSectionContent>
  );
};

const buildForm = ({ control, sections, errors }: FormBuilderProps) => {
  return (
    <>
      {sections.map((section, sIdx) => {
        const sectionTitleId = convertLabelToId(section.label);
        if (section.type === "custom") {
          const SectionComponent = section.component;
          return (
            <Fragment key={sectionTitleId}>
              <FormSectionTitle
                id={sectionTitleId}
                first={sIdx === 0}
                title={section.label}
              />
              <SectionComponent />
            </Fragment>
          );
        }
        return (
          <FormSection key={sectionTitleId}>
            <FormSectionTitle
              id={sectionTitleId}
              first={sIdx === 0}
              title={section.label}
            />
            <FormSectionContent>
              {buildFormSectionContent({ control, errors, section })}
            </FormSectionContent>
          </FormSection>
        );
      })}
    </>
  );
};

export default buildForm;
