import { useNotification } from "@app/hooks";
import { Nullable, User } from "@app/models";
import useConfirmation from "@components/Confirmation/useConfirmation";
import buildForm from "@components/FormBuilder";
// import NSBreadcrumbs from "@components/NSBreadcrumbs";
import NSButton from "@components/NSButton";
import NSFormToolbar from "@components/NSFormToolbar";
import {
  useGetUserQuery,
  useLazyResetUserPasswordQuery,
  useUpdateUserMutation,
} from "@features/user/userAPI";
import { Box, Paper } from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import {
  formFields,
  prepareDataToUpdate,
  useUserFormSections,
} from "./userFormHelpers";
import { useHistory } from "react-router-dom";

type UserInfoLocationState = {
  user: Nullable<User>;
};

const UserInfo = () => {
  const { id } = useParams<Record<"id", string>>();
  const history = useHistory();
  const {
    data: queryResponse,
    isFetching,
    isSuccess: isQuerySuccess,
    isError: isQueryError,
  } = useGetUserQuery(id);
  const [
    updateUser,
    {
      isLoading: isUpdating,
      data: mutationResponse,
      isSuccess: isMutationSuccess,
      isError: isMutationError,
    },
  ] = useUpdateUserMutation();
  const [resetPassword, { isFetching: isResettingPassword }] =
    useLazyResetUserPasswordQuery();
  const { state } = useLocation<UserInfoLocationState>();
  const [user, setUser] = useState<Nullable<User>>(state?.user ?? null);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { showConfirmation } = useConfirmation();
  const { showNotification } = useNotification();
  const formSections = useUserFormSections({ withoutPassword: true });

  const setFormData = useCallback(
    (data: User & Record<string, any>) => {
      formFields.forEach((fieldName) => {
        if (!fieldName) return;
        setValue(fieldName, data[fieldName] ?? "");
      });
    },
    [setValue]
  );

  /**
   * Update user from useGetUserQuery response
   */
  useEffect(() => {
    if (!queryResponse?.user) {
      return;
    }
    setUser(queryResponse.user);
    setFormData(queryResponse.user);
  }, [queryResponse, setFormData]);

  /**
   * Show notification for user query result
   */
  useEffect(() => {
    if (isQueryError || (isQuerySuccess && !queryResponse?.user)) {
      showNotification(
        queryResponse?.message || "Unknown error! Cannot get this user details",
        "error"
      );
      history.push("/admin/user/list");
    }
  }, [isQueryError, isQuerySuccess, queryResponse, showNotification, history]);

  /**
   * Re-update the form data when user is updated and received new user from response
   */
  useEffect(() => {
    if (isMutationSuccess && mutationResponse?.user) {
      setUser(mutationResponse.user);
      setFormData(mutationResponse.user);
      return;
    }
  }, [isMutationSuccess, mutationResponse, setFormData]);

  /**
   * Show notification for user mutation result
   */
  useEffect(() => {
    if (isMutationSuccess || isMutationError) {
      if (mutationResponse?.user) {
        showNotification("User is updated successfully");
        setUser(mutationResponse.user);
        setFormData(mutationResponse.user);
        return;
      }
      showNotification(
        mutationResponse?.message || "Unknown error! Cannot update this user.",
        "error"
      );
    }
  }, [
    isMutationSuccess,
    isMutationError,
    mutationResponse,
    setFormData,
    showNotification,
  ]);

  const submit = useCallback(
    (data) => {
      if (!user?.id) {
        return;
      }
      updateUser(
        prepareDataToUpdate(
          {
            ...data,
            id: user.id,
          },
          {
            originalData: user,
            withoutPassword: true,
          }
        )
      );
    },
    [updateUser, user]
  );

  const handleResetPassword = useCallback(() => {
    showConfirmation({
      title: "Are you sure?",
      content: (
        <>
          After the password is reset, all the active sessions of this user will
          be destroyed.
          <br />
          The default password is <b>password@marshall</b>
        </>
      ),
      onConfirm: () => resetPassword(id),
    });
  }, [resetPassword, id, showConfirmation]);

  return (
    <>
      {/* <NSBreadcrumbs /> */}
      <form onSubmit={handleSubmit(submit)} autoComplete="off">
        <NSFormToolbar title="User Details" loading={isFetching}>
          <NSButton
            type="button"
            loading={isResettingPassword}
            onClick={handleResetPassword}
          >
            Reset password
          </NSButton>
          <NSButton
            className="ml-3"
            type="submit"
            color="primary"
            loading={isUpdating}
          >
            Update
          </NSButton>
        </NSFormToolbar>
        <Box component={Paper} p={3}>
          {buildForm({ control, sections: formSections, errors })}
        </Box>
      </form>
    </>
  );
};

export default memo(UserInfo);
