import clsx from "clsx";
import { memo, ReactNode } from "react";
import { ReactElement } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Toolbar, Theme } from "@material-ui/core";
import { toInteger } from "lodash";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      content: {
        flexGrow: 1,
        minHeight: "100vh",
        padding: theme.spacing(1.5, 2),
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
      },
      contentShift: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: theme.sideBar.width,
      },
      toolbarSpacer: {
        minHeight: toInteger(theme.mixins.toolbar.minHeight),
      },
    }),
  {
    classNamePrefix: "Content",
  }
);

export interface ContentProps {
  sideBarOpen: boolean;
  children: ReactNode;
}

const Content = (props: ContentProps): ReactElement => {
  const classes = useStyles();

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: props.sideBarOpen,
      })}
    >
      <Toolbar className={classes.toolbarSpacer} />
      {props.children}
    </main>
  );
};

export default memo(Content);
