import { useDialog, useNotification } from "@app/hooks";
import { Assessor } from "@app/models";
import NSDivider from "@components/NSDivider";
import { useUploadAssessorImageMutation } from "@features/assessor/assessorAPI";
import { AssessorStatus } from "@features/assessor/assessorConstant";
import {
  Box,
  CircularProgress,
  createStyles,
  Link,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import clsx from "clsx";
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import AssessorReferencesDialog from "./AssessorReferencesDialog";
import FormMap from "./components/FormMap";
import StatusTag from "./components/StatusTag";

interface PortraitProps {
  assessor?: Partial<Assessor> | undefined | null;
}

const useFullName = (assessor: PortraitProps["assessor"]) => {
  const fullName = useMemo(() => {
    const defaultName = "-";
    if (!assessor) return defaultName;

    const arr = [
      assessor.firstname,
      assessor.middlename,
      assessor.surname,
    ].filter((item) => !!item);

    const result = arr.length > 0 ? arr.join(" ") : defaultName;

    return result;
  }, [assessor]);

  return fullName;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      avatar: {
        width: 120,
        height: 120,
        objectFit: "cover",
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.up("sm")]: {
          width: 160,
          height: 160,
        },
        [theme.breakpoints.up("md")]: {
          width: 220,
          height: 220,
        },
      },
      updatePhoto: {
        position: "absolute",
        left: 0,
        bottom: 0,
        height: 32,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        "& label": {
          cursor: "pointer",
          color: theme.palette.info.main,
          "&:active": {
            opacity: theme.palette.action.activatedOpacity,
          },
        },
      },
      avatarBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        marginBottom: theme.spacing(1),
        boxShadow: "0px 0px 0 1px rgb(255 255 255 / 12%)",
        border: "none",
      },
      avatarLoaderContainer: {
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      avatarHolder: {
        fontSize: 100,
        color: theme.palette.grey[600],
        [theme.breakpoints.up("sm")]: {
          fontSize: 140,
        },
        [theme.breakpoints.up("md")]: {
          fontSize: 210,
        },
      },
      statusTag: {
        position: "absolute",
        top: 0,
        left: 4,
      },
      infoBox: {
        width: 120,
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        [theme.breakpoints.up("sm")]: {
          width: 160,
        },
        [theme.breakpoints.up("md")]: {
          width: 220,
        },
      },
      name: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(17),
        margin: theme.spacing(0.5, 0),
      },
    }),
  {
    classNamePrefix: "Portrait",
  }
);

const Portrait = ({ assessor }: PortraitProps) => {
  const classes = useStyles();
  const [avatarSrc, setAvatarSrc] = useState<string | null | undefined>();

  const {
    open: isReferencesOpen,
    openDialog: openReferences,
    closeDialog: closeReferences,
  } = useDialog();

  const [uploadImage, { isLoading, data, isSuccess, isError, error }] =
    useUploadAssessorImageMutation();

  const { showNotification } = useNotification();

  useEffect(() => {
    setAvatarSrc(assessor?.avatar?.data);
  }, [assessor]);

  const fullName = useFullName(assessor);

  const onUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!assessor?.id || !e.target.files || e.target.files.length === 0) {
        return;
      }
      const imageFile = e.target.files[0];
      uploadImage({
        id: assessor.id,
        imageFile,
      });
    },
    [assessor, uploadImage]
  );

  useEffect(() => {
    if (isSuccess || isError) {
      if (data?.avatar) {
        setAvatarSrc(data.avatar.data);
        return;
      }
      showNotification(
        data?.message ||
          (error as any)?.message ||
          "Unknown error! Cannot upload image.",
        "error"
      );
    }
  }, [isSuccess, isError, data, error, showNotification]);

  if (!assessor) {
    return (
      <Box
        component={Paper}
        p={2}
        textAlign="center"
        position="sticky"
        top={108}
      >
        <Paper
          variant="outlined"
          className={clsx(classes.avatar, classes.avatarBox, "mb-0")}
        >
          <PersonIcon className={classes.avatarHolder} />
        </Paper>
      </Box>
    );
  }

  return (
    <Box component={Paper} p={2} textAlign="center" position="sticky" top={108}>
      <Paper
        variant="outlined"
        className={clsx(classes.avatar, classes.avatarBox)}
      >
        {avatarSrc ? (
          <img className={classes.avatar} src={avatarSrc} />
        ) : (
          <PersonIcon className={classes.avatarHolder} />
        )}
        {isLoading ? (
          <div className={classes.avatarLoaderContainer}>
            <CircularProgress size={24} color="primary" />
          </div>
        ) : (
          <>
            {assessor.id && (
              <div className={classes.updatePhoto}>
                <input
                  onChange={onUpload}
                  id="image-input"
                  type="file"
                  style={{ display: "none" }}
                  multiple={false}
                  accept="image/*"
                />
                <Typography
                  htmlFor="image-input"
                  component="label"
                  variant="caption"
                >
                  Update photo
                </Typography>
              </div>
            )}
          </>
        )}
        {assessor?.status && (
          <StatusTag
            status={AssessorStatus[assessor.status]}
            className={classes.statusTag}
          />
        )}
      </Paper>
      <div className={classes.infoBox}>
        <Typography variant="h6" className={classes.name}>
          {fullName}
        </Typography>
        <FormMap />
        <NSDivider label="Others" className="mt-2" />
        <Link
          className="mt-1.5"
          component="button"
          type="button"
          variant="body2"
          onClick={openReferences}
        >
          • Show references
        </Link>
      </div>
      {isReferencesOpen && assessor?.id && (
        <AssessorReferencesDialog
          onClose={closeReferences}
          open={Boolean(assessor.id)}
          assessorId={assessor.id}
        />
      )}
    </Box>
  );
};

export default memo(Portrait);
