import { useParams } from "react-router-dom";
import SectionNotAvailable from "../SectionNotAvailable";
import AssessorStandardsManagement from "./AssessorStandardsManagement";

const AssessorStandardsSection = () => {
  const { id } = useParams<Record<"id", string>>();

  if (!id) {
    return <SectionNotAvailable />;
  }

  return <AssessorStandardsManagement />;
};

export default AssessorStandardsSection;
