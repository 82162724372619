import { formatDateToDisplay } from "@app/helpers";
import { GridColumns } from "@mui/x-data-grid";
import { useMemo } from "react";
import ConflictListActionCell from "./ConflictListActionCell";

const useConflictColumns = () => {
  const columns: GridColumns = useMemo(() => {
    return [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        width: 70,
        filterable: false,
        disableColumnMenu: true,
        hide: true,
      },
      {
        field: "reason",
        headerName: "Reason",
        width: 280,
        editable: true,
        cellClassName: "cursor-pointer",
      },
      {
        field: "location",
        headerName: "Location",
        width: 200,
        editable: true,
        cellClassName: "cursor-pointer",
      },
      {
        field: "boardSignOff",
        headerName: "Board sign off",
        type: "date",
        width: 160,
        editable: true,
        cellClassName: "cursor-pointer",
        valueFormatter: (params) => {
          if (!params.row.boardSignOff) {
            return "";
          }
          return formatDateToDisplay(new Date(params.row.boardSignOff));
        },
      },
      {
        field: "signedOn",
        headerName: "Signed on",
        type: "date",
        width: 160,
        editable: true,
        cellClassName: "cursor-pointer",
        valueFormatter: (params) => {
          if (!params.row.signedOn) {
            return "";
          }
          return formatDateToDisplay(new Date(params.row.signedOn));
        },
      },
      {
        field: "expiredOn",
        headerName: "Expired on",
        width: 160,
        cellClassName: "outline-none color-disabled",
        valueFormatter: (params) => {
          if (!params.row.expiredOn) {
            return "";
          }
          return formatDateToDisplay(new Date(params.row.expiredOn));
        },
      },
      {
        field: "actions",
        headerName: " ",
        width: 90,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: "right",
        cellClassName: "outline-none",
        renderCell: ConflictListActionCell,
      },
    ];
  }, []);

  return columns;
};

export default useConflictColumns;
