import { useNotification } from "@app/hooks";
import buildForm, { convertLabelToId } from "@components/FormBuilder";
import NSButton from "@components/NSButton";
import NSFormToolbar from "@components/NSFormToolbar";
import { useCreateERAMutation } from "@features/era/eraAPI";
import { Box, Paper } from "@material-ui/core";
import useERAFormDataPreparation from "@pages/ERA/hooks/useERAFormDataPreparation";
import useERAFormDefinition from "@pages/ERA/hooks/useERAFormDefinition";
import { FC, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const NewERA: FC = () => {
  const history = useHistory();

  const { showNotification } = useNotification();
  const { formFields, formSections } = useERAFormDefinition();
  const { prepareDataToCreate } = useERAFormDataPreparation(formFields);

  const [createERA, { data, isLoading, isSuccess, isError, error }] =
    useCreateERAMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (isSuccess || isError) {
      if (data?.era) {
        history.push(`/era/info/${data.era.id}`, {
          assessor: data.era,
        });
        showNotification("ERA is created");
        return;
      }
      showNotification(
        data?.message || (error as any)?.message || "Unknown error",
        "error"
      );
      return;
    }
  }, [isSuccess, isError, history, error, showNotification, data]);

  const submit = useCallback(
    (data) => {
      const preparedData = prepareDataToCreate(data);

      // const missingFields = validateMandatoryFields(
      //   preparedData,
      //   preparedData.status
      // );
      // if (missingFields.length > 0) {
      //   showNotification(
      //     "Mandatory fields are missing, please fill in all of them",
      //     "error"
      //   );
      //   missingFields.forEach((fieldName, idx) => {
      //     setTimeout(() => {
      //       setError(
      //         fieldName,
      //         {
      //           type: "required",
      //           message: "This field is required",
      //         },
      //         {
      //           shouldFocus: idx === 0,
      //         }
      //       );
      //     });
      //   });
      //   return;
      // }
      createERA(preparedData);
    },
    [createERA, prepareDataToCreate]
  );

  return (
    <Box display="flex" flexDirection="column">
      <NSFormToolbar title="New ERA">
        <NSButton
          loading={isLoading}
          color="primary"
          onClick={handleSubmit(submit)}
        >
          Submit
        </NSButton>
      </NSFormToolbar>
      <Box display="flex" overflow="auto">
        {formSections.map((fs) => (
          <Box key={convertLabelToId(`new era`)} component={Paper} p={2}>
            {buildForm({ control, errors, sections: fs })}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default NewERA;
