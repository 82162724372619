import { BaseResponse, Reference } from "@app/models";
import { axiosBaseQuery } from "@app/rtk-query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

export type ReferenceListResponse = {
  references: {
    primaryReference: Reference;
    secondaryReference: Reference;
  };
} & BaseResponse;

export type UpdateAssessorReferencesArg = {
  assessorId: number | string;
  data: {
    primaryReference: Partial<Reference>;
    secondaryReference: Partial<Reference>;
  };
};

/**
 * Reference RTK Query definitions
 */

const referenceQuery = createApi({
  reducerPath: "referenceQuery",
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getAssessorReferences: build.query<ReferenceListResponse, number | string>({
      query: (assessorId) => ({
        method: "get",
        url: `/assessors/references/${assessorId}`,
      }),
    }),
    updateAssessorReferences: build.mutation<
      BaseResponse,
      UpdateAssessorReferencesArg
    >({
      query: ({ assessorId, data }) => ({
        method: "post",
        url: `/assessors/references/${assessorId}`,
        data,
      }),
    }),
  }),
});

export const {
  useGetAssessorReferencesQuery,
  useUpdateAssessorReferencesMutation,
} = referenceQuery;

export default referenceQuery;
