import { memo, useCallback, useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  // Handler to call on window resize
  const handleResize = useCallback(() => {
    setWindowSize({
      width: window.outerWidth,
      height: window.outerHeight,
    });
  }, []);

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);
  return windowSize;
}

const ReactiveViewport = () => {
  const windowSize = useWindowSize();
  const content = useMemo(() => {
    if (windowSize.width > 800) {
      return `width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no`;
    }
    return "width=800, user-scalable=no";
  }, [windowSize.width]);

  return (
    <Helmet>
      <meta name="viewport" content={content} />
    </Helmet>
  );
};

export default memo(ReactiveViewport);
