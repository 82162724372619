import { useAppDispatch, useNotification } from "@app/hooks";
import { useCreateQualificationMutation } from "@features/general/generalAPI";
import { getQualificationListSilently } from "@features/general/generalSlice";
import { useEffect } from "react";

const useCreateQualification = ({ callback }: { callback: () => any }) => {
  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();
  const [createQualification, { isLoading: isCreating, isSuccess, isError }] =
    useCreateQualificationMutation();

  useEffect(() => {
    if (isSuccess) {
      showNotification("Qualification is updated");
      dispatch(getQualificationListSilently());
      callback();
      return;
    }
    if (isError) {
      showNotification("Cannot update this qualification.", "error");
    }
  }, [isSuccess, isError, showNotification, callback, dispatch]);

  return {
    createQualification,
    isCreating,
  };
};

export default useCreateQualification;
