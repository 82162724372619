import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AsyncStatus,
  Nullable,
  Qualification,
  QualificationLevel,
  Role,
} from "@app/models";
import {
  GeneralResponse,
  GetQualificationLevelListResponse,
  GetQualificationListResponse,
  sendGetQualificationLevelListRequest,
  sendGetQualificationListRequest,
  sendGetRoleListRequest,
} from "./generalAPI";

export interface GeneralState {
  roles: Nullable<Array<Role>>;
  qualifications: Nullable<Array<Qualification>>;
  qualificationLevels: Nullable<Array<QualificationLevel>>;
  status: AsyncStatus;
}

const initialState: GeneralState = {
  roles: null,
  qualifications: null,
  qualificationLevels: null,
  status: "idle",
};

export const getGeneralItems = createAsyncThunk<GeneralResponse>(
  "general/getGeneralItems",
  async (_, { rejectWithValue }) => {
    const getRoleListRes = sendGetRoleListRequest();
    const getQualificationListRes = sendGetQualificationListRequest();
    const getQualificationLevelListRes = sendGetQualificationLevelListRequest();

    const allRes = await Promise.all([
      getRoleListRes,
      getQualificationListRes,
      getQualificationLevelListRes,
    ]);

    if (allRes.includes(null)) {
      return rejectWithValue(null);
    }

    const result = allRes.reduce((previous, current) => {
      return {
        ...previous,
        ...current,
      };
    }, {});

    return result as GeneralResponse;
  }
);

export const getQualificationListSilently =
  createAsyncThunk<GetQualificationListResponse>(
    "general/getQualificationListSilently",
    async (_, { rejectWithValue }) => {
      const response = await sendGetQualificationListRequest();
      if (!response) {
        return rejectWithValue(null);
      }
      return response;
    }
  );

export const getQualificationLevelListSilently =
  createAsyncThunk<GetQualificationLevelListResponse>(
    "general/getQualificationLevelListSilently",
    async (_, { rejectWithValue }) => {
      const response = await sendGetQualificationLevelListRequest();
      if (!response) {
        return rejectWithValue(null);
      }
      return response;
    }
  );

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    resetGeneral: (state) => {
      state.roles = null;
      state.qualifications = null;
      state.qualificationLevels = null;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralItems.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getGeneralItems.fulfilled, (state, action) => {
        state.status = "idle";
        state.roles = action.payload.roles;
        state.qualifications = action.payload.qualifications;
        state.qualificationLevels = action.payload.qualificationLevels;
      })
      .addCase(getGeneralItems.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getQualificationListSilently.fulfilled, (state, action) => {
        state.qualifications = action.payload.qualifications;
      })
      .addCase(getQualificationLevelListSilently.fulfilled, (state, action) => {
        state.qualificationLevels = action.payload.qualificationLevels;
      });
  },
});

export const { resetGeneral } = generalSlice.actions;

export default generalSlice.reducer;
