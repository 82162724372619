import { makeStyles, Theme } from "@material-ui/core";
import { GridColumnMenu, GridColumnMenuProps } from "@mui/x-data-grid";
import { memo } from "react";

const useStyles = makeStyles(
  (theme: Theme) => ({
    columnMenu: {
      boxShadow: theme.shadows[6],
      borderRadius: theme.shape.borderRadius,
    },
  }),
  {
    classNamePrefix: "NSDataGridColumnMenu",
  }
);

export function NSDataGridColumnMenu(props: GridColumnMenuProps) {
  const classes = useStyles();
  const { hideMenu, currentColumn, ...other } = props;

  return (
    <GridColumnMenu
      hideMenu={hideMenu}
      currentColumn={currentColumn}
      className={classes.columnMenu}
      {...other}
    />
  );
}
export default memo(NSDataGridColumnMenu);
