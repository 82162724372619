import { createContext, useContext, ProviderProps } from "react";

export interface PasswordDialogContextValue {
  isPasswordDialogOpened: boolean;
  openPasswordDialog: () => void;
  closePasswordDialog: () => void;
}

const PasswordDialogContext = createContext<PasswordDialogContextValue>({
  isPasswordDialogOpened: false,
  openPasswordDialog: () => null,
  closePasswordDialog: () => null,
});

export const usePasswordDialog = () => useContext(PasswordDialogContext);

export const PasswordDialogProvider = (
  props: ProviderProps<PasswordDialogContextValue>
) => {
  return <PasswordDialogContext.Provider {...props} />;
};
