import { usePasswordDialog } from "@app/contexts";
import { Dialog, DialogProps } from "@material-ui/core";
import { useCallback } from "react";
import PasswordUpdate from "./PasswordUpdate";

const PasswordUpdateDialog = () => {
  const { isPasswordDialogOpened, closePasswordDialog } = usePasswordDialog();

  const handleOnClose = useCallback<Required<DialogProps>["onClose"]>(
    (_, reason) => {
      if (reason === "backdropClick") {
        return;
      }
      closePasswordDialog();
    },
    [closePasswordDialog]
  );

  return (
    <Dialog open={isPasswordDialogOpened} onClose={handleOnClose} fullWidth>
      {isPasswordDialogOpened && <PasswordUpdate />}
    </Dialog>
  );
};

export default PasswordUpdateDialog;
