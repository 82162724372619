import { BaseResponse, AssessorDevelopment } from "@app/models";
import { axiosBaseQuery } from "@app/rtk-query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

export interface DevelopmentListResponse extends BaseResponse {
  developments: AssessorDevelopment[];
}

export type CreateDevelopmentArg = Omit<AssessorDevelopment, "id">;

export interface CreateDevelopmentResponse extends BaseResponse {
  development: AssessorDevelopment;
}

export type UpdateDevelopmentArg = Partial<Omit<AssessorDevelopment, "id">> &
  Required<Pick<AssessorDevelopment, "id">>;

/**
 * Reference RTK Query definitions
 */

const assessorDevelopmentQuery = createApi({
  reducerPath: "assessorDevelopmentQuery",
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  tagTypes: ["Development"],
  endpoints: (build) => ({
    getDevelopmentList: build.query<DevelopmentListResponse, number | string>({
      query: (assessorId) => ({
        method: "get",
        url: `/assessor-developments?assessorId=${assessorId}`,
      }),
      providesTags: [{ type: "Development", id: "AssessorDevelopmentList" }],
    }),
    createDevelopment: build.mutation<
      CreateDevelopmentResponse,
      CreateDevelopmentArg
    >({
      query: (data) => ({
        method: "post",
        url: `/assessor-developments`,
        data,
      }),
      invalidatesTags: [{ type: "Development", id: "AssessorDevelopmentList" }],
    }),
    updateDevelopment: build.mutation<BaseResponse, UpdateDevelopmentArg>({
      query: ({ id, ...data }) => ({
        method: "patch",
        url: `/assessor-developments/${id}`,
        data,
      }),
    }),
  }),
});

export const {
  useGetDevelopmentListQuery,
  useCreateDevelopmentMutation,
  useUpdateDevelopmentMutation,
} = assessorDevelopmentQuery;

export default assessorDevelopmentQuery;
