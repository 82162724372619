import {
  FormDataFieldType,
  FormFieldType,
  FormSectionType,
  validateDataField,
} from "@components/FormBuilder";
import { useMemo } from "react";

/**
 * Form definitions
 */

const baseReference: FormFieldType[] = [
  {
    name: "id",
    type: "hidden",
  },
  {
    name: "refFirstname",
    label: "First name",
    type: "text",
  },
  {
    name: "refSurname",
    label: "Surname",
    type: "text",
  },
  {
    type: "blank",
  },
  {
    name: "refEmail",
    label: "Email",
    type: "text",
  },
  {
    name: "refTelPrimary",
    label: "Phone number",
    type: "text",
  },
  {
    type: "blank",
  },
  {
    name: "refPosition",
    label: "Position",
    type: "text",
  },
  {
    name: "refCompanyName",
    label: "Company name",
    type: "text",
  },
  {
    type: "blank",
  },
  {
    name: "refSentOn",
    label: "Sent on",
    type: "date",
  },
  {
    name: "refReceivedOn",
    label: "Received on",
    type: "date",
  },
  {
    type: "blank",
  },
];

const primaryReferenceFields = baseReference.map((ref) => {
  if (validateDataField(ref)) {
    return {
      ...ref,
      name: `primaryReference.${(ref as FormDataFieldType).name}`,
    };
  }
  return ref;
});

const secondaryReferenceFields = baseReference.map((ref) => {
  if (validateDataField(ref)) {
    return {
      ...ref,
      name: `secondaryReference.${(ref as FormDataFieldType).name}`,
    };
  }
  return ref;
});

const staticFormSections: FormSectionType[] = [
  {
    label: "Primary reference",
    fields: primaryReferenceFields,
  },
  {
    label: "Secondary reference",
    fields: secondaryReferenceFields,
  },
];

const staticFormFields = [
  ...primaryReferenceFields,
  ...secondaryReferenceFields,
].filter((f) => validateDataField(f)) as FormDataFieldType[];

/**
 * Hooks to get form definition objects
 */
export const useReferencesFormDefinition = () => {
  const formSections = useMemo(() => {
    return staticFormSections;
  }, []);

  return {
    formFields: staticFormFields,
    formSections,
  };
};

export default useReferencesFormDefinition;
