interface Environment {
  name: string;
  baseURL: string;
}

// export const getBaseURL = () => {
//   const urlDomain: string = `${process.env.REACT_APP_BASE_URL}`
//     .split(":")
//     .slice(0, 2)
//     .join(":");
//   if (window.location.origin.includes(urlDomain)) {
//     return process.env.REACT_APP_BASE_URL as string;
//   }
//   return process.env.REACT_APP_BASE_URL_IP as string;
// };

const environment: Environment = {
  name: process.env.REACT_APP_ENV ?? "",
  baseURL: process.env.REACT_APP_BASE_URL as string,
};

export default environment;
