import { memo } from "react";
import { ReactElement } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AssessorInfo from "./AssessorInfo";
import AssessorList from "./AssessorList";
import NewAssessor from "./NewAssessor";

const Assessor = (): ReactElement => {
  return (
    <Switch>
      <Route exact path="/assessor/list" component={AssessorList} />
      <Route exact path="/assessor/info/:id" component={AssessorInfo} />
      <Route exact path="/assessor/new" component={NewAssessor} />
      <Route>
        <Redirect to="/assessor/list" />
      </Route>
    </Switch>
  );
};

export default memo(Assessor);
