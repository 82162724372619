import { BaseResponse, User, UserWithPassword } from "@app/models";
import { axiosBaseQuery } from "@app/rtk-query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

export type UserListResponse = {
  message: string;
  users?: User[];
};

export type UserInfoResponse = {
  message: string;
  user?: User | null;
};

export type UpdateUserArg = Partial<User> & Pick<User, "id">;

export type CreateUserArg = Omit<UserWithPassword, "id">;

/**
 * User RTK Query definitions
 */

const userQuery = createApi({
  reducerPath: "userQuery",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["user"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getUserList: build.query<UserListResponse, void>({
      query: (params) => ({
        method: "get",
        url: "/users",
        params,
      }),
    }),
    getUser: build.query<UserInfoResponse, number | string>({
      query: (id: number | string) => ({
        method: "get",
        url: `/users/${id}`,
      }),
    }),
    updateUser: build.mutation<UserInfoResponse, UpdateUserArg>({
      query: ({ id, ...patchData }) => ({
        method: "patch",
        url: `/users/${id}`,
        data: patchData,
      }),
    }),
    createUser: build.mutation<UserInfoResponse, CreateUserArg>({
      query: (data) => ({
        method: "post",
        url: "/users",
        data,
      }),
    }),
    resetUserPassword: build.query<BaseResponse, number | string>({
      query: (id: number | string) => ({
        method: "get",
        url: `/users/reset-password/${id}`,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useGetUserListQuery,
  useLazyResetUserPasswordQuery,
} = userQuery;

export default userQuery;
