import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import SectionNotAvailable from "../SectionNotAvailable";
import AssessorDevelopment from "./AssessorDevelopment";

const AssessorDevelopmentSection: FunctionComponent = () => {
  const { id } = useParams<Record<"id", string>>();

  if (!id) {
    return <SectionNotAvailable />;
  }

  return <AssessorDevelopment />;
};

export default AssessorDevelopmentSection;
