import { useNotification } from "@app/hooks";
import buildForm, { convertLabelToId } from "@components/FormBuilder";
import NSButton from "@components/NSButton";
import NSFormToolbar from "@components/NSFormToolbar";
import { useCreateAssessorMutation } from "@features/assessor/assessorAPI";
import { AssessorStatus } from "@features/assessor/assessorConstant";
import { Box, Paper, Tab, Tabs } from "@material-ui/core";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import useAssessorFormTab from "../contexts/AssessorFormTab";
import validateMandatoryFields from "../helpers/validateMandatoryFields";
import useAssessorFormDataPreparation from "../hooks/useAssessorFormDataPreparation";
import useAssessorFormDefinition from "../hooks/useAssessorFormDefinition";
import Portrait from "../Portrait";

const NewAssessorForm = () => {
  const [
    createAssessor,
    { data: mutationResponse, isLoading, isSuccess, isError, error },
  ] = useCreateAssessorMutation();

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm();
  const watchStatus = watch("status", "");

  useEffect(() => {
    setValue("status", AssessorStatus.NAR);
  }, [setValue]);

  useEffect(() => {
    if (watchStatus) {
      clearErrors();
    }
  }, [watchStatus, clearErrors]);

  const { showNotification } = useNotification();
  const { formFields, formSections, formTabs } = useAssessorFormDefinition();
  const { prepareDataToCreate } = useAssessorFormDataPreparation(formFields);

  const history = useHistory();

  const submit = useCallback(
    (data) => {
      const preparedData = prepareDataToCreate(data);

      const missingFields = validateMandatoryFields(
        preparedData,
        preparedData.status
      );
      if (missingFields.length > 0) {
        showNotification(
          "Mandatory fields are missing, please fill in all of them",
          "error"
        );
        missingFields.forEach((fieldName, idx) => {
          setTimeout(() => {
            setError(
              fieldName,
              {
                type: "required",
                message: "This field is required",
              },
              {
                shouldFocus: idx === 0,
              }
            );
          });
        });
        return;
      }
      createAssessor(preparedData);
    },
    [createAssessor, prepareDataToCreate, setError, showNotification]
  );

  useEffect(() => {
    if (isSuccess || isError) {
      if (mutationResponse?.assessor) {
        history.push(`/assessor/info/${mutationResponse.assessor.id}`, {
          assessor: mutationResponse.assessor,
        });
        showNotification("Assessor is created");
        return;
      }
      showNotification(
        mutationResponse?.message || (error as any)?.message || "Unknown error",
        "error"
      );
      return;
    }
  }, [isSuccess, isError, mutationResponse, history, error, showNotification]);

  const { tab, changeTab } = useAssessorFormTab();

  const handleChange = (
    _: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    changeTab(newValue);
  };

  return (
    <Box display="flex" flexDirection="column">
      <NSFormToolbar title="New assessor">
        <NSButton
          loading={isLoading}
          color="primary"
          onClick={handleSubmit(submit)}
        >
          Submit
        </NSButton>
      </NSFormToolbar>
      <Box display="flex" mx={-1}>
        <Box mx={1} maxWidth={300}>
          <Portrait />
        </Box>
        <Box flex={1} mx={1} overflow="auto">
          <Paper className="mb-2 overflow-hidden">
            <Tabs value={tab} onChange={handleChange} indicatorColor="primary">
              {formTabs.map((ft) => (
                <Tab key={convertLabelToId(ft)} label={ft} />
              ))}
            </Tabs>
          </Paper>
          {formSections.map((fs, idx) => (
            <Box
              key={convertLabelToId(`form section ${formTabs[idx]}`)}
              component={Paper}
              p={2}
              display={tab === idx ? undefined : "none"}
            >
              {buildForm({ control, errors, sections: fs })}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default NewAssessorForm;
