import environment from "@app/environment";
import { useAppSelector } from "@app/hooks";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { io, Socket } from "socket.io-client";

const AppSocketContext = createContext<Socket | null>(null);

export const useAppSocket = () => {
  return useContext(AppSocketContext);
};

const AppSocketProvider = ({ children }: Record<"children", ReactNode>) => {
  const token = useAppSelector((state) => state.auth.token);
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const resetSocket = () => {
      setSocket((currentSocket) => {
        currentSocket?.disconnect();
        return null;
      });
    };

    if (token) {
      setSocket(
        io(environment.baseURL + "/io", {
          transports: ["websocket"],
          auth: {
            token,
          },
        })
      );
    } else {
      resetSocket();
    }
    return () => {
      resetSocket();
    };
  }, [token]);

  return (
    <AppSocketContext.Provider value={socket}>
      {children}
    </AppSocketContext.Provider>
  );
};

export default AppSocketProvider;
