import { Dialog, CircularProgress, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import AppLoadingIndicatorContext from "./LoadingIndicatorContext";

const useStyles = makeStyles(
  (theme: Theme) => {
    const darkMode = theme.palette.type === "dark";
    const defaultBg = "rgba(0, 0, 0, 0.4)";
    return {
      paper: {
        backgroundColor: defaultBg,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      lightBg: {
        backgroundColor: theme.palette.primary.contrastText,
      },
      darkBg: {
        backgroundColor: darkMode
          ? theme.palette.background.default
          : theme.palette.primary.main,
      },
      progress: {
        color: theme.palette.getContrastText(defaultBg),
      },
      lightProgress: {
        color: theme.palette.primary.main,
      },
      darkProgress: {
        color: darkMode
          ? theme.palette.primary.main
          : theme.palette.primary.contrastText,
      },
    };
  },
  {
    classNamePrefix: "AppLoadingIndicator",
  }
);

const AppLoadingIndicator = () => {
  const classes = useStyles();
  return (
    <AppLoadingIndicatorContext.Consumer>
      {({ open, variant }) => (
        <Dialog
          fullScreen
          fullWidth
          open={open}
          classes={{
            paper: clsx(classes.paper, {
              [classes.lightBg]: variant === "light",
              [classes.darkBg]: variant === "dark",
            }),
          }}
        >
          <CircularProgress
            className={clsx(classes.progress, {
              [classes.lightProgress]: variant === "light",
              [classes.darkProgress]: variant === "dark",
            })}
          />
        </Dialog>
      )}
    </AppLoadingIndicatorContext.Consumer>
  );
};

export default AppLoadingIndicator;
