import { useAppSelector } from "@app/hooks";
import { EmailPattern } from "@app/regex-patterns";
import {
  FormDataFieldType,
  FormFieldType,
  validateDataField,
  FormBuilderSections,
} from "@components/FormBuilder";
import { useGetAssessorListQuery } from "@features/assessor/assessorAPI";
import { AssessorStatusList } from "@features/assessor/assessorConstant";
import { useMemo } from "react";
import AssessorConflictsSection from "../components/AssessorConflictsSection";
import AssessorDevelopmentSection from "../components/AssessorDevelopmentSection";
import AssessorStandardsSection from "../components/AssessorStandardsSection";

/**
 * Form definitions
 */

const personalFields: FormFieldType[] = [
  {
    name: "id",
    type: "hidden",
  },
  {
    name: "firstname",
    label: "First name",
    type: "text",
  },
  {
    name: "surname",
    label: "Surname",
    type: "text",
  },
  {
    type: "blank",
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    rules: { required: true },
    selectOptions: AssessorStatusList.map((s) => ({
      id: s.value,
      name: s.label,
    })),
  },
  {
    name: "dob",
    label: "Date of birth",
    type: "date",
  },
  {
    name: "gender",
    label: "Gender",
    type: "select",
    selectOptions: [
      {
        id: 0,
        name: "Male",
      },
      {
        id: 1,
        name: "Female",
      },
    ],
  },
  {
    type: "title",
    label: "Contact details",
  },
  {
    name: "telPrimary",
    label: "Primary phone number",
    type: "text",
  },
  {
    name: "telSecondary",
    label: "Secondary phone number",
    type: "text",
  },
  { type: "blank" },
  {
    name: "email",
    label: "Email",
    type: "email",
    rules: {
      pattern: {
        value: EmailPattern,
        message: "Please enter a valid email",
      },
    },
  },
  {
    name: "emailSecondary",
    label: "Secondary email",
    type: "email",
    rules: {
      pattern: {
        value: EmailPattern,
        message: "Please enter a valid email",
      },
    },
  },
  { type: "blank" },
  {
    name: "address1",
    label: "Street address",
    type: "text",
  },
  {
    name: "townCity",
    label: "Town city",
    type: "text",
  },
  { type: "blank" },
  {
    name: "county",
    label: "County",
    type: "text",
  },
  {
    name: "postcode",
    label: "Postcode",
    type: "text",
  },
  { type: "blank" },

  {
    type: "title",
    label: "Right to work",
  },
  {
    name: "passportCopyOnFile",
    label: "Passport copy on file",
    type: "checkbox",
    slot: 1,
  },
  {
    name: "birthCertCopyOnFile",
    label: "Birth Certificate copy on file",
    type: "checkbox",
    slot: 1,
  },
  {
    name: "visaCopyOnFile",
    label: "Visa copy on file",
    type: "checkbox",
    slot: 1,
  },
];

const nokFields: FormFieldType[] = [
  {
    name: "nokFullname",
    label: "Full name",
    type: "text",
  },
  {
    name: "nokTelPrimary",
    label: "Phone number",
    type: "text",
  },
];

const applicationFields: FormFieldType[] = [
  {
    name: "interviewDate",
    label: "Interview date",
    type: "date",
  },
  {
    name: "interviewAttended",
    label: "Interview attended",
    type: "checkbox",
  },
  { type: "blank" },
  {
    label: "Curriculum Vitae",
    type: "title",
  },
  {
    name: "cvReceivedOn",
    label: "CV received on",
    type: "date",
  },
  {
    name: "cvCopyOnFile",
    label: "Copy on file",
    type: "checkbox",
  },
  {
    name: "appFormCompleted",
    label: "Application form completed",
    type: "checkbox",
  },
  {
    name: "ndaSentOn",
    label: "NDA sent",
    type: "date",
  },
  {
    name: "ndaReceivedOn",
    label: "NDA received",
    type: "date",
  },
  { type: "blank" },
  {
    name: "consAgreeSentOn",
    label: "Consultant agreement sent",
    type: "date",
  },
  {
    name: "consAgreeReceivedOn",
    label: "Consultant agreement received",
    type: "date",
  },
  { type: "blank" },
  {
    name: "jdSentOn",
    label: "JD sent",
    type: "date",
  },
  {
    name: "jdReceivedOn",
    label: "JD received",
    type: "date",
  },
];

const profileFields: FormFieldType[] = [
  {
    name: "shortBio",
    label: "Short bio",
    type: "text",
    slot: 3,
    minRows: 6,
    maxRows: 15,
    maxLength: 2500,
  },
  {
    type: "title",
    label: "Qualifications",
  },
  {
    name: "assessorAward",
    label: "Assessor award",
    type: "select",
    selectOptions: [
      {
        id: 1,
        name: "Yes",
      },
      {
        id: 2,
        name: "No",
      },
      {
        id: 3,
        name: "In progress",
      },
    ],
  },
  {
    name: "ivAward",
    label: "IV award",
    type: "select",
    selectOptions: [
      {
        id: 1,
        name: "Yes",
      },
      {
        id: 2,
        name: "No",
      },
      {
        id: 3,
        name: "In progress",
      },
    ],
  },
  { type: "blank" },
  {
    type: "title",
    label: "Other qualifications",
  },
  {
    name: "qualificationId",
    label: "Qualification",
    type: "select",
    slot: 2,
    selectOptions: [],
  },
  { type: "blank" },
  {
    name: "qualificationLevelId",
    label: "Level",
    type: "select",
    selectOptions: [],
  },
  {
    name: "qualificationCopyOnFile",
    label: "Copy on file",
    type: "checkbox",
  },
  {
    type: "blank",
  },
  {
    name: "cieaRegistration",
    label: "CIEA registration",
    type: "select",
    selectOptions: [
      {
        id: 1,
        name: "Yes",
      },
      {
        id: 2,
        name: "No",
      },
      {
        id: 3,
        name: "In progress",
      },
    ],
  },
];

const trainingFields: FormFieldType[] = [
  {
    name: "inductionDate",
    label: "Induction date",
    type: "date",
  },
  {
    name: "safeguardingCertExpiredOn",
    label: "Safeguarding certificate expiry",
    type: "date",
    mode: "monthAndYear",
  },
  {
    type: "blank",
  },
  {
    name: "edCertCompletedOn",
    label: "E&D certificate completed",
    type: "date",
    mode: "monthAndYear",
  },
  {
    name: "edCertExpiredOn",
    label: "E&D certificate expiry (Auto generated)",
    type: "date",
    mode: "monthAndYear",
    disabled: true,
  },
  {
    type: "blank",
  },
  {
    name: "gdprPolicyReviewedOn",
    label: "GDPR policy reviewed",
    type: "date",
  },
  {
    name: "gdprPolicyExpiredOn",
    label: "GDPR policy expired (Auto generated)",
    type: "date",
    disabled: true,
  },
  {
    name: "handbookSent",
    label: "Code of Conduct and Staff handbook",
    type: "checkbox",
  },
];

const dbsFields: FormFieldType[] = [
  {
    name: "dbsSentOn",
    label: "DBS sent",
    type: "date",
  },
  {
    name: "dbsReceivedOn",
    label: "DBS start date",
    type: "date",
  },
  { type: "blank" },
  {
    name: "dbsNumber",
    label: "DBS number",
    type: "text",
  },
  {
    name: "dbsExpiredOn",
    label: "DBS expiry (Auto generated)",
    type: "date",
    disabled: true,
  },
  { type: "blank" },
];

const complianceFields: FormFieldType[] = [
  {
    name: "cpdLogReceivedOn",
    label: "CPD Log received",
    type: "date",
    mode: "monthAndYear",
  },
  {
    name: "cpdLogExpiredOn",
    label: "CPD Log expiry (Auto generated)",
    type: "date",
    mode: "monthAndYear",
    disabled: true,
  },
  { type: "blank" },
  // {
  //   type: "title",
  //   label: "Conflict of interest",
  // },
  // {
  //   name: "declarationCompletedOn",
  //   label: "Declaration Completed",
  //   type: "date",
  // },
  // {
  //   name: "declarationCopyOnFile",
  //   label: "Copy on file",
  //   type: "checkbox",
  // },
  // {
  //   name: "conflictDetails",
  //   label: "Conflict details",
  //   type: "text",
  //   slot: 3,
  //   minRows: 3,
  //   maxRows: 15,
  // },
];

const signOffFields: FormFieldType[] = [
  // {
  //   name: "boardSignOff",
  //   label: "Board sign-off",
  //   type: "date",
  // },
  // {
  //   name: "emailGranted",
  //   label: "Marshall Assessment email account",
  //   type: "checkbox",
  //   slot: 3,
  // },
  {
    name: "ace360Granted",
    label: "ACE360 log in",
    type: "checkbox",
    slot: 3,
  },
  // {
  //   name: "signOffFromLead",
  //   label: "Sign off from Lead Assessor",
  //   type: "checkbox",
  //   slot: 3,
  // },
  {
    name: "leadAssessorSignOff",
    label: "Lead Assessor sign off",
    type: "select",
    selectOptions: [],
  },
  {
    name: "leadAssessorSignOffDate",
    label: "Lead Assessor sign off date",
    type: "date",
  },
];

const performanceMonitorFields: FormFieldType[] = [
  {
    name: "performanceOverprompting",
    label: "Overprompting",
    type: "checkbox",
    slot: 3,
  },
  {
    name: "performanceAffirmativeLanguageUsed",
    label: "Affirmative language used",
    type: "checkbox",
    slot: 3,
  },
  {
    name: "performanceNotEnoughDetailInAssessorNotes",
    label: "Not enough detail in assessor notes",
    type: "checkbox",
    slot: 3,
  },
  {
    name: "performanceIncompleteApprenticeResponseNotes",
    label: "Incomplete Apprentice response notes",
    type: "checkbox",
    slot: 3,
  },
  {
    name: "performanceUnclearRationaleForVcdMarks",
    label: "Unclear rationale for VCD marks",
    type: "checkbox",
    slot: 3,
  },
  {
    name: "performanceUnclearRationaleForScs",
    label: "Unclear rationale for SCS",
    type: "checkbox",
    slot: 3,
  },
  {
    name: "performanceTimeKeepingIssues",
    label: "Time keeping issues",
    type: "checkbox",
    slot: 3,
  },
  {
    name: "performanceDelayInMarking",
    label: "Delay in marking",
    type: "checkbox",
    slot: 3,
  },
  {
    name: "performanceMonitorNotes",
    label: "Notes",
    type: "text",
    minRows: 6,
    maxRows: 10,
    maxLength: 500,
    slot: 2,
  },
  {
    type: "blank",
  },
  {
    name: "performanceEpaDate",
    label: "EPA date",
    type: "date",
  },
  {
    name: "performanceApprenticeName",
    label: "Apprentice name",
    type: "text",
  },
  {
    type: "blank",
  },
];

export const staticFormTabs: string[] = [
  "Details",
  "Sign off to Active",
  "Performance",
];

const staticFormSections: FormBuilderSections[] = [
  [
    {
      label: "Primary Information",
      type: "default",
      fields: personalFields,
    },
    {
      label: "Next of kin",
      type: "default",
      fields: nokFields,
    },
    {
      label: "Application",
      type: "default",
      fields: applicationFields,
    },
    {
      label: "Profile",
      type: "default",
      fields: profileFields,
    },
    {
      label: "Training",
      type: "default",
      fields: trainingFields,
    },
    {
      label: "Standardisation",
      type: "custom",
      component: AssessorStandardsSection,
    },
    {
      label: "Conflicts",
      type: "custom",
      component: AssessorConflictsSection,
    },
    {
      label: "Assessor development",
      type: "custom",
      component: AssessorDevelopmentSection,
    },
    {
      label: "Disclosure and Barring Service",
      type: "default",
      fields: dbsFields,
    },
    {
      label: "Compliance",
      type: "default",
      fields: complianceFields,
    },
  ],
  [
    {
      label: "Sign off list",
      type: "default",
      fields: signOffFields,
    },
  ],
  [
    {
      label: "Performance monitor",
      type: "default",
      fields: performanceMonitorFields,
    },
  ],
];

const staticFormFields = [
  ...personalFields,
  ...nokFields,
  ...applicationFields,
  ...profileFields,
  ...trainingFields,
  ...dbsFields,
  ...complianceFields,
  ...signOffFields,
  ...performanceMonitorFields,
].filter((f) => validateDataField(f)) as FormDataFieldType[];

/**
 * Hooks to get form definition objects
 */
export const useAssessorFormDefinition = () => {
  const qualificationList = useAppSelector((state) =>
    state.general.qualifications?.map((qual) => ({
      ...qual,
      disabled: Boolean(qual.deletedAt),
    }))
  );
  const qualificationLevelList = useAppSelector((state) =>
    state.general.qualificationLevels?.map((lvl) => ({
      ...lvl,
      disabled: Boolean(lvl.deletedAt),
    }))
  );
  const { data: assessorListResponse } = useGetAssessorListQuery();

  const leadAssessorSignOffOptions = useMemo(() => {
    if (!assessorListResponse) {
      return [];
    }
    return assessorListResponse.assessors.map((a) => ({
      id: a.id,
      name: `${a.firstname} ${a.surname}`,
    }));
  }, [assessorListResponse]);

  const formSections = useMemo(() => {
    if (!qualificationList || !qualificationLevelList) {
      return staticFormSections;
    }
    return staticFormSections.map((tab) =>
      tab.map((sec) => {
        if (sec.type !== "custom") {
          if (sec.label === "Profile") {
            return {
              ...sec,
              fields: sec.fields.map((secField) => {
                if (
                  secField.type === "select" &&
                  secField.name === "qualificationId"
                ) {
                  return {
                    ...secField,
                    selectOptions: [
                      ...secField.selectOptions,
                      ...qualificationList,
                    ],
                  };
                }
                if (
                  secField.type === "select" &&
                  secField.name === "qualificationLevelId"
                ) {
                  return {
                    ...secField,
                    selectOptions: [
                      ...secField.selectOptions,
                      ...qualificationLevelList,
                    ],
                  };
                }
                return secField;
              }),
            };
          }
          if (sec.label === "Sign off list") {
            return {
              ...sec,
              fields: sec.fields.map((secField) => {
                if (
                  secField.type === "select" &&
                  secField.name === "leadAssessorSignOff"
                ) {
                  return {
                    ...secField,
                    selectOptions: leadAssessorSignOffOptions,
                  };
                }
                return secField;
              }),
            };
          }
        }
        return sec;
      })
    );
  }, [qualificationList, qualificationLevelList, leadAssessorSignOffOptions]);

  return {
    formFields: staticFormFields,
    formSections,
    formTabs: staticFormTabs,
  };
};

export default useAssessorFormDefinition;
