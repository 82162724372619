import { getDataPatch, prepareData } from "@app/helpers";
import { Assessor } from "@app/models";
import { FormDataFieldType, FormDataType } from "@components/FormBuilder";
import {
  CreateAssessorArg,
  UpdateAssessorArg,
} from "@features/assessor/assessorAPI";
import { useCallback } from "react";

const useAssessorFormDataPreparation = (formFields: FormDataFieldType[]) => {
  const prepareDataToCreate = useCallback(
    (formData: FormDataType) => {
      const preparedData = prepareData<CreateAssessorArg>(
        formData,
        formFields,
        {
          withoutId: true,
        }
      );
      return preparedData;
    },
    [formFields]
  );

  const prepareDataToUpdate = useCallback(
    (
      formData: FormDataType & { id: string | number },
      { originalData }: { originalData: Assessor }
    ) => {
      const preparedData = prepareData<Assessor>(formData, formFields);
      const dataPatch = getDataPatch(originalData, preparedData);

      return { ...dataPatch, id: formData.id } as UpdateAssessorArg;
    },
    [formFields]
  );

  return {
    prepareDataToCreate,
    prepareDataToUpdate,
  };
};

export default useAssessorFormDataPreparation;
