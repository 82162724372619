import { prepareData } from "@app/helpers";
import { useNotification } from "@app/hooks";
import { AssessorConflict } from "@app/models";
import {
  buildFormSectionContent,
  FormSectionContentBuilderProps,
  FormSectionContentItem,
  FormTitle,
} from "@components/FormBuilder";
import NSDataGrid from "@components/NSDataGrid";
import {
  UpdateAssessorConflictArg,
  useCreateAssessorConflictMutation,
  useGetAssessorConflictListQuery,
  useUpdateAssessorConflictMutation,
} from "@features/assessor/assessorAPI";
import { Typography } from "@material-ui/core";
import { GridCellEditCommitParams } from "@mui/x-data-grid";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useConflictColumns from "./useConflictColumns";
import useConflictFormDefinition, {
  conflictFormDataFields,
} from "./useConflictFormDefinition";

const AssessorConflictsManagement = () => {
  const { id } = useParams<Record<"id", string>>();
  const { showNotification } = useNotification();

  const [rows, setRows] = useState<AssessorConflict[]>([]);

  const { data: queryResponse, isLoading } = useGetAssessorConflictListQuery(
    id,
    {
      skip: !id,
    }
  );

  // const { prepareDataToCreate } = useAssessorFormDataPreparation(
  //   conflictFormDataFields
  // );

  useEffect(() => {
    if (!queryResponse?.conflicts) {
      return;
    }
    setRows(queryResponse.conflicts);
  }, [queryResponse]);

  const [
    createConflict,
    { isLoading: isCreating, isSuccess: isCreated, isError: isCreatedError },
  ] = useCreateAssessorConflictMutation();

  const [
    updateConflict,
    { isLoading: isUpdating, isSuccess: isUpdated, isError: isUpdatedError },
  ] = useUpdateAssessorConflictMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      reason: "",
      location: "",
      declarationCompletedOn: null,
      declarationCopyOnFile: false,
      conflictDetails: "",
      signedOn: null,
      expiredOn: null,
      boardSignOff: null,
    },
  });

  const columns = useConflictColumns();

  const onAddConflictClick = handleSubmit((data) => {
    createConflict(
      prepareData({ ...data, assessorId: Number(id) }, conflictFormDataFields)
    );
  });

  const onCellEditCommit = useCallback(
    (params: GridCellEditCommitParams) => {
      const payload: Partial<UpdateAssessorConflictArg> = {
        assessorConflictId: params.id,
        assessorConflict: {
          [params.field]: params.value,
        } as UpdateAssessorConflictArg["assessorConflict"],
      };
      const editingField = conflictFormDataFields.filter(
        (f) => f.name === params.field
      );
      if (editingField.length === 0 || !payload.assessorConflict) {
        return;
      }
      payload.assessorConflict = prepareData(
        payload.assessorConflict,
        editingField
      );
      updateConflict(payload as UpdateAssessorConflictArg);
    },
    [updateConflict]
  );

  useEffect(() => {
    if (isCreated) {
      showNotification("A conflict is added successfully");
      reset();
    }
  }, [isCreated, reset, showNotification]);

  useEffect(() => {
    if (isUpdated) {
      showNotification("A conflict is updated successfully");
    }
  }, [isUpdated, showNotification]);

  useEffect(() => {
    if (isCreatedError || isUpdatedError) {
      showNotification(
        "Cannot create/update this conflict. Something went wrong.",
        "error"
      );
    }
  }, [isCreatedError, isUpdated, isUpdatedError, showNotification]);

  const conflictListComponent = useMemo(() => {
    return (
      <Fragment key="conflictListComponent">
        <FormSectionContentItem slot={3}>
          <FormTitle>Conflict list</FormTitle>
          <Typography variant="caption">
            (*Double click on a cell to edit)
          </Typography>
        </FormSectionContentItem>
        <FormSectionContentItem slot={3}>
          <NSDataGrid
            loading={isLoading || isUpdating}
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            cellFocusable
            onCellEditCommit={onCellEditCommit}
            stickyHeader={false}
            paperContainerProps={{
              variant: "outlined",
              className: "mt-2 mb-2",
            }}
          />
        </FormSectionContentItem>
      </Fragment>
    );
  }, [columns, isLoading, isUpdating, onCellEditCommit, rows]);

  const conflictFormDef = useConflictFormDefinition({
    submit: onAddConflictClick,
    isSubmitting: isCreating,
    conflictListComponent,
  });

  return buildFormSectionContent({
    control,
    errors,
    section: {
      fields: conflictFormDef,
      label: "Conflict",
    },
  } as unknown as FormSectionContentBuilderProps);
};

export default memo(AssessorConflictsManagement);
