import { useAppSelector } from "@app/hooks";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { memo, useCallback, useState } from "react";
import TopBarUserMenu from "./TopBarUserMenu";

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.grey[600],
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    rootShift: {
      width: `calc(100% - ${theme.sideBar.width}px)`,
      marginLeft: theme.sideBar.width,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      justifyContent: "space-between",
    },
    greeting: {
      marginRight: theme.spacing(1),
    },
    menuButton: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(-2),
    },
    profileButton: {
      marginRight: theme.spacing(-2),
      borderRadius: theme.spacing(3),
      textTransform: "none",
      padding: theme.spacing(1, 1.5),
    },
  }),
  { classNamePrefix: "TopBar" }
);

interface TopBarProps {
  sideBarOpen: boolean;
  toggleSideBar: () => void;
}

const TopBar = ({ sideBarOpen, toggleSideBar }: TopBarProps) => {
  const classes = useStyles();
  const userName = useAppSelector((state) => state.auth.name);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <AppBar
      position="fixed"
      color="inherit"
      elevation={6}
      className={clsx(classes.root, {
        [classes.rootShift]: sideBarOpen,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <div>
          <IconButton
            color="inherit"
            onClick={toggleSideBar}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <Box display="flex" alignItems="center">
          <Button className={classes.profileButton} onClick={openMenu}>
            <Typography component="span" className={classes.greeting}>
              Hello <b>{userName}</b>
            </Typography>
            <AccountCircleIcon />
          </Button>
        </Box>
      </Toolbar>
      <TopBarUserMenu anchorEl={anchorEl} onClose={closeMenu} />
    </AppBar>
  );
};

export default memo(TopBar);
