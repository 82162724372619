import {
  FormSectionContent,
  FormSectionContentItem,
} from "@components/FormBuilder";

const SectionNotAvailable = () => {
  return (
    <FormSectionContent>
      <FormSectionContentItem slot={3}>
        This section will be available after the assessor is created
      </FormSectionContentItem>
    </FormSectionContent>
  );
};
export default SectionNotAvailable;
