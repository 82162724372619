import assessorQuery from "@features/assessor/assessorAPI";
import referenceQuery from "@features/assessor/referenceAPI";
import developmentQuery from "@features/assessor/assessorDevelopmentAPI";
import authQuery from "@features/auth/authAPI";
import authReducer, { AuthState } from "@features/auth/authSlice";
import dashboardReducer from "@features/dashboard/dashboardSlice";
import generalReducer from "@features/general/generalSlice";
import generalQuery from "@features/general/generalAPI";
import userQuery from "@features/user/userAPI";
import eraQuery from "@features/era/eraAPI";
import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import localForage from "localforage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import environment from "./environment";
import standardQuery from "@features/standard/standardAPI";

const storage = localForage.createInstance({
  name: "3pa",
  driver: localForage.INDEXEDDB,
  storeName: "auth",
  version: 1.0,
});

const persistConfig: PersistConfig<AuthState> = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["authenticated"],
};

const reducer = combineReducers({
  dashboard: dashboardReducer,
  auth: persistReducer(persistConfig, authReducer),
  general: generalReducer,
  [assessorQuery.reducerPath]: assessorQuery.reducer,
  [userQuery.reducerPath]: userQuery.reducer,
  [referenceQuery.reducerPath]: referenceQuery.reducer,
  [developmentQuery.reducerPath]: developmentQuery.reducer,
  [authQuery.reducerPath]: authQuery.reducer,
  [generalQuery.reducerPath]: generalQuery.reducer,
  [standardQuery.reducerPath]: standardQuery.reducer,
  [eraQuery.reducerPath]: eraQuery.reducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      assessorQuery.middleware,
      userQuery.middleware,
      referenceQuery.middleware,
      developmentQuery.middleware,
      authQuery.middleware,
      generalQuery.middleware,
      standardQuery.middleware,
      eraQuery.middleware
    ),
  devTools: environment.name === "dev",
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
