import { memo } from "react";
import { ReactElement } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ConflictList from "./ConflictList";

const AssessorConflict = (): ReactElement => {
  return (
    <>
      <Switch>
        <Route exact path="/assessor-conflict/list" component={ConflictList} />
        <Route>
          <Redirect to="/assessor-conflict/list" />
        </Route>
      </Switch>
    </>
  );
};

export default memo(AssessorConflict);
