import { DefaultEmailDomain } from "@app/constants";
import { AxiosInstance } from "@app/controllers";
import { useAppDispatch, useAppSelector, useNotification } from "@app/hooks";
import Logo from "@components/Logo";
import NSButton from "@components/NSButton";
import NSTextField from "@components/NSTextField";
import { login } from "@features/auth/authSlice";
import { Box, Divider, makeStyles, Paper, Typography } from "@material-ui/core";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

const useStyles = makeStyles(
  (theme) => {
    const darkMode = theme.palette.type === "dark";
    return {
      container: {
        minHeight: "100vh",
        backgroundColor: darkMode
          ? theme.palette.background.default
          : theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      contentBox: {
        width: "100%",
        maxWidth: 420,
        padding: theme.spacing(4),
        paddingTop: theme.spacing(3),
      },
      logo: {
        width: "55%",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
      },
      greeting: {
        marginBottom: theme.spacing(1),
        width: "100%",
        textAlign: "center",
        color: theme.palette.text.primary,
      },
      message: {
        fontSize: theme.typography.pxToRem(12),
        textAlign: "center",
        color: theme.palette.text.secondary,
      },
    };
  },
  {
    classNamePrefix: "Login",
  }
);

const Login = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector((state) => state.auth.status);
  const loading = useMemo(() => authStatus === "pending", [authStatus]);
  const { showNotification, hideNotification } = useNotification();

  const {
    control,
    handleSubmit,
    formState: { isSubmitted },
  } = useForm();

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }
    if (authStatus === "failed") {
      showNotification("Incorrect email or password", "error");
    } else {
      hideNotification();
    }
  }, [isSubmitted, authStatus, showNotification, hideNotification]);

  const onLoginClick = useCallback(
    (data) => {
      AxiosInstance.init();
      const email = data.email.includes("@")
        ? data.email
        : `${data.email}${DefaultEmailDomain}`;

      dispatch(
        login({
          ...data,
          email,
        })
      );
    },
    [dispatch]
  );

  return (
    <div className={classes.container}>
      <Paper className={classes.contentBox}>
        <Box textAlign="center" mb={1}>
          <Logo className={classes.logo} />
        </Box>
        <Typography component="div" variant="h6" className={classes.greeting}>
          Please login to continue
        </Typography>
        <form onSubmit={handleSubmit(onLoginClick)}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <NSTextField
                {...field}
                margin="normal"
                label="Email"
                autoComplete="email"
                helperText={`Marshall Assessment email (username${DefaultEmailDomain}) is used by default if there is no email domain provided.`}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <NSTextField
                {...field}
                label="Password"
                margin="normal"
                type="password"
                autoComplete="password"
                helperText="Keep your password secure."
              />
            )}
          />
          <NSButton
            className="mt-3 mb-3"
            loading={loading}
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            type="submit"
          >
            Login
          </NSButton>
          <Divider className="mt-3 mb-5" />
          <Typography component="div" className={classes.message}>
            By default all our software runs in dark mode, this is to prevent
            eye strain, you can change this in settings. Give it a try though,
            you only get one pair!
          </Typography>
        </form>
      </Paper>
    </div>
  );
};

export default Login;
