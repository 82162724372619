import { useState } from "react";
import { useCallback } from "react";
import { ReactNode } from "react";
import LoadingIndicator from "./LoadingIndicator";
import LoadingIndicatorContext, {
  LoadingIndicatorVariant,
} from "./LoadingIndicatorContext";

type LoadingIndicatorProviderProps = {
  children: ReactNode;
};

const LoadingIndicatorProvider = ({
  children,
}: LoadingIndicatorProviderProps) => {
  const [open, setOpen] = useState(false);
  const [variant, setVariant] =
    useState<LoadingIndicatorVariant>("transparent");

  const show = useCallback(
    (inputVariant: LoadingIndicatorVariant = "transparent") => {
      setOpen(true);
      setVariant(inputVariant);
    },
    []
  );
  const hide = useCallback(() => setOpen(false), []);

  return (
    <LoadingIndicatorContext.Provider value={{ open, show, hide, variant }}>
      {children}
      <LoadingIndicator />
    </LoadingIndicatorContext.Provider>
  );
};

export default LoadingIndicatorProvider;
