import QualificationLevelList from "./QualificationLevelList";
import QualificationList from "./QualificationList";
import { Route, Switch, Redirect } from "react-router-dom";

const Qualification = () => {
  return (
    <Switch>
      <Route path="/qualification/list/:id?" component={QualificationList} />
      <Route
        exact
        path="/qualification-level/list/:id?"
        component={QualificationLevelList}
      />
      <Route path="/qualification-level">
        <Redirect to="/qualification-level/list" />
      </Route>
      <Route>
        <Redirect to="/qualification/list" />
      </Route>
    </Switch>
  );
};

export default Qualification;
