import axios, { AxiosRequestConfig } from "axios";
import environment from "./environment";

const config: AxiosRequestConfig = {
  baseURL: environment.baseURL,
  withCredentials: true,
};

const api = axios.create(config);

export default api;
