import { useAppDispatch } from "@app/hooks";
import {
  Summary,
  updateSummary,
  updateTotalAssessors,
  updateTotalUsers,
} from "@features/dashboard/dashboardSlice";
import { useEffect } from "react";
import { useAppSocket } from "../AppSocket";

type DashboardUpdateValue = {
  totalUsers?: number;
  totalAssessors?: number;
  summary?: Summary;
};

const useDashboard = () => {
  const socket = useAppSocket();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!socket) return;

    const onConnected = () => {
      socket.emit("watch-dashboard");
      socket.on("dashboard-update", (value: DashboardUpdateValue) => {
        if (value?.totalUsers) {
          dispatch(updateTotalUsers(value.totalUsers));
        }
        if (value?.totalAssessors) {
          dispatch(updateTotalAssessors(value.totalAssessors));
        }
        if (value?.summary) {
          dispatch(updateSummary(value.summary));
        }
      });
    };

    socket.on("connect", () => {
      onConnected();
    });

    return () => {
      socket.emit("unwatch-dashboard");
    };
  }, [socket, dispatch]);
};

export default useDashboard;
