import { formatDateToBackend, isValidDate } from "@app/helpers";
import { useNotification } from "@app/hooks";
import { AssessorStandard } from "@app/models";
import {
  FormSectionContent,
  FormSectionContentItem,
  FormTitle,
} from "@components/FormBuilder";
import NSButton from "@components/NSButton";
import NSDataGrid from "@components/NSDataGrid";
import NSDatePicker from "@components/NSDatePicker";
import NSSelect from "@components/NSSelect";
import {
  UpdateAssessorStandardArg,
  useCreateAssessorStandardsMutation,
  useGetStandardListQuery,
  useGetStandardsByAssessorIdQuery,
  useUpdateAssessorStandardMutation,
} from "@features/standard/standardAPI";
import { MenuItem, Typography } from "@material-ui/core";
import { GridCellEditCommitParams } from "@mui/x-data-grid";
import { format, isValid } from "date-fns";
import { memo, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import SectionNotAvailable from "../SectionNotAvailable";
import useStandardColumns from "./useStandardColumns";

const AssessorStandardsManagement = () => {
  const { id } = useParams<Record<"id", string>>();
  const [
    createAssessorStandard,
    { isLoading: isCreating, isSuccess: isCreated },
  ] = useCreateAssessorStandardsMutation();

  const { data: assessorStandardsResponse, refetch } =
    useGetStandardsByAssessorIdQuery(id, { skip: !id });

  const [
    updateAssessorStandard,
    { isLoading: isUpdating, isSuccess: isUpdateSuccess },
  ] = useUpdateAssessorStandardMutation();

  const { data: availableStandardList, isFetching } = useGetStandardListQuery();

  const { showNotification } = useNotification();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    selectedStandard: number;
    meetingDate: Date | null;
  }>({
    defaultValues: {
      selectedStandard: 0,
      meetingDate: null,
    },
  });

  const addStandard = handleSubmit((data) => {
    if (!data.meetingDate || !isValid(data.meetingDate)) {
      return;
    }
    createAssessorStandard({
      assessorId: id,
      standards: [
        {
          standardId: data.selectedStandard,
          meetingDate: format(new Date(data.meetingDate), "yyyy-MM-dd"),
        },
      ],
    });
  });

  useEffect(() => {
    if (isCreated) {
      showNotification("A standard is added successfully");
      reset();
    }
  }, [isCreated, showNotification, reset]);

  useEffect(() => {
    if (isUpdateSuccess) {
      showNotification("A standard is updated successfully");
    }
  }, [isUpdateSuccess, showNotification]);

  const onCellEditCommit = useCallback(
    (params: GridCellEditCommitParams) => {
      const payload: Partial<UpdateAssessorStandardArg> = {
        assessorStandardId: params.id,
      };
      switch (params.field) {
        case "meetingDate":
          payload.standard = {
            [params.field]: formatDateToBackend(params.value),
          } as Partial<AssessorStandard>;
          break;
        case "standardId":
        case "isAttended":
          payload.standard = {
            [params.field]: params.value,
          } as Partial<AssessorStandard>;
          break;
        default:
          return;
      }

      const isUpdatable =
        payload?.standard &&
        Object.keys(payload.standard).filter(
          (k) => payload?.standard?.[k as keyof AssessorStandard] !== undefined
        ).length > 0;

      if (isUpdatable) {
        updateAssessorStandard(payload as UpdateAssessorStandardArg);
        return;
      }
      refetch();
    },
    [updateAssessorStandard, refetch]
  );

  const columns = useStandardColumns({
    standardList: availableStandardList?.standards,
    onCellEditCommit,
  });

  if (!id) {
    return <SectionNotAvailable />;
  }

  return (
    <FormSectionContent>
      <FormSectionContentItem>
        <Controller
          control={control}
          name="selectedStandard"
          rules={{
            validate: (value) => {
              if (!value) {
                return "This field is required";
              }
              return true;
            },
          }}
          render={({ field }) => {
            return (
              <NSSelect
                {...field}
                label="Standard approved to assess"
                error={!!errors.selectedStandard}
                helperText={errors.selectedStandard?.message}
                value={field.value}
              >
                <MenuItem value={0}>
                  <em>None</em>
                </MenuItem>
                {availableStandardList?.standards?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </NSSelect>
            );
          }}
        />
      </FormSectionContentItem>
      <FormSectionContentItem>
        <Controller
          control={control}
          name="meetingDate"
          rules={{
            validate: (value) => {
              if (!value) {
                return "This field is required";
              }
              if (value && !isValidDate(value)) {
                return "Invalid date";
              }
              return true;
            },
          }}
          render={({ field }) => {
            return (
              <NSDatePicker
                label="Meeting date"
                error={!!errors.meetingDate}
                helperText={errors.meetingDate?.message}
                {...field}
                value={field.value || ""}
              />
            );
          }}
        />
      </FormSectionContentItem>
      <FormSectionContentItem>
        <NSButton
          color="primary"
          className="mt-3.5 mb-2"
          onClick={addStandard}
          loading={isCreating}
          variant="outlined"
        >
          Add standard
        </NSButton>
      </FormSectionContentItem>
      <FormSectionContentItem slot={3}>
        <FormTitle>Standard list</FormTitle>
        <Typography variant="caption">
          (*Double click on a cell to edit)
        </Typography>
      </FormSectionContentItem>
      <FormSectionContentItem slot={3}>
        <NSDataGrid
          loading={isFetching || isUpdating}
          rows={assessorStandardsResponse?.standards || []}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          cellFocusable
          onCellEditCommit={onCellEditCommit}
          stickyHeader={false}
          paperContainerProps={{
            variant: "outlined",
            className: "mt-2 mb-2",
          }}
        />
      </FormSectionContentItem>
    </FormSectionContent>
  );
};

export default memo(AssessorStandardsManagement);
