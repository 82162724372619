/* eslint-disable react/display-name */
import { queryObjectToString, queryStringToObject } from "@app/helpers";
import { Assessor } from "@app/models";
import NSBreadcrumbs from "@components/NSBreadcrumbs";
import NSDataGrid from "@components/NSDataGrid";
import NSSelect from "@components/NSSelect";
import NSTextField from "@components/NSTextField";
import {
  GetAssessorListArg,
  useGetAssessorListQuery,
} from "@features/assessor/assessorAPI";
import { AssessorStatusList } from "@features/assessor/assessorConstant";
import { Grid, IconButton, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { differenceInCalendarDays, format, startOfToday } from "date-fns";
import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import NSFab from "@components/NSFab";
import AddIcon from "@material-ui/icons/Add";
import StatusTag from "./components/StatusTag";
import { PageSizeOptions } from "@app/constants";

const getRemainingDaysUntilExpiration = (expiryDate: Date) => {
  const today = startOfToday();
  const remaining = differenceInCalendarDays(expiryDate, today);
  return remaining;
};

const getDbsExpDateDisplayValue = (
  formattedDate: string,
  remainingDays: number
) => {
  if (remainingDays > 90 || remainingDays < 0) {
    return formattedDate;
  }
  const unit = remainingDays > 1 ? "days" : "day";
  return `${formattedDate} (${remainingDays} ${unit})`;
};

const getDbsColorClass = (remainingDays: number) => {
  if (remainingDays < 0) {
    return "color-error";
  }
  if (remainingDays <= 7) {
    return "color-warning-level-2";
  }
  if (remainingDays <= 30) {
    return "color-warning-level-1";
  }
  return "";
};

// const getCompletionColorClass = (percentage: number) => {
//   if (percentage < 50) {
//     return "color-error";
//   }
//   if (percentage < 100) {
//     return "color-warning-level-1";
//   }
//   return "color-success";
// };

const makeRenderCell = (field: string | string[]) => {
  const renderCell = (params: GridCellParams) => {
    const label = Array.isArray(field)
      ? field
          .reduce((labels: string[], value: string) => {
            return [...labels, params.row[value]];
          }, [])
          .join(" ")
      : params.row[field];

    return (
      <Link to={`/assessor/info/${params.id}`} className="color-inherit">
        {label}
      </Link>
    );
  };
  return renderCell;
};

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    width: 80,
    filterable: false,
    disableColumnMenu: true,
    renderCell: makeRenderCell("id"),
  },
  {
    field: "fullName",
    headerName: "Full name",
    sortable: true,
    width: 180,
    valueGetter: (params) => `${params.row.firstname} ${params.row.surname}`,
    renderCell: makeRenderCell(["firstname", "surname"]),
  },
  { field: "email", headerName: "Email", width: 320 },
  { field: "telPrimary", headerName: "Phone No.", width: 160 },
  {
    field: "dbsExpiredOn",
    headerName: "DBS Exp. Date",
    width: 170,
    renderCell: (params: GridCellParams) => {
      if (!params.value?.toString()) {
        return "";
      }
      const dateObj = new Date(params.value.toString());
      const formattedDate = format(dateObj, "dd-MM-yyyy");
      const remainingDays = getRemainingDaysUntilExpiration(dateObj);
      const displayValue = getDbsExpDateDisplayValue(
        formattedDate,
        remainingDays
      );

      return (
        <span className={getDbsColorClass(remainingDays)}>{displayValue}</span>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 110,
    type: "singleSelect",
    valueOptions: AssessorStatusList,
    valueGetter: (params: GridValueGetterParams) =>
      AssessorStatusList.find((cs) => cs.value === params.row.status)?.label,
    renderCell: (params: GridCellParams) => {
      return (
        <StatusTag status={params.value?.toString() || ""} variant="text" />
      );
    },
  },
  // {
  //   field: "completionPercentage",
  //   headerName: "Completion",
  //   width: 140,
  //   renderCell: (params: GridCellParams) => {
  //     return (
  //       <span className={getCompletionColorClass(toInteger(params.value))}>
  //         {params.value}%
  //       </span>
  //     );
  //   },
  // },
  {
    field: "actions",
    headerName: " ",
    width: 24,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    align: "right",
    renderCell: (params: GridCellParams) => (
      <Link to={`/assessor/info/${params.id}`} target="_blank">
        <IconButton size="small">
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      </Link>
    ),
  },
];

type FilterType = {
  searchTerm: string;
  dbsExpiredIn: number;
};

/**
 * Hook the filter object base on location query params
 */
const useFilter = () => {
  const location = useLocation();
  const filter = useMemo<FilterType>(() => {
    const queryObj = queryStringToObject(location.search);

    return {
      searchTerm: queryObj.searchTerm || "",
      dbsExpiredIn: +queryObj.dbsExpiredIn || 0,
    };
  }, [location]);
  return filter;
};

/**
 * Hook the assessors which match the search term
 */
const useSearchedList = (
  list: Assessor[],
  searchTerm: FilterType["searchTerm"]
) => {
  const searchedList = useMemo(() => {
    if (!searchTerm) {
      return list;
    }
    const term = searchTerm.toLowerCase();
    return list.filter((item) => {
      const fullName = [item.firstname, item.surname].join(" ");
      return (
        item.firstname.toLowerCase().includes(term) ||
        item.surname.toLowerCase().includes(term) ||
        fullName.toLowerCase().includes(term) ||
        item.email.toLowerCase().includes(term) ||
        item.telPrimary.includes(term)
      );
    });
  }, [searchTerm, list]);
  return searchedList;
};

const AssessorList = () => {
  const location = useLocation();
  const history = useHistory();
  const filter = useFilter();

  const listQueryArg = useMemo<GetAssessorListArg>(
    () => (filter.dbsExpiredIn ? { dbsExpiredIn: filter.dbsExpiredIn } : null),
    [filter.dbsExpiredIn]
  );

  const {
    data: queryResponse,
    isLoading,
    isFetching,
  } = useGetAssessorListQuery(listQueryArg, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const [pageSize, setPageSize] = useState(PageSizeOptions[0]);

  const list = useMemo(() => queryResponse?.assessors || [], [queryResponse]);

  const searchedList = useSearchedList(list, filter.searchTerm);

  const searchDebounce = useRef<NodeJS.Timeout | null>(null);

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (searchDebounce.current) {
        clearTimeout(searchDebounce.current);
      }
      searchDebounce.current = setTimeout(() => {
        const queryObj = queryStringToObject(location.search);
        if (e.target.value) {
          queryObj.searchTerm = e.target.value;
        } else {
          delete queryObj.searchTerm;
        }
        history.push(`/assessor/list${queryObjectToString(queryObj)}`);
      }, 500);
    },
    [history, location]
  );

  const onExpiredInSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const queryObj = queryStringToObject(location.search);
      if (e.target.value) {
        queryObj.dbsExpiredIn = e.target.value;
      } else {
        delete queryObj.dbsExpiredIn;
      }
      history.push(`/assessor/list${queryObjectToString(queryObj)}`);
    },
    [history, location]
  );

  const goToCreateAssessor = useCallback(() => {
    history.push("/assessor/new");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [history]);

  return (
    <>
      <NSBreadcrumbs isLoading={!isLoading && isFetching} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={7} md={8}>
          <NSTextField
            margin="none"
            label="Search"
            defaultValue={filter.searchTerm}
            onChange={onSearch}
            disabled={isFetching}
            placeholder="Type something here to search"
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <NSSelect
            margin="none"
            label="DBS expired in"
            onChange={onExpiredInSelect}
            value={filter.dbsExpiredIn}
            disabled={isFetching}
          >
            <MenuItem value={0}>
              <em>Not selected</em>
            </MenuItem>
            {[7, 30, 90, -1].map((item) => (
              <MenuItem key={item} value={item}>
                {item > 0 ? `${item} days` : "Already expired"}
              </MenuItem>
            ))}
          </NSSelect>
        </Grid>
        <Grid item xs={12}>
          <NSDataGrid
            loading={isLoading}
            rows={searchedList}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={PageSizeOptions}
            onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          />
        </Grid>
      </Grid>
      <NSFab onClick={goToCreateAssessor}>
        <AddIcon />
      </NSFab>
    </>
  );
};

export default AssessorList;
