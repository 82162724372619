import {
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import React, { useMemo } from "react";

export type NSTextFieldProps = {
  maxLength?: number;
} & TextFieldProps;

const NSTextField = ({
  maxLength,
  minRows,
  maxRows,
  ...props
}: NSTextFieldProps) => {
  const inputProps = useMemo(() => {
    const result: Record<string, any> = {
      maxLength,
      style: {
        overflow: "auto",
      },
      ...props.inputProps,
    };
    if (minRows) {
      result.style.minHeight = Number(minRows) * 12;
    }
    if (maxRows) {
      result.style.maxHeight = Number(maxRows) * 12;
    }
    return result as InputBaseComponentProps;
  }, [maxLength, props.inputProps, minRows, maxRows]);

  return (
    <TextField
      autoComplete={props.autoComplete ?? "new-password"}
      fullWidth
      variant="outlined"
      size="small"
      margin="normal"
      multiline={!!minRows || !!maxRows}
      {...props}
      helperText={props.helperText}
      InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
      InputProps={{
        ...props.InputProps,
        autoComplete: props.autoComplete ?? "new-password",
      }}
      inputProps={inputProps}
    />
  );
};

export default React.forwardRef((props: NSTextFieldProps, ref) => (
  <NSTextField {...props} inputRef={ref} />
));
