import { useAppDispatch, useNotification } from "@app/hooks";
import { useCreateQualificationLevelMutation } from "@features/general/generalAPI";
import { getQualificationLevelListSilently } from "@features/general/generalSlice";
import { useEffect } from "react";

const useCreateQualificationLevel = ({ callback }: { callback: () => any }) => {
  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();
  const [
    createQualificationLevel,
    { isLoading: isCreating, isSuccess, isError },
  ] = useCreateQualificationLevelMutation();

  useEffect(() => {
    if (isSuccess) {
      showNotification("Qualification level is created");
      dispatch(getQualificationLevelListSilently());
      callback();
      return;
    }
    if (isError) {
      showNotification("Cannot create this qualification level.", "error");
    }
  }, [isSuccess, isError, showNotification, callback, dispatch]);

  return {
    createQualificationLevel,
    isCreating,
  };
};

export default useCreateQualificationLevel;
