import {
  FormDataFieldType,
  formDataFieldTypeValues,
  FormSectionType,
} from "@components/FormBuilder";
import { DevelopmentStatusList } from "@features/assessor/assessorConstant";
import { ReactNode, useMemo } from "react";

export interface UseConflictFormContentDefinitionConfig {
  submit: () => any;
  isSubmitting: boolean;
  developmentListComponent: ReactNode;
}

const developmentFormFields: FormSectionType["fields"] = [
  {
    type: "text",
    name: "reason",
    label: "Conflict",
    minRows: 6,
    maxRows: 10,
    maxLength: 200,
    rules: { required: true },
    slot: 2,
  },
  {
    type: "blank",
  },
  {
    type: "text",
    name: "location",
    label: "Location",
    rules: { required: true },
    slot: 2,
  },
  {
    type: "checkbox",
    name: "declarationCopyOnFile",
    label: "Copy on file (Mocking)",
  },
  {
    type: "date",
    name: "boardSignOff",
    label: "Board sign off",
    rules: { required: true },
  },
  {
    type: "date",
    name: "signedOn",
    label: "Signed on",
  },
];

export const developmentFormDataFields = developmentFormFields.filter((f) => {
  return formDataFieldTypeValues.includes(f.type);
}) as FormDataFieldType[];

const useDevelopmentFormDefinition = ({
  submit,
  isSubmitting,
  developmentListComponent,
}: UseConflictFormContentDefinitionConfig) => {
  const def = useMemo<FormSectionType["fields"]>(
    () => [
      {
        type: "date",
        name: "reviewDate",
        label: "Review date",
        rules: { required: true },
        slot: 1,
      },
      {
        type: "select",
        name: "status",
        label: "Status",
        rules: { required: true },
        slot: 1,
        selectOptions: DevelopmentStatusList.map((s) => ({
          id: s.value,
          name: s.label,
        })),
      },
      { type: "blank" },
      {
        type: "date",
        name: "signedOff",
        label: "Signed off",
        rules: { required: true },
        slot: 1,
      },
      {
        type: "text",
        name: "notes",
        label: "Note",
        minRows: 6,
        maxRows: 10,
        slot: 1,
      },
      {
        type: "button",
        label: "Add",
        onClick: submit,
        isLoading: isSubmitting,
      },
      {
        type: "custom",
        component: developmentListComponent,
      },
    ],
    [developmentListComponent, isSubmitting, submit]
  );

  return def;
};

export default useDevelopmentFormDefinition;
