import { Chip, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";

export interface StatusTagProps {
  status: string;
  variant?: "chip" | "text";
  className?: string;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(11),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    active: {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.success.main,
    },
    inactive: {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.grey[800]
          : theme.palette.grey[500],
      color:
        theme.palette.type === "dark"
          ? theme.palette.grey[500]
          : theme.palette.grey[800],
    },
    nar: {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.info.main,
    },
    label: {
      lineHeight: "normal",
    },
    text: {
      backgroundColor: "transparent",
      paddingLeft: 0,
      paddingRight: 0,
    },
    textLabel: {
      fontSize: theme.typography.pxToRem(12),
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
  { name: "StatusTag" }
);

const StatusTag = ({
  status = "",
  variant = "chip",
  className,
}: StatusTagProps) => {
  const classes = useStyles();

  const statusClassName = classes[status.toLowerCase() as keyof typeof classes];

  if (!status) {
    return null;
  }

  return (
    <Chip
      label={status.toUpperCase()}
      size="small"
      classes={{
        label: clsx(classes.label, {
          [classes.textLabel]: variant === "text",
        }),
      }}
      className={clsx(classes.root, statusClassName, className, {
        [classes.text]: variant === "text",
      })}
    />
  );
};

export default memo(StatusTag);
