import { Nullable } from "@app/models";
import { createSlice } from "@reduxjs/toolkit";

export type Summary = Array<{
  key: string;
  value: string | number;
}>;

export interface DashboardState {
  totalUsers: Nullable<number>;
  totalAssessors: Nullable<number>;
  summary: Nullable<Summary>;
}

export const initialState: DashboardState = {
  totalUsers: null,
  totalAssessors: null,
  summary: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateTotalUsers: (state, action) => {
      state.totalUsers = action.payload;
    },
    updateTotalAssessors: (state, action) => {
      state.totalAssessors = action.payload;
    },
    updateSummary: (state, action) => {
      state.summary = action.payload;
    },
  },
});

export const { updateTotalUsers, updateTotalAssessors, updateSummary } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
