import { EmailPattern } from "@app/regex-patterns";
import {
  FormDataFieldType,
  FormFieldType,
  validateDataField,
  FormBuilderSections,
} from "@components/FormBuilder";

/**
 * Form definitions
 */

const primaryFields: FormFieldType[] = [
  {
    name: "id",
    type: "hidden",
  },
  {
    name: "name",
    label: "Name",
    type: "text",
    rules: {
      required: true,
    },
  },
  {
    name: "employer",
    label: "Employer",
    type: "text",
  },
  {
    name: "standard",
    label: "Standard",
    type: "text",
  },
  {
    name: "apprentice",
    label: "Apprentice",
    type: "text",
  },
  {
    name: "jobTitle",
    label: "Job title",
    type: "text",
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    rules: {
      pattern: {
        value: EmailPattern,
        message: "Please enter a valid email",
      },
    },
  },
  {
    name: "iaAssigned",
    label: "IA assigned",
    type: "text",
  },
  {
    name: "dateOfEpa",
    label: "Date of EPA",
    type: "date",
  },
  {
    name: "cv",
    label: "CV",
    type: "checkbox",
  },
  {
    name: "cpdOrProfessionalRegistrationEvidence",
    label: "CPD or professional registration evidence",
    type: "checkbox",
  },
  {
    name: "additionalEvidence",
    label: "Additional evidence",
    type: "text",
  },
  {
    name: "qualification",
    label: "Qualification",
    type: "text",
  },
  {
    name: "inductionConfirmationSigned",
    label: "Declaration, CoI & induction confirmation signed",
    type: "checkbox",
  },
  {
    name: "dateOfInduction",
    label: "Date of Induction",
    type: "date",
  },
  {
    name: "anyCoiDisclosed",
    label: "Any CoI disclosed",
    type: "checkbox",
  },
  {
    name: "details",
    label: "Details",
    type: "text",
  },
  {
    name: "actions",
    label: "Actions",
    type: "text",
  },
  {
    name: "agreedBy",
    label: "Agreed by",
    type: "text",
  },
];

const staticFormSections: FormBuilderSections[] = [
  [
    {
      label: "Primary Information",
      type: "default",
      fields: primaryFields,
    },
  ],
];

const staticFormFields = [...primaryFields].filter((f) =>
  validateDataField(f)
) as FormDataFieldType[];

/**
 * Hooks to get form definition objects
 */
export const useERAFormDefinition = () => {
  // const qualificationList = useAppSelector((state) =>
  //   state.general.qualifications?.map((qual) => ({
  //     ...qual,
  //     disabled: Boolean(qual.deletedAt),
  //   }))
  // );
  // const qualificationLevelList = useAppSelector((state) =>
  //   state.general.qualificationLevels?.map((lvl) => ({
  //     ...lvl,
  //     disabled: Boolean(lvl.deletedAt),
  //   }))
  // );

  return {
    formFields: staticFormFields,
    formSections: staticFormSections,
  };
};

export default useERAFormDefinition;
