import { PageSizeOptions } from "@app/constants";
import NSBreadcrumbs from "@components/NSBreadcrumbs";
import NSDataGrid from "@components/NSDataGrid";
import NSFab from "@components/NSFab";
import { useGetERAListQuery } from "@features/era/eraAPI";
import { Grid } from "@material-ui/core";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

const makeRenderCell = (field: string | string[]) => {
  const renderCell = (params: GridCellParams) => {
    const label = Array.isArray(field)
      ? field
          .reduce((labels: string[], value: string) => {
            return [...labels, params.row[value]];
          }, [])
          .join(" ")
      : params.row[field];

    return (
      <Link to={`/era/info/${params.id}`} className="color-inherit">
        {label}
      </Link>
    );
  };
  return renderCell;
};

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    width: 80,
    filterable: false,
    disableColumnMenu: true,
    renderCell: makeRenderCell("id"),
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    width: 180,
    renderCell: makeRenderCell("name"),
  },
  { field: "email", headerName: "Email", width: 320 },
  // {
  //   field: "dbsExpiredOn",
  //   headerName: "DBS Exp. Date",
  //   width: 170,
  //   renderCell: (params: GridCellParams) => {
  //     if (!params.value?.toString()) {
  //       return "";
  //     }
  //     const dateObj = new Date(params.value.toString());
  //     const formattedDate = format(dateObj, "dd-MM-yyyy");
  //     const remainingDays = getRemainingDaysUntilExpiration(dateObj);
  //     const displayValue = getDbsExpDateDisplayValue(
  //       formattedDate,
  //       remainingDays
  //     );

  //     return (
  //       <span className={getDbsColorClass(remainingDays)}>{displayValue}</span>
  //     );
  //   },
  // },
  // {
  //   field: "actions",
  //   headerName: " ",
  //   width: 24,
  //   sortable: false,
  //   filterable: false,
  //   disableColumnMenu: true,
  //   align: "right",
  //   renderCell: (params: GridCellParams) => (
  //     <Link to={`/assessor/info/${params.id}`} target="_blank">
  //       <IconButton size="small">
  //         <OpenInNewIcon fontSize="small" />
  //       </IconButton>
  //     </Link>
  //   ),
  // },
];

const ERAList = () => {
  const history = useHistory();
  const { data, isLoading, isFetching } = useGetERAListQuery();
  const [pageSize, setPageSize] = useState(PageSizeOptions[0]);

  const goToCreateERA = useCallback(() => {
    history.push("/era/new");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [history]);

  return (
    <>
      <NSBreadcrumbs isLoading={!isLoading && isFetching} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <NSDataGrid
            loading={isLoading}
            rows={data?.eras || []}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={PageSizeOptions}
            onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          />
        </Grid>
      </Grid>
      <NSFab onClick={goToCreateERA}>
        <AddIcon />
      </NSFab>
    </>
  );
};

export default ERAList;
