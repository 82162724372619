import { memo } from "react";
import { ReactElement } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NewUserForm from "./NewUserForm";
import UserInfo from "./UserInfo";
import UserList from "./UserList";

const Admin = (): ReactElement => {
  return (
    <Switch>
      <Route exact path="/admin/user/list" component={UserList} />
      <Route exact path="/admin/user/info/:id" component={UserInfo} />
      <Route exact path="/admin/user/new" component={NewUserForm} />
      <Route>
        <Redirect to="/admin/user/list" />
      </Route>
    </Switch>
  );
};

export default memo(Admin);
