import { Box, makeStyles, Typography } from "@material-ui/core";
import { memo } from "react";

type SummaryTitleProps = {
  title: string;
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
      "&:before": {
        content: '" "',
        position: "absolute",
        width: 4,
        height: "100%",
        backgroundColor: theme.palette.info.main,
        borderRadius: 2,
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 600,
      position: "relative",
      paddingLeft: theme.spacing(1.5),
    },
  }),
  {
    classNamePrefix: "SummaryTitle",
  }
);

const SummaryTitle = ({ title }: SummaryTitleProps) => {
  const classes = useStyles();
  return (
    <Box mt={3} mb={2} className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
    </Box>
  );
};

export default memo(SummaryTitle);
