import { getDataPatch, prepareData } from "@app/helpers";
import { ERA } from "@app/models";
import { FormDataFieldType, FormDataType } from "@components/FormBuilder";
import { CreateERAArg, UpdateERAArg } from "@features/era/eraAPI";
import { useCallback } from "react";

const useERAFormDataPreparation = (formFields: FormDataFieldType[]) => {
  const prepareDataToCreate = useCallback(
    (formData: FormDataType) => {
      const preparedData = prepareData<CreateERAArg>(formData, formFields, {
        withoutId: true,
      });
      return preparedData;
    },
    [formFields]
  );

  const prepareDataToUpdate = useCallback(
    (
      formData: FormDataType & { id: string | number },
      { originalData }: { originalData: ERA }
    ) => {
      const preparedData = prepareData<ERA>(formData, formFields);
      const dataPatch = getDataPatch(originalData, preparedData);

      return { ...dataPatch, id: formData.id } as UpdateERAArg;
    },
    [formFields]
  );

  return {
    prepareDataToCreate,
    prepareDataToUpdate,
  };
};

export default useERAFormDataPreparation;
