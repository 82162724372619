import { Box, Fab, FabProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";

const useStyles = makeStyles(
  () => ({
    root: {
      position: "fixed",
      bottom: 16,
      right: 16,
      zIndex: 50,
    },
  }),
  {
    classNamePrefix: "NSFab",
  }
);

export type NSFabProps = Omit<FabProps, "size">;

/**
 * Put `NSFab` at the end of your component
 */
const NSFab = ({ children, className, ...props }: NSFabProps) => {
  const classes = useStyles();
  return (
    <>
      <Box height={48} />
      <Fab
        color="primary"
        {...props}
        size="medium"
        className={clsx(classes.root, className)}
      >
        {children}
      </Fab>
    </>
  );
};

export default memo(NSFab);
