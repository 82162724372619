import { useIsAdmin } from "@app/hooks";
import Logo from "@components/Logo";
import { Divider, Drawer, List, Theme } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PeopleIcon from "@material-ui/icons/People";
import SchoolIcon from "@material-ui/icons/School";
import LayersIcon from "@material-ui/icons/Layers";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import { createStyles, makeStyles } from "@material-ui/styles";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import SideBarListItem, { SideBarListItemProps } from "./SideBarListItem";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      logo: {
        maxWidth: "60%",
        width: "100%",
      },
      drawer: {
        width: theme.sideBar.width,
        flexShrink: 0,
      },
      drawerPaper: {
        width: theme.sideBar.width,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[8],
        backgroundColor:
          theme.palette.type === "light"
            ? theme.palette.primary.main
            : theme.palette.background.default,
      },
      sideBarHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(0, 1),
        minHeight: "fit-content",
        marginTop: theme.spacing(1),
      },
      logoDivider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
      },
      sideBarList: {
        paddingTop: 0,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
    }),
  {
    classNamePrefix: "SideBar",
  }
);

interface SideBarProps {
  open: boolean;
}

const SideBar = ({ open }: SideBarProps) => {
  const classes = useStyles();

  const isAdmin = useIsAdmin();

  const items = useMemo<Array<SideBarListItemProps>>(
    () => [
      {
        text: "Dashboard",
        icon: DashboardIcon,
        path: "/home",
      },
      {
        text: "New Assessor",
        path: "/assessor/new",
        target: "_blank",
        icon: PersonAddIcon,
      },
      {
        text: "Assessor List",
        path: "/assessor/list",
        icon: PeopleIcon,
      },
      {
        text: "Conflict of Interests",
        path: "/assessor-conflict/list",
        icon: ThumbsUpDownIcon,
      },
      {
        text: "Qualification",
        path: "/qualification/list",
        icon: SchoolIcon,
      },
      {
        text: "Qualification Level",
        path: "/qualification-level/list",
        icon: LayersIcon,
      },
      {
        text: "Employer Registered Assessors",
        path: "/era/list",
        icon: PeopleIcon,
      },
      {
        text: "Administrator",
        icon: VerifiedUserIcon,
        disabled: !isAdmin,
        nestedItems: [
          {
            text: "New User",
            path: "/admin/user/new",
          },
          {
            text: "User List",
            path: "/admin/user/list",
          },
        ],
      },
    ],
    [isAdmin]
  );

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.sideBarHeader}>
        <Link to="/" className="text-center">
          <Logo
            className={classes.logo}
            variant="dark-content"
            autoChangeWithThemeMode={false}
          />
        </Link>
      </div>
      <Divider className={classes.logoDivider} />
      <List className={classes.sideBarList}>
        {items.map((item) => (
          <SideBarListItem key={item.text} {...item} />
        ))}
      </List>
    </Drawer>
  );
};

export default memo(SideBar);
