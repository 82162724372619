import { formatDateTimeToDisplay } from "@app/helpers";
import { DevelopmentStatusList } from "@features/assessor/assessorConstant";
import { GridColumns } from "@mui/x-data-grid";
import { useMemo } from "react";

const useDevelopmentColumns = () => {
  const columns: GridColumns = useMemo(() => {
    return [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        width: 70,
        filterable: false,
        disableColumnMenu: true,
        cellClassName: "outline-none",
        hide: true,
      },
      {
        field: "reviewDate",
        headerName: "Review Date",
        type: "dateTime",
        width: 200,
        editable: true,
        cellClassName: "outline-none",
        valueFormatter: (params) =>
          formatDateTimeToDisplay(params.row.reviewDate, true),
      },
      {
        field: "status",
        headerName: "Status",
        type: "singleSelect",
        width: 200,
        editable: true,
        cellClassName: "outline-none",
        valueOptions: DevelopmentStatusList,
        valueFormatter: (params) =>
          DevelopmentStatusList.find((s) => s.value === params.row.status)
            ?.label || "",
      },
      {
        field: "signedOff",
        headerName: "Signed off",
        type: "dateTime",
        width: 200,
        editable: true,
        cellClassName: "outline-none",
        valueFormatter: (params) =>
          formatDateTimeToDisplay(params.row.signedOff, true),
      },
      {
        field: "notes",
        headerName: "Note",
        width: 500,
        editable: true,
        cellClassName: "cursor-pointer",
      },
      // {
      //   field: "actions",
      //   headerName: " ",
      //   width: 90,
      //   sortable: false,
      //   filterable: false,
      //   disableColumnMenu: true,
      //   align: "right",
      //   cellClassName: "outline-none",
      //   renderCell: ConflictListActionCell,
      // },
    ];
  }, []);

  return columns;
};

export default useDevelopmentColumns;
