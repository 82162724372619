import {
  BaseResponse,
  Qualification,
  QualificationLevel,
  Role,
} from "@app/models";
import request from "@app/api";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "@app/rtk-query";

export type GeneralResponse = {
  roles: Array<Role>;
  qualifications: Array<Qualification>;
  qualificationLevels: Array<QualificationLevel>;
};

type GetRoleListResponse = Pick<GeneralResponse, "roles">;

export const sendGetRoleListRequest =
  async (): Promise<GetRoleListResponse | null> => {
    try {
      const response = await request.get("/general/roles");
      if (response.status === 200) {
        return response.data;
      }
      throw new Error();
    } catch (err) {
      return null;
    }
  };

export type GetQualificationListResponse = Pick<
  GeneralResponse,
  "qualifications"
>;

export const sendGetQualificationListRequest =
  async (): Promise<GetQualificationListResponse | null> => {
    try {
      const response = await request.get("/qualifications");
      if (response.status === 200) {
        return response.data;
      }
      throw new Error();
    } catch (err) {
      return null;
    }
  };

export type GetQualificationLevelListResponse = Pick<
  GeneralResponse,
  "qualificationLevels"
>;

export const sendGetQualificationLevelListRequest =
  async (): Promise<GetQualificationLevelListResponse | null> => {
    try {
      const response = await request.get("/qualification-levels");
      if (response.status === 200) {
        return response.data;
      }
      throw new Error();
    } catch (err) {
      return null;
    }
  };

/**
 * Assessor RTK Query definitions
 */

export type QualificationResponse = {
  qualification: Qualification;
} & BaseResponse;

export type QualificationLevelResponse = {
  qualificationLevel: QualificationLevel;
} & BaseResponse;

const generalQuery = createApi({
  reducerPath: "generalQuery",
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    ///////////////////
    // QUALIFICATION //
    ///////////////////
    getQualification: build.query<QualificationResponse, number | string>({
      query: (id) => ({
        method: "get",
        url: `/qualifications/${id}`,
      }),
    }),
    createQualification: build.mutation<QualificationResponse, Qualification>({
      query: (data) => ({
        method: "post",
        url: `/qualifications`,
        data: data,
      }),
    }),
    updateQualification: build.mutation<QualificationResponse, Qualification>({
      query: ({ id, ...patchData }) => ({
        method: "put",
        url: `/qualifications/${id}`,
        data: patchData,
      }),
    }),
    deleteQualification: build.mutation<BaseResponse, number | string>({
      query: (id) => ({
        method: "delete",
        url: `/qualifications/${id}`,
      }),
    }),
    restoreQualification: build.mutation<BaseResponse, number | string>({
      query: (id) => ({
        method: "delete",
        url: `/qualifications/${id}/restoration`,
      }),
    }),
    /////////////////////////
    // QUALIFICATION LEVEL //
    /////////////////////////
    getQualificationLevel: build.query<
      QualificationLevelResponse,
      number | string
    >({
      query: (id) => ({
        method: "get",
        url: `/qualification-levels/${id}`,
      }),
    }),
    createQualificationLevel: build.mutation<
      QualificationLevelResponse,
      QualificationLevel
    >({
      query: (data) => ({
        method: "post",
        url: `/qualification-levels`,
        data: data,
      }),
    }),
    updateQualificationLevel: build.mutation<
      QualificationLevelResponse,
      QualificationLevel
    >({
      query: ({ id, ...patchData }) => ({
        method: "put",
        url: `/qualification-levels/${id}`,
        data: patchData,
      }),
    }),
    deleteQualificationLevel: build.mutation<BaseResponse, number | string>({
      query: (id) => ({
        method: "delete",
        url: `/qualification-levels/${id}`,
      }),
    }),
    restoreQualificationLevel: build.mutation<BaseResponse, number | string>({
      query: (id) => ({
        method: "delete",
        url: `/qualification-levels/${id}/restoration`,
      }),
    }),
  }),
});

export const {
  useGetQualificationQuery,
  useLazyGetQualificationQuery,
  useCreateQualificationMutation,
  useUpdateQualificationMutation,
  useDeleteQualificationMutation,
  useRestoreQualificationMutation,
  useGetQualificationLevelQuery,
  useLazyGetQualificationLevelQuery,
  useCreateQualificationLevelMutation,
  useUpdateQualificationLevelMutation,
  useDeleteQualificationLevelMutation,
  useRestoreQualificationLevelMutation,
} = generalQuery;

export default generalQuery;
