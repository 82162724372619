import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus, Role } from "@app/models";
import {
  LoginResponse,
  sendLoginRequest,
  sendLogoutRequest,
  sendVerificationRequest,
} from "./authAPI";

export interface AuthState {
  id: number | null;
  email: string | null;
  name?: string | null;
  token?: string | null;
  role: Role | null;
  authenticated: boolean;
  verified: boolean;
  status: AsyncStatus;
  reloadApp?: boolean;
}

export const authInitialState: AuthState = {
  id: null,
  email: null,
  name: null,
  token: null,
  role: null,
  authenticated: false,
  verified: false,
  status: "idle",
  reloadApp: false,
};

interface LoginPayload {
  email: string;
  password: string;
}

export const login = createAsyncThunk<LoginResponse, LoginPayload>(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    const response = await sendLoginRequest({ email, password });
    if (!response) {
      return rejectWithValue(null);
    }
    return response;
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    const response = await sendLogoutRequest();
    if (!response) {
      return rejectWithValue(null);
    }
    return response;
  }
);

export const verify = createAsyncThunk<LoginResponse>(
  "auth/verify",
  async (_, { rejectWithValue }) => {
    const response = await sendVerificationRequest();
    if (!response) {
      return rejectWithValue(null);
    }
    return response;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    resetAuth: (state) => {
      state.id = null;
      state.email = null;
      state.name = null;
      state.token = null;
      state.role = null;
      state.verified = false;
      state.status = "idle";
      state.authenticated = false;
      state.reloadApp = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.status = "idle";
        state.id = action.payload.user.id;
        state.email = action.payload.user.email;
        state.name = action.payload.user.name;
        state.token = action.payload.token;
        state.role = action.payload.user.role;
        state.authenticated = true;
        state.verified = true;
      })
      .addCase(login.rejected, (state) => {
        state.status = "failed";
        state.id = null;
        state.email = null;
        state.name = null;
        state.token = null;
        state.role = null;
        state.authenticated = false;
        state.verified = false;
        state.reloadApp = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.status = "idle";
        state.id = null;
        state.email = null;
        state.name = null;
        state.token = null;
        state.role = null;
        state.authenticated = false;
        state.verified = false;
        state.reloadApp = true;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.status = "idle";
        state.id = action.payload.user.id;
        state.email = action.payload.user.email;
        state.name = action.payload.user.name;
        state.token = action.payload.token;
        state.role = action.payload.user.role;
        state.authenticated = true;
        state.verified = true;
      })
      .addMatcher(
        (action) =>
          action.type.startsWith("auth/") && action.type.endsWith("/pending"),
        (state) => {
          state.status = "pending";
        }
      )
      .addMatcher(
        (action) =>
          !action.type.includes("auth/login") &&
          action.type.startsWith("auth/") &&
          action.type.endsWith("/rejected"),
        (state) => {
          state.status = "failed";
          state.id = null;
          state.email = null;
          state.name = null;
          state.token = null;
          state.role = null;
          state.authenticated = false;
          state.verified = false;
          state.reloadApp = true;
        }
      );
  },
});

export const { resetAuth } = authSlice.actions;

export default authSlice.reducer;
