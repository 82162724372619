import { AssessorFormTabProvider } from "../contexts/AssessorFormTab";
import AssessorInfoContent from "./AssessorInfoContent";

const AssessorInfo = () => {
  return (
    <AssessorFormTabProvider>
      <AssessorInfoContent />
    </AssessorFormTabProvider>
  );
};

export default AssessorInfo;
