import { useAppDispatch, useAppSelector } from "@app/hooks";
import useLoadingIndicator from "@components/LoadingIndicator";
import { getGeneralItems } from "@features/general/generalSlice";
import { memo } from "react";
import { ReactNode, useEffect } from "react";
import useDashboard from "./hooks/useDashboard";

type InitializationProps = {
  children: ReactNode;
};

const Initialization = ({ children }: InitializationProps) => {
  const { show, hide } = useLoadingIndicator();
  const isDataFulfilled = useAppSelector((state) =>
    Boolean(
      state.general.roles &&
        state.general.qualifications &&
        state.general.qualificationLevels
    )
  );
  const generalStatus = useAppSelector((state) => state.general.status);
  const dispatch = useAppDispatch();
  useDashboard();

  useEffect(() => {
    show("dark");
    dispatch(getGeneralItems());
  }, [dispatch, show]);

  useEffect(() => {
    if (generalStatus === "idle" && isDataFulfilled) {
      hide();
    }
  }, [generalStatus, isDataFulfilled, hide]);

  if (generalStatus === "idle" && isDataFulfilled) {
    return <>{children}</>;
  }

  return null;
};

export default memo(Initialization);
