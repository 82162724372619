import { prepareData, removeDuplicates } from "@app/helpers";
import { useNotification } from "@app/hooks";
import { Reference } from "@app/models";
import buildForm from "@components/FormBuilder";
import NSButton from "@components/NSButton";
import {
  UpdateAssessorReferencesArg,
  useGetAssessorReferencesQuery,
  useUpdateAssessorReferencesMutation,
} from "@features/assessor/referenceAPI";
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useReferencesFormDefinition from "./hooks/useReferencesFormDefinition";

interface ReferencesFormData {
  primaryReference: Partial<Reference>;
  secondaryReference: Partial<Reference>;
}

const flattenReferences = (data: ReferencesFormData) => {
  const result: Record<string, any> = {};
  Object.keys(data).forEach((key) => {
    const dataKey = key as keyof ReferencesFormData;
    const propKeys = Object.keys(data[dataKey]);

    propKeys.forEach((propKey) => {
      result[`${dataKey}.${propKey}`] =
        data[dataKey][propKey as keyof Reference];
    });
  });
  return result;
};

const nestReferences = (data: Record<string, any>) => {
  const keys = Object.keys(data);
  const referenceNames = removeDuplicates(keys.map((k) => k.split(".")[0]));
  const result: Record<string, any> = {};
  referenceNames.forEach((refName) => {
    result[refName] = {};
    const propKeys = keys.filter((k) => k.includes(`${refName}.`));
    propKeys.forEach((propKey) => {
      const propKeyWithoutRefName = propKey.split(".")[1];
      result[refName][propKeyWithoutRefName] = data[propKey];
    });
  });

  return result;
};

interface AssessorReferencesDialogProps
  extends Omit<DialogProps, "fullWidth" | "maxWidth" | "onClose"> {
  assessorId: number | string;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    flex: 1,
  },
  dialogContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const AssessorReferencesDialog = ({
  assessorId,
  onClose,
  ...modalProps
}: AssessorReferencesDialogProps) => {
  const classes = useStyles();
  const { showNotification } = useNotification();
  const { data } = useGetAssessorReferencesQuery(assessorId);
  const [updateReferences, { isLoading: isUpdating, isSuccess, isError }] =
    useUpdateAssessorReferencesMutation();

  const { formSections, formFields } = useReferencesFormDefinition();
  const [primaryRef, setPrimaryRef] = useState<Partial<Reference> | null>(null);
  const [secondaryRef, setSecondaryRef] = useState<Partial<Reference> | null>(
    null
  );
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const setFormData = useCallback(
    (data: Record<string, any>) => {
      formFields.forEach((f) => {
        if (!f.name) {
          return;
        }
        setValue(f.name, data[f.name] ?? "");
      });
    },
    [setValue, formFields]
  );

  useEffect(() => {
    if (!primaryRef || !secondaryRef) {
      return;
    }
    const flattenData = flattenReferences({
      primaryReference: primaryRef,
      secondaryReference: secondaryRef,
    });
    setFormData(flattenData);
  }, [primaryRef, secondaryRef, setFormData]);

  useEffect(() => {
    if (!data?.references) {
      return;
    }
    setPrimaryRef(data.references.primaryReference);
    setSecondaryRef(data.references.secondaryReference);
  }, [data]);

  const submit = useCallback(
    (data) => {
      const preparedData = prepareData(flattenReferences(data), formFields);
      updateReferences({
        assessorId,
        data: nestReferences(
          preparedData
        ) as UpdateAssessorReferencesArg["data"],
      });
    },
    [updateReferences, assessorId, formFields]
  );

  useEffect(() => {
    if (isSuccess) {
      showNotification("References are updated");
    } else if (isError) {
      showNotification(
        "Something wrong! References cannot be updated",
        "error"
      );
    }
  }, [isSuccess, isError, showNotification]);

  const handleDialogClose = useCallback<Required<DialogProps>["onClose"]>(
    (_, reason) => {
      if (reason === "backdropClick") {
        return;
      }
      onClose();
    },
    [onClose]
  );

  return (
    <Dialog {...modalProps} fullWidth maxWidth="md" onClose={handleDialogClose}>
      <Box display="flex" alignItems="center" paddingRight={3}>
        <DialogTitle className={classes.dialogTitle}>
          Assessor References
        </DialogTitle>
        <NSButton
          color="primary"
          loading={isUpdating}
          onClick={handleSubmit(submit)}
        >
          Save
        </NSButton>
        <NSButton onClick={onClose} className="ml-3" disabled={isUpdating}>
          Cancel
        </NSButton>
      </Box>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        {buildForm({ control, errors, sections: formSections })}
      </DialogContent>
    </Dialog>
  );
};

export default memo(AssessorReferencesDialog);
