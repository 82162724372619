import { ThemeOptions } from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import createPalette from "@material-ui/core/styles/createPalette";
import { Overrides } from "@material-ui/core/styles/overrides";
import { Shadows } from "@material-ui/core/styles/shadows";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    sideBar: {
      width: React.CSSProperties["width"];
    };
  }

  interface ThemeOptions {
    sideBar?: {
      width?: React.CSSProperties["width"];
    };
  }
}

const darkPalette = createPalette({
  type: "dark",
  primary: {
    main: "#24C77B",
    dark: "#1C473B",
  },
  success: {
    main: "#24C77B",
    dark: "#1C473B",
  },
  info: {
    main: "#2194FF",
    dark: "#233F5A",
  },
  warning: {
    main: "#FE9738",
    dark: "#422B15",
  },
  background: {
    paper: "#242526",
    default: "#18191A",
  },
});

const darkOverrides: Overrides = {
  MuiFormLabel: {
    root: {
      "&.Mui-focused": {
        color: grey[100],
      },
      "&.Mui-error.Mui-focused": {
        color: red[500],
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: grey[400],
      },
      "&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: red[500],
      },
    },
  },
  MuiButton: {
    contained: {
      color: "white",
      backgroundColor: grey[800],
      "&:hover": {
        backgroundColor: grey.A400,
      },
    },
    containedPrimary: {
      color: darkPalette.primary.main,
      backgroundColor: grey[800],
      "&:hover": {
        backgroundColor: grey.A400,
      },
    },
  },
};

const lightPalette = createPalette({
  type: "light",
  primary: {
    main: "#1A2138",
  },
  success: {
    main: "#24C77B",
    dark: "#1C473B",
  },
  info: {
    main: "#2194FF",
    dark: "#233F5A",
  },
  warning: {
    main: "#FE9738",
    dark: "#422B15",
  },
});

const lightShadows: Shadows = [
  "none",
  "0px 2px 1px -1px rgba(0,0,0,0.06),0px 1px 1px 0px rgba(0,0,0,0.04),0px 1px 3px 0px rgba(0,0,0,0.04)",
  "0px 3px 1px -2px rgba(0,0,0,0.06),0px 2px 2px 0px rgba(0,0,0,0.04),0px 1px 5px 0px rgba(0,0,0,0.04)",
  "0px 3px 3px -2px rgba(0,0,0,0.06),0px 3px 4px 0px rgba(0,0,0,0.04),0px 1px 8px 0px rgba(0,0,0,0.04)",
  "0px 2px 4px -1px rgba(0,0,0,0.06),0px 4px 5px 0px rgba(0,0,0,0.04),0px 1px 10px 0px rgba(0,0,0,0.04)",
  "0px 3px 5px -1px rgba(0,0,0,0.06),0px 5px 8px 0px rgba(0,0,0,0.04),0px 1px 14px 0px rgba(0,0,0,0.04)",
  "0px 3px 5px -1px rgba(0,0,0,0.06),0px 6px 10px 0px rgba(0,0,0,0.04),0px 1px 18px 0px rgba(0,0,0,0.04)",
  "0px 4px 5px -2px rgba(0,0,0,0.06),0px 7px 10px 1px rgba(0,0,0,0.04),0px 2px 16px 1px rgba(0,0,0,0.04)",
  "0px 5px 5px -3px rgba(0,0,0,0.06),0px 8px 10px 1px rgba(0,0,0,0.04),0px 3px 14px 2px rgba(0,0,0,0.04)",
  "0px 5px 6px -3px rgba(0,0,0,0.06),0px 9px 12px 1px rgba(0,0,0,0.04),0px 3px 16px 2px rgba(0,0,0,0.04)",
  "0px 6px 6px -3px rgba(0,0,0,0.06),0px 10px 14px 1px rgba(0,0,0,0.04),0px 4px 18px 3px rgba(0,0,0,0.04)",
  "0px 6px 7px -4px rgba(0,0,0,0.06),0px 11px 15px 1px rgba(0,0,0,0.04),0px 4px 20px 3px rgba(0,0,0,0.04)",
  "0px 7px 8px -4px rgba(0,0,0,0.06),0px 12px 17px 2px rgba(0,0,0,0.04),0px 5px 22px 4px rgba(0,0,0,0.04)",
  "0px 7px 8px -4px rgba(0,0,0,0.06),0px 13px 19px 2px rgba(0,0,0,0.04),0px 5px 24px 4px rgba(0,0,0,0.04)",
  "0px 7px 9px -4px rgba(0,0,0,0.06),0px 14px 21px 2px rgba(0,0,0,0.04),0px 5px 26px 4px rgba(0,0,0,0.04)",
  "0px 8px 9px -5px rgba(0,0,0,0.06),0px 15px 22px 2px rgba(0,0,0,0.04),0px 6px 28px 5px rgba(0,0,0,0.04)",
  "0px 8px 10px -5px rgba(0,0,0,0.06),0px 16px 24px 2px rgba(0,0,0,0.04),0px 6px 30px 5px rgba(0,0,0,0.04)",
  "0px 8px 11px -5px rgba(0,0,0,0.06),0px 17px 26px 2px rgba(0,0,0,0.04),0px 6px 32px 5px rgba(0,0,0,0.04)",
  "0px 9px 11px -5px rgba(0,0,0,0.06),0px 18px 28px 2px rgba(0,0,0,0.04),0px 7px 34px 6px rgba(0,0,0,0.04)",
  "0px 9px 12px -6px rgba(0,0,0,0.06),0px 19px 29px 2px rgba(0,0,0,0.04),0px 7px 36px 6px rgba(0,0,0,0.04)",
  "0px 10px 13px -6px rgba(0,0,0,0.06),0px 20px 31px 3px rgba(0,0,0,0.04),0px 8px 38px 7px rgba(0,0,0,0.04)",
  "0px 10px 13px -6px rgba(0,0,0,0.06),0px 21px 33px 3px rgba(0,0,0,0.04),0px 8px 40px 7px rgba(0,0,0,0.04)",
  "0px 10px 14px -6px rgba(0,0,0,0.06),0px 22px 35px 3px rgba(0,0,0,0.04),0px 8px 42px 7px rgba(0,0,0,0.04)",
  "0px 11px 14px -7px rgba(0,0,0,0.06),0px 23px 36px 3px rgba(0,0,0,0.04),0px 9px 44px 8px rgba(0,0,0,0.04)",
  "0px 11px 15px -7px rgba(0,0,0,0.06),0px 24px 38px 3px rgba(0,0,0,0.04),0px 9px 46px 8px rgba(0,0,0,0.04)",
];

const baseTheme: ThemeOptions = {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiIconButton: {
      disableRipple: true,
    },
  },
  sideBar: {
    width: 260,
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 0,
      },
    },
    MuiButtonBase: {
      root: {
        "&:active": {
          opacity: 0.55,
        },
      },
    },
    MuiLink: {
      root: {
        "&:hover": {
          opacity: 0.7,
        },
        "&:active": {
          opacity: 0.55,
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: 16,
        borderRadius: 6,
        padding: "6px 12px",
        lineHeight: 1.7,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 6,
      },
      inputMarginDense: {
        paddingTop: 9,
        paddingBottom: 9,
      },
      multiline: {
        "&.MuiOutlinedInput-marginDense": {
          paddingTop: 9,
          paddingBottom: 9,
        },
      },
    },
    MuiFormHelperText: {
      marginDense: {
        marginTop: 2,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 12,
        marginBottom: 6,
      },
    },
    MuiDialogTitle: {
      root: {
        "& .MuiTypography-root": {
          fontWeight: 600,
        },
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
        // textTransform: "uppercase",
        fontSize: "0.925rem",
      },
    },
  },
};

export const lightTheme: ThemeOptions = {
  ...cloneDeep(baseTheme),
  palette: merge(baseTheme.palette, lightPalette),
  shadows: lightShadows,
};

export const darkTheme: ThemeOptions = {
  ...cloneDeep(baseTheme),
  palette: merge(baseTheme.palette, darkPalette),
  overrides: merge(baseTheme.overrides, darkOverrides),
};
