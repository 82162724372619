import { DataGrid, DataGridProps, GridOverlay } from "@mui/x-data-grid";
import { NSDataGridColumnMenu } from "./NSDataGridColumnMenu";
import { CircularProgress, Paper, PaperProps, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles(
  (theme: Theme) => ({
    dataGridContainer: {
      overflow: "hidden",
    },
    root: {
      "& .MuiDataGrid-columnsContainer": {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        top: theme.mixins.toolbar.minHeight,
        backgroundColor: theme.palette.background.paper,
        position: "initial",
      },
      "& .MuiDataGrid-windowContainer": {
        marginBottom: -39,
      },
    },
    stickyHeader: {
      "& .MuiDataGrid-columnsContainer": {
        position: "sticky",
        zIndex: 10,
      },
    },
    header: {
      // textTransform: "uppercase",
      fontSize: "0.925rem",
      fontWeight: 600,
    },
  }),
  { classNamePrefix: "NSDataGrid" }
);

const NSLoadingOverlay = () => (
  <GridOverlay style={{ zIndex: 10 }}>
    <CircularProgress size={24} />
  </GridOverlay>
);

export interface NSDataGrid extends DataGridProps {
  cellFocusable?: boolean;
  paperContainerProps?: PaperProps;
  stickyHeader?: boolean;
}

const NSDataGrid = ({
  cellFocusable,
  loading,
  rows,
  columns,
  pageSize,
  rowsPerPageOptions,
  onPageSizeChange,
  paperContainerProps,
  stickyHeader = true,
  ...restProps
}: NSDataGrid) => {
  const classes = useStyles();
  return (
    <Paper {...paperContainerProps}>
      <DataGrid
        autoHeight
        density="compact"
        hideFooterSelectedRowCount
        disableColumnSelector
        disableSelectionOnClick
        hideFooterRowCount
        components={{
          ColumnMenu: NSDataGridColumnMenu,
          LoadingOverlay: NSLoadingOverlay,
        }}
        classes={{
          root: "border-none",
          columnHeader: clsx("outline-none", classes.header),
          cell: cellFocusable ? "" : "outline-none",
        }}
        className={clsx(classes.root, {
          [classes.stickyHeader]: stickyHeader,
        })}
        loading={Boolean(loading)}
        rows={rows ? [...rows] : []}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageSizeChange={onPageSizeChange}
        {...restProps}
      />
    </Paper>
  );
};

export default NSDataGrid;
