import { useState, useCallback, ReactNode } from "react";
import ThemeModeContext, { ThemeModeType } from "./ThemeModeContext";

type ThemeModeProps = {
  children: ReactNode;
};

const ThemeModeProvider = ({ children }: ThemeModeProps) => {
  const [themeMode, setThemeMode] = useState<ThemeModeType>(() => {
    const storedMode = localStorage.getItem("mode");
    if (storedMode === "dark" || storedMode === "light") {
      return storedMode;
    }
    return "dark";
  });

  const switchTheme = useCallback((mode: ThemeModeType = "dark") => {
    localStorage.setItem("mode", mode);
    setThemeMode(mode);
  }, []);

  return (
    <ThemeModeContext.Provider value={{ mode: themeMode, switchTheme }}>
      {children}
    </ThemeModeContext.Provider>
  );
};

export default ThemeModeProvider;
