import { createContext } from "react";

export type AssessorFormTabContext = {
  tab: number;
  changeTab: (nextTab: number, hash?: string) => any;
};

const AssessorFormTabContext = createContext<AssessorFormTabContext>({
  tab: 0,
  changeTab: () => null,
});

export default AssessorFormTabContext;
