import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import { useCallback } from "react";
import { memo, useEffect } from "react";
import { ReactNode } from "react";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      formToolbar: {
        position: "sticky",
        top: theme.mixins.toolbar.minHeight,
        zIndex: 10,
        marginBottom: theme.spacing(1.5),
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        "&.stuck": {
          backgroundColor: theme.palette.background.default,
          paddingTop: theme.spacing(1),
          marginTop: -theme.spacing(1),
        },
      },
      formToolbarPaper: {
        padding: theme.spacing(0.75, 1.5, 0.75, 2),
        display: "flex",
        alignItems: "center",
        "&.lifted": {
          boxShadow: theme.shadows[5],
        },
      },
      title: {
        fontSize: "1.15rem",
      },
    }),
  {
    classNamePrefix: "NSFormToolbar",
  }
);

interface NSFormToolbarProps {
  children?: ReactNode;
  title?: string | null;
  loading?: boolean | null;
}

const NSFormToolbar = ({ children, title, loading }: NSFormToolbarProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const onScroll = useCallback(() => {
    const formToolbarEl = document.querySelector<HTMLElement>(
      `.${classes.formToolbar}`
    );
    const formToolbarPaperEl = document.querySelector<HTMLElement>(
      `.${classes.formToolbarPaper}`
    );
    const topSpacing = theme.spacing(0.5);
    if (formToolbarEl && formToolbarPaperEl && topSpacing) {
      if (window.pageYOffset >= topSpacing) {
        formToolbarEl.classList.add("stuck");
        formToolbarPaperEl.classList.add("lifted");
      } else {
        formToolbarEl.classList.remove("stuck");
        formToolbarPaperEl.classList.remove("lifted");
      }
    }
  }, [classes.formToolbar, classes.formToolbarPaper, theme]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  return (
    <Box className={classes.formToolbar}>
      <Paper className={classes.formToolbarPaper}>
        <Box flex={1} display="flex" alignItems="center" minHeight={32}>
          <Typography
            variant="h6"
            className={clsx("mr-4 text-bold", classes.title)}
          >
            {title}
          </Typography>
          {loading && <CircularProgress size={18} />}
        </Box>
        {!loading && <>{children}</>}
      </Paper>
    </Box>
  );
};

export default memo(NSFormToolbar);
