import { ProviderProps, useCallback, useState } from "react";
import {
  ConfirmationArg,
  ConfirmationContext,
  ConfirmationContextValue,
} from "./ConfirmationContext";
import ConfirmationDialog from "./ConfirmationDialog";

const ConfirmationProvider = ({
  children,
}: Omit<ProviderProps<ConfirmationContextValue>, "value">) => {
  const [isShowing, setIsShowing] = useState(false);
  const [confirmation, setConfirmation] = useState<ConfirmationArg | null>(
    null
  );

  const show = useCallback((data: ConfirmationArg) => {
    setIsShowing(true);
    setConfirmation(data);
  }, []);

  const close = useCallback(() => {
    setIsShowing(false);
    setConfirmation(null);
  }, []);

  return (
    <ConfirmationContext.Provider
      value={{
        isShowingConfirmation: isShowing,
        confirmation,
        showConfirmation: show,
        closeConfirmation: close,
      }}
    >
      <ConfirmationDialog />
      {children}
    </ConfirmationContext.Provider>
  );
};

export default ConfirmationProvider;
