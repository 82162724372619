import environment from "@app/environment";
import { darkTheme, lightTheme } from "@app/theme";
import ConfirmationProvider from "@components/Confirmation/ConfirmationProvider";
import { AppLoadingIndicatorProvider } from "@components/LoadingIndicator";
import useThemeMode from "@components/ThemeMode";
import DateFnsUtils from "@date-io/date-fns";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  unstable_createMuiStrictModeTheme,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SnackbarProvider } from "notistack";
import React, { useMemo } from "react";
import AppRouter from "./AppRouter";
import useGlobalStyles from "./hooks/useGlobalStyles";

const App = () => {
  const { mode } = useThemeMode();

  const themeOpt = useMemo(
    () => (mode === "dark" ? darkTheme : lightTheme),
    [mode]
  );

  const theme =
    environment.name === "dev"
      ? unstable_createMuiStrictModeTheme(themeOpt)
      : createTheme(themeOpt);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Root />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

const Root = () => {
  useGlobalStyles();
  return (
    <React.StrictMode>
      <SnackbarProvider
        maxSnack={2}
        autoHideDuration={3500}
        disableWindowBlurListener
      >
        <AppLoadingIndicatorProvider>
          <ConfirmationProvider>
            <AppRouter />
          </ConfirmationProvider>
        </AppLoadingIndicatorProvider>
      </SnackbarProvider>
    </React.StrictMode>
  );
};

export default App;
