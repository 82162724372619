import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { FC } from "react";

export interface NSDividerProps {
  label?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  tabLabel: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    width: "100%",
    color: theme.palette.grey[500],
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    minHeight: 18,
    "&:before, &:after": {
      content: '" "',
      flex: 1,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    "&:not(:empty):before": {
      marginRight: theme.spacing(1),
    },
    "&:not(:empty):after": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const NSDivider: FC<NSDividerProps> = ({ label, className }) => {
  const classes = useStyles();

  return <div className={clsx(classes.tabLabel, className)}>{label}</div>;
};

export default NSDivider;
