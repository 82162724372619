import { memo } from "react";
import { ReactElement } from "react";
import { Box, Paper } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
// import NSBreadcrumbs from "@components/NSBreadcrumbs";
import NSFormToolbar from "@components/NSFormToolbar";
import NSButton from "@components/NSButton";
import buildForm from "@components/FormBuilder";
import { prepareDataToCreate, useUserFormSections } from "./userFormHelpers";
import { useCreateUserMutation } from "@features/user/userAPI";
import { useHistory } from "react-router-dom";
import { UserWithPassword } from "@app/models";
import { useEffect } from "react";
import { useNotification } from "@app/hooks";

const NewUserForm = (): ReactElement => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [
    createUser,
    { isLoading, isSuccess, isError, data: mutationResponse },
  ] = useCreateUserMutation();
  const history = useHistory();
  const { showNotification } = useNotification();

  const formSections = useUserFormSections();

  useEffect(() => {
    if (isSuccess || isError) {
      if (mutationResponse?.user) {
        history.push(`/admin/user/info/${mutationResponse.user.id}`, {
          user: mutationResponse.user,
        });
        showNotification("User is created");
        return;
      }
      showNotification(mutationResponse?.message || "Unknown error", "warning");
      return;
    }
  }, [isSuccess, isError, mutationResponse, history, showNotification]);

  const submit = useCallback(
    async (data: UserWithPassword) => {
      if (isLoading) return;
      if (data.password !== data.retypedPassword) {
        setError("retypedPassword", {
          type: "validate",
          message: "Retyped password doesn't match",
        });
        return;
      }
      createUser(prepareDataToCreate(data));
    },
    [createUser, isLoading, setError]
  );

  return (
    <>
      {/* <NSBreadcrumbs /> */}
      <form onSubmit={handleSubmit(submit)} autoComplete="off">
        <NSFormToolbar title="Add a New User">
          <NSButton type="submit" color="primary" loading={isLoading}>
            Submit
          </NSButton>
        </NSFormToolbar>
        <Box component={Paper} p={3}>
          {buildForm({ control, sections: formSections, errors })}
        </Box>
      </form>
    </>
  );
};

export default memo(NewUserForm);
