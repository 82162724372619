import {
  FormSectionType,
  formDataFieldTypeValues,
  FormDataFieldType,
} from "@components/FormBuilder";
import { ReactNode, useMemo } from "react";

export interface UseConflictFormDefinitionConfig {
  submit: () => any;
  isSubmitting: boolean;
  conflictListComponent: ReactNode;
}

const conflictFormFields: FormSectionType["fields"] = [
  {
    type: "text",
    name: "reason",
    label: "Conflict",
    minRows: 6,
    maxRows: 10,
    maxLength: 200,
    rules: { required: true },
    slot: 2,
  },
  {
    type: "blank",
  },
  {
    type: "text",
    name: "location",
    label: "Location",
    rules: { required: true },
    slot: 2,
  },
  {
    type: "checkbox",
    name: "declarationCopyOnFile",
    label: "Copy on file (Mocking)",
  },
  {
    type: "date",
    name: "boardSignOff",
    label: "Board sign off",
    rules: { required: true },
  },
  {
    type: "date",
    name: "signedOn",
    label: "Signed on",
  },
];

export const conflictFormDataFields = conflictFormFields.filter((f) => {
  return formDataFieldTypeValues.includes(f.type);
}) as FormDataFieldType[];

const useConflictFormDefinition = ({
  submit,
  isSubmitting,
  conflictListComponent,
}: UseConflictFormDefinitionConfig) => {
  const def = useMemo<FormSectionType["fields"]>(
    () => [
      ...conflictFormFields,
      {
        type: "button",
        label: "Add conflict",
        onClick: submit,
        isLoading: isSubmitting,
      },
      {
        type: "custom",
        component: conflictListComponent,
      },
    ],
    [conflictListComponent, isSubmitting, submit]
  );

  return def;
};

export default useConflictFormDefinition;
