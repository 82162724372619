import { Standard } from "@app/models";
import { useCallback } from "react";

const useGetStandardLabelById = (standardList?: Standard[] | null) => {
  const getStandardLabelById = useCallback(
    (id: number) => {
      if (!standardList) {
        return id;
      }
      return standardList.find((item) => item.id === id)?.name || id;
    },
    [standardList]
  );

  return getStandardLabelById;
};

export default useGetStandardLabelById;
