import { createContext } from "react";

export type LoadingIndicatorVariant = "dark" | "light" | "transparent";

export type LoadingIndicatorContext = {
  open: boolean;
  show: (v?: LoadingIndicatorVariant) => any;
  hide: () => any;
  variant: LoadingIndicatorVariant;
};

const LoadingIndicatorContext = createContext<LoadingIndicatorContext>({
  open: false,
  show: () => null,
  hide: () => null,
  variant: "transparent",
});

export default LoadingIndicatorContext;
