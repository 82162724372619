import { useEffect } from "react";
import { useAppSocket } from "@pages/App/AppSocket";
import { useAppSelector, useNotification } from "@app/hooks";

const useAssessorInfoSocket = (assessorId: number | undefined) => {
  const socket = useAppSocket();
  const currentUserId = useAppSelector((state) => state.auth.id);
  const { showNotification } = useNotification();

  useEffect(() => {
    if (!assessorId || !socket) {
      return;
    }
    socket.emit("watch-assessor", assessorId);

    socket.on(`assessor-${assessorId}-update`, (message) => {
      console.log(message);
      if (currentUserId && message?.assessor.updatedBy !== currentUserId) {
        showNotification("Someone just updated this assessor", "warning");
      }
    });
    return () => {
      socket.emit("unwatch-assessor", assessorId);
    };
  }, [currentUserId, assessorId, socket, showNotification]);
};

export default useAssessorInfoSocket;
