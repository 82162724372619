import { BaseResponse, User } from "@app/models";
import request from "@app/api";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "@app/rtk-query";

export interface LoginRequestPayload {
  email: string;
  password: string;
}

export interface LoginResponse {
  user: User;
  token: string;
}

export type VerificationResponse = LoginResponse;

export const sendLoginRequest = async (
  payload: LoginRequestPayload
): Promise<LoginResponse | null> => {
  try {
    const response = await request.post<LoginResponse>("/auth", payload);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error();
  } catch (err) {
    return null;
  }
};

export const sendLogoutRequest = async (): Promise<Record<
  string,
  string
> | null> => {
  try {
    const response = await request.delete("/auth");
    if (response.status === 200) {
      return response.data;
    }
    throw new Error();
  } catch (err) {
    return null;
  }
};

export const sendVerificationRequest =
  async (): Promise<VerificationResponse | null> => {
    try {
      const response = await request.get<VerificationResponse>(
        "/auth/verification"
      );
      if (response.status === 200) {
        return response.data;
      }
      throw new Error();
    } catch (err) {
      return null;
    }
  };

export interface UpdatePasswordArg {
  currentPassword: string;
  newPassword: string;
}

/**
 * Assessor RTK Query definitions
 */

const authQuery = createApi({
  reducerPath: "authQuery",
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    updatePassword: build.mutation<BaseResponse, UpdatePasswordArg>({
      query: (data) => ({
        method: "post",
        url: `/auth/password`,
        data,
      }),
    }),
  }),
});

export const { useUpdatePasswordMutation } = authQuery;

export default authQuery;
