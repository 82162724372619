import { PageSizeOptions } from "@app/constants";
import NSBreadcrumbs from "@components/NSBreadcrumbs";
import NSDataGrid from "@components/NSDataGrid";
import {
  useGetAssessorListQuery,
  useGetConflictListQuery,
} from "@features/assessor/assessorAPI";
import { Grid, MenuItem } from "@material-ui/core";
import { GridColDef } from "@mui/x-data-grid";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Link, useHistory, useLocation } from "react-router-dom";
import NSSelect, { NSSelectProps } from "@components/NSSelect";
import { orderBy } from "lodash";
import {
  formatDateToDisplay,
  queryObjectToString,
  queryStringToObject,
} from "@app/helpers";
import ConflictListActionCell from "@pages/Assessor/components/AssessorConflictsSection/ConflictListActionCell";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    width: 80,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: "assessor",
    headerName: "Assessor",
    sortable: true,
    width: 220,
    valueGetter: (params) =>
      `${params.row.assessorFirstname} ${params.row.assessorSurname}`,
    renderCell: (params) => {
      const to = `/assessor/info/${params.row.assessorId}#conflicts`;
      return (
        <div className="flex-row align-items-center w-100">
          <Link to={to} className="mr-1 text-ellipsis flex-1">
            {`${params.row.assessorFirstname} ${params.row.assessorSurname}`}
          </Link>
          <Link to={to} target="_blank" className="flex-row">
            <OpenInNewIcon fontSize="small" />
          </Link>
        </div>
      );
    },
  },
  {
    field: "reason",
    headerName: "Reason",
    width: 280,
    editable: true,
    cellClassName: "cursor-pointer",
  },
  {
    field: "location",
    headerName: "Location",
    width: 200,
    editable: true,
    cellClassName: "cursor-pointer",
  },
  {
    field: "boardSignOff",
    headerName: "Board sign off",
    type: "date",
    width: 160,
    editable: true,
    cellClassName: "cursor-pointer",
    valueFormatter: (params) => {
      if (!params.row.boardSignOff) {
        return "";
      }
      return formatDateToDisplay(new Date(params.row.boardSignOff));
    },
  },
  {
    field: "signedOn",
    headerName: "Signed on",
    type: "date",
    width: 160,
    editable: true,
    cellClassName: "cursor-pointer",
    valueFormatter: (params) => {
      if (!params.row.signedOn) {
        return "";
      }
      return formatDateToDisplay(new Date(params.row.signedOn));
    },
  },
  {
    field: "expiredOn",
    headerName: "Expired on",
    width: 160,
    cellClassName: "outline-none color-disabled",
    valueFormatter: (params) => {
      if (!params.row.expiredOn) {
        return "";
      }
      return formatDateToDisplay(new Date(params.row.expiredOn));
    },
  },
  {
    field: "actions",
    headerName: " ",
    width: 90,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    align: "right",
    cellClassName: "outline-none",
    renderCell: ConflictListActionCell,
  },
];

type FilterType = {
  assessor: number;
};
/**
 * Hook the filter object base on location query params
 */
const useFilter = () => {
  const location = useLocation();
  const filter = useMemo<FilterType>(() => {
    const queryObj = queryStringToObject(location.search);

    return {
      assessor: +queryObj.assessor || 0,
    };
  }, [location]);
  return filter;
};

const ConflictList: FunctionComponent = () => {
  const {
    data: queryResponse,
    isLoading,
    isFetching,
  } = useGetConflictListQuery(null, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const [pageSize, setPageSize] = useState(PageSizeOptions[0]);
  const filter = useFilter();

  const history = useHistory();

  const { data: assessorListResponse, isFetching: isFetchAssessorList } =
    useGetAssessorListQuery();

  const onAssessorSelect: NSSelectProps["onChange"] = useCallback(
    (e) => {
      const queryObj = queryStringToObject(location.search);
      if (e.target.value) {
        queryObj.assessor = e.target.value;
      } else {
        delete queryObj.assessor;
      }
      history.push(`/assessor-conflict/list${queryObjectToString(queryObj)}`);
    },
    [history]
  );

  const list = useMemo(() => {
    if (!queryResponse?.conflicts) {
      return [];
    }
    return queryResponse.conflicts
      .filter((c) => {
        if (!filter.assessor) {
          return true;
        }
        return c.assessorId === filter.assessor;
      })
      .map((c) => {
        return {
          id: c.id,
          location: c.location,
          reason: c.reason,
          assessorId: c.assessorId,
          assessorFirstname: c.assessor.firstname,
          assessorSurname: c.assessor.surname,
        };
      });
  }, [queryResponse, filter.assessor]);

  const assessorSelectOptions = useMemo(() => {
    if (
      !assessorListResponse?.assessors ||
      assessorListResponse.assessors.length === 0
    ) {
      return [];
    }
    return orderBy(assessorListResponse.assessors, ["firstname", "surname"]);
  }, [assessorListResponse]);

  return (
    <>
      <NSBreadcrumbs isLoading={isFetching} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={5} md={4}>
          <NSSelect
            margin="none"
            label="Assessor"
            onChange={onAssessorSelect}
            value={filter.assessor}
            disabled={isFetchAssessorList}
          >
            <MenuItem value={0}>
              <em>Not selected</em>
            </MenuItem>
            {assessorSelectOptions.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.firstname} {item.surname}
              </MenuItem>
            ))}
          </NSSelect>
        </Grid>
        <Grid item xs={12}>
          <NSDataGrid
            loading={isLoading}
            rows={list}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={PageSizeOptions}
            onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ConflictList;
