import {
  Assessor,
  AssessorConflict,
  Avatar,
  BaseResponse,
  ConflictWithAssessor,
  Nullable,
  SectionCompletion,
  Voidable,
} from "@app/models";
import { axiosBaseQuery } from "@app/rtk-query";
import { convertObjectToQuery } from "@app/helpers";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

export type AssessorListResponse = {
  assessors: Assessor[];
} & BaseResponse;

export type AssessorInfoResponse = {
  assessor: Assessor;
} & BaseResponse;

export type AssessorImageResponse = {
  avatar: Avatar;
} & BaseResponse;

export type UpdateAssessorArg = Partial<Assessor> & Pick<Assessor, "id">;

export type CreateAssessorArg = Omit<Assessor, "id">;

export type GetAssessorListArg = Voidable<
  Nullable<{
    dbsExpiredIn?: number;
    include?: string;
  }>
>;

export type UploadAssessorImageArg = {
  imageFile: File;
} & Pick<Assessor, "id">;

export interface GetAssessorConflictListResponse extends BaseResponse {
  conflicts: AssessorConflict[];
}

export type CreateAssessorConflictArg = Omit<AssessorConflict, "id">;

export interface UpdateAssessorConflictArg {
  assessorConflictId: string | number;
  assessorConflict: Partial<Omit<AssessorConflict, "id" | "assessorId">>;
}

export interface GetAssessorSectionsCompletionResponse extends BaseResponse {
  completions: {
    [sectionName: string]: SectionCompletion;
  };
  assessorId: Assessor["id"];
}

export interface GetConflictListResponse extends BaseResponse {
  conflicts: ConflictWithAssessor[];
}

/**
 * Assessor RTK Query definitions
 */

const assessorQuery = createApi({
  reducerPath: "assessorQuery",
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  tagTypes: ["Assessor", "AssessorCompletion"],
  endpoints: (build) => ({
    getAssessorList: build.query<AssessorListResponse, GetAssessorListArg>({
      query: (arg) => ({
        method: "get",
        url: `/assessors${convertObjectToQuery(arg)}`,
      }),
    }),

    getAssessor: build.query<AssessorInfoResponse, number | string>({
      query: (id) => ({
        method: "get",
        url: `/assessors/${id}`,
      }),
    }),

    updateAssessor: build.mutation<AssessorInfoResponse, UpdateAssessorArg>({
      query: ({ id, ...patchData }) => ({
        method: "patch",
        url: `/assessors/${id}`,
        data: patchData,
      }),
      invalidatesTags: (_result, _err, arg) => [
        { type: "AssessorCompletion", id: arg.id },
      ],
    }),

    deleteAssessor: build.mutation<BaseResponse, number | string>({
      query: (id) => ({
        method: "delete",
        url: `/assessors/${id}`,
      }),
    }),
    createAssessor: build.mutation<AssessorInfoResponse, CreateAssessorArg>({
      query: (data) => ({
        method: "post",
        url: "/assessors",
        data,
      }),
    }),

    uploadAssessorImage: build.mutation<
      AssessorImageResponse,
      UploadAssessorImageArg
    >({
      query: ({ id, imageFile }) => {
        const formData = new FormData();
        formData.append("image", imageFile);
        return {
          method: "post",
          url: `/assessors/${id}/image`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
    }),

    getAssessorConflictList: build.query<
      GetAssessorConflictListResponse,
      number | string
    >({
      query: (assessorId) => ({
        method: "get",
        url: `/assessor-conflicts?assessorId=${assessorId}`,
      }),
      providesTags: [{ type: "Assessor", id: "AssessorConflictList" }],
    }),

    createAssessorConflict: build.mutation<
      BaseResponse,
      CreateAssessorConflictArg
    >({
      query: (assessorConflict) => ({
        method: "post",
        url: `/assessor-conflicts`,
        data: assessorConflict,
      }),
      invalidatesTags: [{ type: "Assessor", id: "AssessorConflictList" }],
    }),

    updateAssessorConflict: build.mutation<
      BaseResponse,
      UpdateAssessorConflictArg
    >({
      query: ({ assessorConflictId, assessorConflict }) => ({
        method: "patch",
        url: `/assessor-conflicts/${assessorConflictId}`,
        data: assessorConflict,
      }),
      invalidatesTags: [{ type: "Assessor", id: "AssessorConflictList" }],
    }),

    deleteAssessorConflict: build.mutation<
      BaseResponse,
      Pick<UpdateAssessorConflictArg, "assessorConflictId">
    >({
      query: (data) => ({
        method: "delete",
        url: `/assessor-conflicts/${data.assessorConflictId}`,
      }),
      invalidatesTags: [{ type: "Assessor", id: "AssessorConflictList" }],
    }),

    getAssessorSectionsCompletion: build.query<
      GetAssessorSectionsCompletionResponse,
      number | string
    >({
      query: (assessorId) => ({
        method: "get",
        url: `/assessors/section-completions/${assessorId}`,
      }),
      providesTags: (_result, _err, arg) => [
        { type: "AssessorCompletion", id: arg },
      ],
    }),

    getConflictList: build.query<GetConflictListResponse, null>({
      query: () => ({
        method: "get",
        url: `/assessor-conflicts`,
      }),
      providesTags: [{ type: "Assessor", id: "AssessorConflictList" }],
    }),
  }),
});

export const {
  useGetAssessorListQuery,
  useGetAssessorQuery,
  useUpdateAssessorMutation,
  useDeleteAssessorMutation,
  useCreateAssessorMutation,
  useUploadAssessorImageMutation,
  useGetAssessorConflictListQuery,
  useCreateAssessorConflictMutation,
  useUpdateAssessorConflictMutation,
  useDeleteAssessorConflictMutation,
  useGetAssessorSectionsCompletionQuery,
  useGetConflictListQuery,
} = assessorQuery;

export default assessorQuery;
