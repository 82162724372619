import axios, { CancelTokenSource } from "axios";
import { VariantType } from "notistack";
import request from "./api";

export class AppNotification {
  static notify: (
    message: string | React.ReactNode,
    variant?: VariantType
  ) => any;
}

export class AxiosInstance {
  private static source: CancelTokenSource;

  static init() {
    this.source = axios.CancelToken.source();
    request.defaults.cancelToken = this.source.token;
  }

  static cancel() {
    this.source.cancel();
  }

  private static unauthorizedInterceptor: number;

  static addUnauthorizedInterceptor(handler: () => any) {
    this.unauthorizedInterceptor = request.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          handler();
        }
        return Promise.reject(error);
      }
    );
  }

  static ejectUnauthorizedInterceptor() {
    if (!this.unauthorizedInterceptor) {
      return;
    }
    request.interceptors.request.eject(this.unauthorizedInterceptor);
  }
}
