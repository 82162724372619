import { useAppDispatch, useAppSelector } from "@app/hooks";
import useConfirmation from "@components/Confirmation/useConfirmation";
import NSBreadcrumbs from "@components/NSBreadcrumbs";
import NSDataGrid from "@components/NSDataGrid";
import NSFab from "@components/NSFab";
import {
  useDeleteQualificationMutation,
  useRestoreQualificationMutation,
} from "@features/general/generalAPI";
import { getQualificationListSilently } from "@features/general/generalSlice";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import UndoIcon from "@material-ui/icons/Undo";
import {
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import QualificationDialog from "./QualificationDialog";

const RenderCell = (params: GridCellParams) => {
  const { showConfirmation } = useConfirmation();
  const dispatch = useAppDispatch();

  const [
    deleteQualification,
    { isLoading: isDeleting, isSuccess: isDeleteSuccess },
  ] = useDeleteQualificationMutation();

  const [
    restoreQualification,
    { isLoading: isRestoring, isSuccess: isRestoreSuccess },
  ] = useRestoreQualificationMutation();

  useEffect(() => {
    if (isDeleteSuccess || isRestoreSuccess) {
      dispatch(getQualificationListSilently());
    }
  }, [isDeleteSuccess, isRestoreSuccess, dispatch]);

  const onDelete = useCallback(() => {
    showConfirmation({
      title: "Disable qualification",
      content: (
        <>
          <span>You are going to disable </span>
          <b>{params.getValue(params.id, "name")}</b>
          <span>. Confirm to proceed this?</span>
        </>
      ),
      onConfirm: () => {
        deleteQualification(params.id);
      },
    });
  }, [showConfirmation, params, deleteQualification]);

  const onRestore = useCallback(() => {
    showConfirmation({
      title: "Enable qualification",
      content: (
        <>
          <span>You are going to enable </span>
          <b>{params.getValue(params.id, "name")}</b>
          <span>. Confirm to proceed this?</span>
        </>
      ),
      onConfirm: () => {
        restoreQualification(params.id);
      },
    });
  }, [showConfirmation, params, restoreQualification]);

  const isLoading = isDeleting || isRestoring;

  return (
    <>
      {isLoading && <CircularProgress size={16} />}
      {!params.row.deletedAt ? (
        <>
          <Link to={`/qualification/list/${params.id}`}>
            <IconButton size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          </Link>
          <Tooltip enterDelay={500} title="Disable" placement="top-start" arrow>
            <IconButton size="small" onClick={onDelete}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Tooltip enterDelay={500} title="Enable" placement="top-start" arrow>
          <IconButton size="small" onClick={onRestore}>
            <UndoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    width: 80,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    width: 600,
  },
  {
    field: "deletedAt",
    headerName: "Active",
    type: "boolean",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    width: 80,
    valueGetter: (params: GridValueGetterParams) => !params.row.deletedAt,
  },
  {
    field: "actions",
    headerName: " ",
    width: 68,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    align: "right",
    renderCell: RenderCell,
  },
];

const QualificationList = () => {
  const list = useAppSelector((state) => state.general.qualifications);
  const dispatch = useAppDispatch();
  const params = useParams<{ id?: string }>();
  const history = useHistory();

  const selectedId = useMemo(() => params.id, [params]);

  useEffect(() => {
    dispatch(getQualificationListSilently());
  }, [dispatch]);

  const closeDialog = useCallback(() => {
    history.push("/qualification/list");
  }, [history]);

  const openCreateQualificationDialog = useCallback(() => {
    history.push("/qualification/list/new");
  }, [history]);

  return (
    <>
      <NSBreadcrumbs />
      <NSDataGrid rows={list ?? []} columns={columns} />
      {!!selectedId && (
        <QualificationDialog
          selectedId={selectedId}
          closeDialog={closeDialog}
        />
      )}
      <NSFab onClick={openCreateQualificationDialog}>
        <AddIcon />
      </NSFab>
    </>
  );
};

export default QualificationList;
