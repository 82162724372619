import {
  DatePickerView,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";

export type NSDatePickerMode = "default" | "monthAndYear" | undefined;

export interface NSDatePickerProps
  extends Omit<KeyboardDatePickerProps, "format" | "views"> {
  mode?: NSDatePickerMode;
}

const NSDatePicker = ({ mode, inputRef, ...props }: NSDatePickerProps) => {
  const [value, setValue] = useState<any>(props.value || "");

  const displayValue = useMemo(() => {
    switch (mode) {
      case "monthAndYear":
        return {
          format: "MM/yyyy",
          views: ["year", "month"] as DatePickerView[],
        };
      case "default":
      default:
        return {
          format: "dd/MM/yyyy",
          views: undefined,
        };
    }
  }, [mode]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <KeyboardDatePicker
      autoOk
      fullWidth
      variant="inline"
      size="small"
      margin="normal"
      inputVariant="outlined"
      className="pr-0"
      {...displayValue}
      {...props}
      value={value || null}
      onChange={(d) => {
        if (props.onChange) {
          props.onChange(d);
        }
        setValue(d);
      }}
      helperText={props.helperText}
      KeyboardButtonProps={{ size: "small" }}
      InputProps={{ classes: { adornedEnd: "pr-2" } }}
      InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
      inputRef={inputRef}
    />
  );
};

export default React.forwardRef((props: NSDatePickerProps, ref) => (
  <NSDatePicker {...props} inputRef={ref} />
));
