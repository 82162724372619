import NSButton from "@components/NSButton";
import NSTextField from "@components/NSTextField";
import { useLazyGetQualificationLevelQuery } from "@features/general/generalAPI";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import useCreateQualificationLevel from "./hooks/useCreateQualificationLevel";
import useUpdateQualificationLevel from "./hooks/useUpdateQualificationLevel";

const useStyles = makeStyles(
  () => ({
    dialogTitle: {
      flex: 1,
      "& h2": {
        display: "flex",
        alignItems: "center",
      },
    },
  }),
  {
    classNamePrefix: "QualificationLevelDialog",
  }
);

export interface QualificationLevelDialogProps {
  selectedId?: "new" | string | number | null;
  closeDialog: () => void;
}

const QualificationLevelDialog = ({
  selectedId,
  closeDialog,
}: QualificationLevelDialogProps) => {
  const classes = useStyles();

  const [getQualificationLevel, { isFetching, data, isError }] =
    useLazyGetQualificationLevelQuery();

  const { updateQualificationLevel, isUpdating } = useUpdateQualificationLevel({
    callback: closeDialog,
  });

  const { createQualificationLevel, isCreating } = useCreateQualificationLevel({
    callback: closeDialog,
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
    },
  });

  useEffect(() => {
    if (!selectedId || selectedId === "new") {
      return;
    }
    getQualificationLevel(selectedId);
  }, [getQualificationLevel, selectedId]);

  useEffect(() => {
    if (data?.qualificationLevel) {
      setValue("id", data.qualificationLevel.id.toString());
      setValue("name", data.qualificationLevel.name);
    }
  }, [data, setValue]);

  useEffect(() => {
    if (isError) {
      closeDialog();
    }
  }, [isError, closeDialog]);

  const handleOnClose = useCallback<Required<DialogProps>["onClose"]>(
    (_, reason) => {
      if (reason === "backdropClick") {
        return;
      }
      closeDialog();
    },
    [closeDialog]
  );

  return (
    <Dialog open={!!selectedId} onClose={handleOnClose} maxWidth="sm" fullWidth>
      <Box display="flex" alignItems="center" paddingRight={3}>
        <DialogTitle className={classes.dialogTitle}>
          {selectedId !== "new"
            ? "Update Qualification Level"
            : "New Qualification Level"}
          {isFetching && <CircularProgress size={18} className="ml-2" />}
        </DialogTitle>
        <IconButton
          edge="end"
          size="small"
          className="ml-3"
          onClick={closeDialog}
          disabled={isUpdating}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        {selectedId !== "new" && (
          <Controller
            control={control}
            name="id"
            render={({ field }) => {
              return <NSTextField label="ID" disabled {...field} />;
            }}
          />
        )}
        <Controller
          control={control}
          name="name"
          rules={{ required: "This field is required" }}
          render={({ field }) => {
            return (
              <NSTextField
                label="Name"
                error={!!errors.name}
                helperText={errors.name?.message}
                minRows={2}
                multiline
                {...field}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions className="pl-6 pr-6 pb-5">
        {selectedId !== "new" ? (
          <NSButton
            color="primary"
            loading={isUpdating}
            onClick={handleSubmit(updateQualificationLevel)}
          >
            Submit
          </NSButton>
        ) : (
          <NSButton
            color="primary"
            loading={isCreating}
            onClick={handleSubmit(createQualificationLevel)}
          >
            Create
          </NSButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default QualificationLevelDialog;
