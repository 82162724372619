export type AssessorStatusKey = "NAR" | "ACTIVE" | "INACTIVE";

export const AssessorStatus: { [key in AssessorStatusKey]: string } = {
  NAR: "NAR",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const AssessorStatusList = (
  Object.keys(AssessorStatus) as AssessorStatusKey[]
).map((sk) => ({
  value: sk,
  label: AssessorStatus[sk],
}));

export const DevelopmentStatusList = [
  {
    value: "ISSUE_SIGNED_OFF",
    label: "Issue signed off",
  },
  {
    value: "UNDER_REVIEW",
    label: "Under review",
  },
  {
    value: "OUTSTANDING",
    label: "Outstanding",
  },
  {
    value: "SIGNED_OFF",
    label: "Signed off",
  },
  {
    value: "FURTHER_MONITORING",
    label: "Further monitoring",
  },
];
