import { TextField, TextFieldProps } from "@material-ui/core";
import React, { useMemo } from "react";

export type NSSelectProps = TextFieldProps & {
  displayEmpty?: boolean;
  multiple?: boolean;
};

const NSSelect = ({
  children,
  displayEmpty,
  multiple,
  value: rawValue,
  ...props
}: NSSelectProps) => {
  const value = useMemo(() => {
    if (multiple) {
      if (Array.isArray(rawValue)) {
        return rawValue;
      }
      return [];
    }
    return rawValue;
  }, [multiple, rawValue]);

  return (
    <TextField
      select
      autoComplete={props.autoComplete ?? "new-password"}
      fullWidth
      variant="outlined"
      size="small"
      margin="normal"
      value={value}
      {...props}
      helperText={props.helperText}
      InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
      InputProps={{ autoComplete: props.autoComplete ?? "new-password" }}
      SelectProps={{
        displayEmpty,
        multiple,
      }}
    >
      {children}
    </TextField>
  );
};

export default React.forwardRef((props: NSSelectProps, ref) => (
  <NSSelect {...props} inputRef={ref} />
));
