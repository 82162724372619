import { useAppDispatch, useNotification } from "@app/hooks";
import { useUpdateQualificationLevelMutation } from "@features/general/generalAPI";
import { getQualificationLevelListSilently } from "@features/general/generalSlice";
import { useEffect } from "react";

const useUpdateQualificationLevel = ({ callback }: { callback: () => any }) => {
  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();
  const [
    updateQualificationLevel,
    { isLoading: isUpdating, isSuccess, isError },
  ] = useUpdateQualificationLevelMutation();

  useEffect(() => {
    if (isSuccess) {
      showNotification("Qualification level is updated");
      dispatch(getQualificationLevelListSilently());
      callback();
      return;
    }
    if (isError) {
      showNotification("Cannot update this qualification level.", "error");
    }
  }, [isSuccess, isError, showNotification, callback, dispatch]);

  return {
    updateQualificationLevel,
    isUpdating,
  };
};

export default useUpdateQualificationLevel;
