import { usePasswordDialog } from "@app/contexts";
import { useAppDispatch } from "@app/hooks";
import useLoadingIndicator from "@components/LoadingIndicator";
import useThemeMode from "@components/ThemeMode";
import { logout } from "@features/auth/authSlice";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Popover,
  Theme,
  Typography,
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import Brightness2Icon from "@material-ui/icons/Brightness2";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { makeStyles } from "@material-ui/styles";
import { Fragment, memo, useCallback, useEffect, useMemo } from "react";

const useStyles = makeStyles(
  (theme: Theme) => {
    const darkMode = theme.palette.type === "dark";
    return {
      menuItem: {
        minWidth: theme.spacing(24),
        color: darkMode
          ? theme.palette.getContrastText(theme.palette.background.default)
          : theme.palette.primary.main,
      },
      menuItemIcon: {
        minWidth: theme.spacing(4.5),
        color: darkMode
          ? theme.palette.getContrastText(theme.palette.background.default)
          : theme.palette.primary.main,
      },
    };
  },
  {
    classNamePrefix: "TopBarUserMenu",
  }
);

interface TopBarUserMenuProps {
  anchorEl?: Element | ((element: Element) => Element) | null;
  onClose?: () => void;
}

type UserMenuItem = {
  text: string;
  icon: OverridableComponent<any>;
  onClick?: () => any;
};

const TopBarUserMenu = ({ anchorEl, onClose }: TopBarUserMenuProps) => {
  const classes = useStyles();
  const { mode, switchTheme } = useThemeMode();
  const dispatch = useAppDispatch();

  const { openPasswordDialog } = usePasswordDialog();
  const { show, hide } = useLoadingIndicator();

  useEffect(() => {
    return () => {
      switchTheme("dark");
      hide();
    };
  }, [hide, switchTheme]);

  const handleUpdatePasswordClick = useCallback(() => {
    if (onClose) {
      onClose();
    }
    openPasswordDialog();
  }, [onClose, openPasswordDialog]);

  const onLogoutClick = useCallback(() => {
    dispatch(logout());
    show();
  }, [dispatch, show]);

  const menuItems = useMemo<UserMenuItem[]>(
    () => [
      {
        text: "Update password",
        icon: VpnKeyIcon,
        onClick: handleUpdatePasswordClick,
      },
    ],
    [handleUpdatePasswordClick]
  );

  const systemControls = useMemo<UserMenuItem[]>(
    () => [
      {
        text: mode === "light" ? "Dark mode" : "Light mode",
        icon: mode === "light" ? Brightness2Icon : Brightness7Icon,
        onClick: () => switchTheme(mode === "light" ? "dark" : "light"),
      },
      {
        text: "Logout",
        icon: PowerSettingsNewIcon,
        onClick: onLogoutClick,
      },
    ],
    [onLogoutClick, mode, switchTheme]
  );

  return (
    <Popover
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <List>
        {menuItems.map(({ text, icon: IconComponent, onClick }) => {
          return (
            <Fragment key={text}>
              <ListItem
                button
                className={classes.menuItem}
                onClick={onClick ?? undefined}
              >
                <ListItemIcon className={classes.menuItemIcon}>
                  <IconComponent fontSize="small" />
                </ListItemIcon>
                <Typography>{text}</Typography>
              </ListItem>
            </Fragment>
          );
        })}
      </List>
      <Divider />
      <List>
        {systemControls.map(({ text, icon: IconComponent, onClick }) => {
          return (
            <Fragment key={text}>
              <ListItem
                button
                className={classes.menuItem}
                onClick={onClick ?? undefined}
              >
                <ListItemIcon className={classes.menuItemIcon}>
                  <IconComponent fontSize="small" />
                </ListItemIcon>
                <Typography>{text}</Typography>
              </ListItem>
            </Fragment>
          );
        })}
      </List>
    </Popover>
  );
};

export default memo(TopBarUserMenu);
