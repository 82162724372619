import { BaseResponse, ERA } from "@app/models";
import { axiosBaseQuery } from "@app/rtk-query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

interface ERAListResponse extends BaseResponse {
  eras: ERA[];
}

interface ERAResponse extends BaseResponse {
  era: ERA;
}

export interface UpdateERAArg extends Partial<Omit<ERA, "id">> {
  id: number;
}

export type CreateERAArg = Partial<Omit<ERA, "id">>;

/**
 * ERA RTK Query definitions
 */

const eraQuery = createApi({
  reducerPath: "eraQuery",
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  tagTypes: ["ERA"],
  endpoints: (build) => ({
    getERAList: build.query<ERAListResponse, void>({
      query: () => ({
        method: "get",
        url: `/eras`,
      }),
      providesTags: [{ type: "ERA", id: "List" }],
    }),

    getERA: build.query<ERAResponse, number>({
      query: (id) => ({
        method: "get",
        url: `/eras/${id}`,
      }),
      providesTags: (_result, _error, arg) => [{ type: "ERA", id: arg }],
    }),

    updateERA: build.mutation<ERAResponse, UpdateERAArg>({
      query: ({ id, ...era }) => ({
        method: "patch",
        url: `/eras/${id}`,
        data: era,
      }),
    }),

    createERA: build.mutation<ERAResponse, CreateERAArg>({
      query: (data) => ({
        method: "post",
        url: "/eras",
        data,
      }),
    }),
  }),
});

export const {
  useGetERAListQuery,
  useGetERAQuery,
  useUpdateERAMutation,
  useCreateERAMutation,
} = eraQuery;

export default eraQuery;
