import { useNotification } from "@app/hooks";
import useConfirmation from "@components/Confirmation/useConfirmation";
import {
  useDeleteAssessorStandardMutation,
  useLazyGetStandardsByAssessorIdQuery,
} from "@features/standard/standardAPI";
import { CircularProgress, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { GridCellParams } from "@mui/x-data-grid";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

const StandardListActionCell = (params: GridCellParams) => {
  const { showConfirmation } = useConfirmation();
  const [deleteAssessorStandard, { isLoading: isDeleting, isSuccess }] =
    useDeleteAssessorStandardMutation();
  const { id: assessorId } = useParams<Record<"id", string>>();

  const { showNotification } = useNotification();

  const [getStandardsByAssessorId, { isLoading }] =
    useLazyGetStandardsByAssessorIdQuery();

  useEffect(() => {
    if (isSuccess) {
      showNotification("A standard is deleted successfully");
      getStandardsByAssessorId(assessorId);
    }
  }, [showNotification, assessorId, getStandardsByAssessorId, isSuccess]);

  const onDeleteClick = useCallback(() => {
    showConfirmation({
      title: "Confirmation",
      content:
        "Are you sure to delete this standard from assessor? It cannot be undone.",
      onConfirm: () =>
        deleteAssessorStandard({ assessorStandardId: params.id }),
    });
  }, [deleteAssessorStandard, params, showConfirmation]);

  return (
    <>
      {isDeleting || isLoading ? (
        <CircularProgress size={16} />
      ) : (
        <IconButton size="small" onClick={onDeleteClick}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </>
  );
};

export default StandardListActionCell;
