import { prepareData } from "@app/helpers";
import { useNotification } from "@app/hooks";
import {
  buildFormSectionContent,
  FormSectionContentBuilderProps,
  FormSectionContentItem,
  FormTitle,
} from "@components/FormBuilder";
import NSDataGrid from "@components/NSDataGrid";
import {
  CreateDevelopmentArg,
  UpdateDevelopmentArg,
  useCreateDevelopmentMutation,
  useGetDevelopmentListQuery,
  useUpdateDevelopmentMutation,
} from "@features/assessor/assessorDevelopmentAPI";
import { Typography } from "@material-ui/core";
import { GridCellEditCommitParams } from "@mui/x-data-grid";
import {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useDevelopmentColumns from "./useDevelopmentColumns";
import useDevelopmentFormDefinition, {
  developmentFormDataFields,
} from "./useDevelopmentFormDefinition";

const AssessorDevelopment: FunctionComponent = () => {
  const { id } = useParams<Record<"id", string>>();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      status: "",
      reviewDate: "",
      signedOff: "",
      note: "",
    },
  });

  const columns = useDevelopmentColumns();

  const { showNotification } = useNotification();

  const { data, isLoading } = useGetDevelopmentListQuery(id);

  const [createDevelopment, { isLoading: isCreating, isSuccess: isCreated }] =
    useCreateDevelopmentMutation();

  const [updateDevelopment, { isLoading: isUpdating, isSuccess: isUpdated }] =
    useUpdateDevelopmentMutation();

  useEffect(() => {
    if (isCreated) {
      showNotification("A development record is added successfully");
      reset();
    }
  }, [isCreated, reset, showNotification]);

  useEffect(() => {
    if (isUpdated) {
      showNotification("A development record is updated successfully");
    }
  }, [isUpdated, showNotification]);

  const rows = useMemo(() => data?.developments || [], [data]);

  const onAddDevelopment = handleSubmit((rawData) => {
    const data = prepareData<CreateDevelopmentArg>(
      {
        ...rawData,
        assessorId: id,
      },
      developmentFormDataFields
    );
    createDevelopment(data);
  });

  const onCellEditCommit = useCallback(
    (params: GridCellEditCommitParams) => {
      const payload: UpdateDevelopmentArg = {
        id: Number(params.id),
        [params.field]: params.value,
      };
      updateDevelopment(payload);
    },
    [updateDevelopment]
  );

  const developmentListComponent = useMemo(
    () => (
      <Fragment key="developmentListComponent">
        <FormSectionContentItem slot={3}>
          <FormTitle>Development list</FormTitle>
          <Typography variant="caption">
            (*Double click on a cell to edit)
          </Typography>
        </FormSectionContentItem>
        <FormSectionContentItem slot={3}>
          <NSDataGrid
            loading={isLoading || isUpdating}
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            cellFocusable
            onCellEditCommit={onCellEditCommit}
            stickyHeader={false}
            paperContainerProps={{
              variant: "outlined",
              className: "mt-2 mb-2",
            }}
          />
        </FormSectionContentItem>
      </Fragment>
    ),
    [columns, isLoading, isUpdating, onCellEditCommit, rows]
  );

  const developmentFormDef = useDevelopmentFormDefinition({
    submit: onAddDevelopment,
    isSubmitting: isCreating,
    developmentListComponent,
  });

  return buildFormSectionContent({
    control,
    errors,
    section: {
      fields: developmentFormDef,
      label: "Assessor development",
    },
  } as unknown as FormSectionContentBuilderProps);
};

export default AssessorDevelopment;
