import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Theme,
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { ReactNode, useMemo } from "react";
import { memo, useCallback, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Nullable } from "@app/models";

const useStyles = makeStyles(
  (theme: Theme) => {
    const darkMode = theme.palette.type === "dark";
    return {
      sideBarItem: {
        color: darkMode
          ? theme.palette.getContrastText(theme.palette.background.default)
          : theme.palette.primary.contrastText,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(1),
        cursor: "pointer",
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.08)",
        },
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.16)",
        },
      },
      sideBarItemIcon: {
        color: "inherit",
        minWidth: 0,
      },
      sideBarItemText: {
        color: "inherit",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        paddingLeft: theme.spacing(1.5),
        display: "flex",
        alignItems: "center",
        "& > label": {
          flex: 1,
          cursor: "inherit",
        },
        "& > .MuiSvgIcon-root": {
          opacity: 0.3,
        },
      },
      sideBarSubItem: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: "auto",
      },
      sideBarSubItemText: {
        "& > span:before": {
          content: "'•'",
          paddingLeft: theme.spacing(0.5),
          paddingRight: theme.spacing(1.5),
        },
      },
    };
  },
  { classNamePrefix: "SideBarListItem" }
);

export interface ChildListItemProps {
  text: string;
  path: string;
  target?: string;
  selected?: boolean;
  disabled?: boolean;
}

export interface SideBarListItemProps
  extends Omit<ChildListItemProps, "path" | "selected"> {
  icon?: OverridableComponent<SvgIconTypeMap>;
  nestedItems?: Array<ChildListItemProps>;
  path?: string;
}

const useListItemPrimaryText = (
  text: Nullable<string | ReactNode>,
  target?: string
) => {
  const primaryText = useMemo(() => {
    if (target !== "_blank") {
      return <label>{text}</label>;
    }
    return (
      <>
        <label>{text}</label>
        <OpenInNewIcon fontSize="small" />
      </>
    );
  }, [text, target]);

  return primaryText;
};

const SideBarListItem = ({
  text,
  icon: Icon,
  path,
  nestedItems,
  target,
  disabled,
}: SideBarListItemProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const [nestedItemsVisible, setNestedItemsVisible] = useState(true);

  const selected = useMemo(() => pathname === path, [pathname, path]);
  const itemPrimaryText = useListItemPrimaryText(text, target);

  const onItemClick = useCallback(() => {
    if (selected) return;
    if (!nestedItems) {
      if (path) {
        if (target === "_blank") {
          window.open(path, target);
          return;
        }
        history.push(path);
        return;
      }
      return;
    }
    setNestedItemsVisible((prev) => !prev);
  }, [selected, nestedItems, path, target, history]);

  const renderExpendIcon = useCallback(() => {
    if (!nestedItems) {
      return null;
    }
    return nestedItemsVisible ? <ExpandMore /> : <ChevronRightIcon />;
  }, [nestedItems, nestedItemsVisible]);

  if (disabled) {
    return null;
  }

  return (
    <>
      <ListItem
        selected={pathname === path}
        className={classes.sideBarItem}
        button
        onClick={onItemClick}
        dense
      >
        <ListItemIcon className={classes.sideBarItemIcon}>
          {Icon && <Icon fontSize="small" />}
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.sideBarItemText }}
          primary={itemPrimaryText}
        />
        {renderExpendIcon()}
      </ListItem>
      {nestedItems?.map((child) => (
        <Collapse
          key={child.text}
          in={nestedItemsVisible}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ChildListItem {...child} selected={pathname === child.path} />
          </List>
        </Collapse>
      ))}
    </>
  );
};

const ChildListItem = memo(
  ({ path, text, target, selected = false, disabled }: ChildListItemProps) => {
    const classes = useStyles();
    const itemPrimaryText = useListItemPrimaryText(text, target);

    if (disabled) {
      return null;
    }

    return (
      <Link to={path} target={target} className="text-decoration-none">
        <ListItem
          selected={selected}
          className={clsx(classes.sideBarItem, classes.sideBarSubItem)}
          button
          dense
        >
          <ListItemText
            className={classes.sideBarSubItemText}
            classes={{ primary: classes.sideBarItemText }}
            primary={itemPrimaryText}
          />
        </ListItem>
      </Link>
    );
  }
);
ChildListItem.displayName = "ChildListItem";

export default memo(SideBarListItem);
