import { formatDateToDisplay } from "@app/helpers";
import { Standard } from "@app/models";
import { Checkbox } from "@material-ui/core";
import { GridCellEditCommitParams, GridColumns } from "@mui/x-data-grid";
import { useMemo } from "react";
import StandardListActionCell from "./StandardListActionCell";
import useGetStandardLabelById from "./useGetStandardLabelById";

interface UseStandardColumnsProps {
  standardList?: Standard[] | null;
  onCellEditCommit?: (params: GridCellEditCommitParams) => any;
}

const useStandardColumns = ({
  standardList,
  onCellEditCommit,
}: UseStandardColumnsProps) => {
  const getStandardLabelById = useGetStandardLabelById(standardList);

  const valueOptions = useMemo(() => {
    if (!standardList) {
      return [];
    }
    return standardList.map((s) => ({
      value: s.id,
      label: s.name,
    }));
  }, [standardList]);

  const columns: GridColumns = useMemo(() => {
    return [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        width: 70,
        filterable: false,
        disableColumnMenu: true,
        hide: true,
      },
      {
        field: "standardId",
        headerName: "Standard",
        type: "singleSelect",
        valueOptions,
        width: 250,
        maxWidth: 260,
        editable: true,
        cellClassName: "cursor-pointer",
        valueFormatter: (params) => {
          return getStandardLabelById(params.row.standardId);
        },
      },
      {
        field: "meetingDate",
        headerName: "Meeting date",
        type: "date",
        width: 160,
        editable: true,
        cellClassName: "cursor-pointer",
        valueFormatter: (params) => {
          if (!params.row.meetingDate) {
            return "";
          }
          return formatDateToDisplay(new Date(params.row.meetingDate));
        },
      },
      {
        field: "expiryDate",
        headerName: "Expiry date",
        type: "date",
        width: 160,
        cellClassName: "outline-none color-disabled",
        valueFormatter: (params) => {
          if (!params.row.expiryDate) {
            return "";
          }
          return formatDateToDisplay(new Date(params.row.expiryDate));
        },
      },
      {
        field: "isAttended",
        headerName: "Attended",
        type: "boolean",
        width: 120,
        sortable: false,
        cellClassName: "outline-none",
        renderCell: (params) => {
          return (
            <Checkbox
              color="primary"
              checked={!!params.value}
              onChange={(e) => {
                if (!onCellEditCommit) {
                  return;
                }
                onCellEditCommit({
                  field: "isAttended",
                  id: params.row.id,
                  value: e.target.checked,
                });
              }}
            />
          );
        },
      },
      {
        field: "actions",
        headerName: " ",
        width: 90,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: "right",
        cellClassName: "outline-none",
        renderCell: StandardListActionCell,
      },
    ];
  }, [getStandardLabelById, onCellEditCommit, valueOptions]);

  return columns;
};

export default useStandardColumns;
