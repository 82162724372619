import { useAppDispatch, useAppSelector } from "@app/hooks";
import { verify } from "@features/auth/authSlice";
import { useMemo, useRef } from "react";

export type AllowedRouteName = "member" | "guest" | null;

const useRoutesGuard = (): AllowedRouteName => {
  /**
   * `authenticated` stores the authentication status, if users reload the page, `authenticated` will be persisted in IndexedDB. After the reloading,
   * this hook will check the value of `authenticated`.
   * - If `authenticated === true`: call api to verify the token
   * - Else: show `guest` routes
   */
  const authenticated = useAppSelector((state) => state.auth.authenticated);
  const reloadApp = useAppSelector((state) => state.auth.reloadApp);
  const verified = useAppSelector((state) => state.auth.verified);
  const verificationStatus = useAppSelector((state) => state.auth.status);

  const dispatch = useAppDispatch();

  const previousValue = useRef<AllowedRouteName>(null);

  const routeName = useMemo(() => {
    if (reloadApp) {
      window.location.href = window.location.origin;
      return previousValue.current;
    }
    if (!authenticated) {
      previousValue.current = "guest";
      return "guest";
    }
    if (verificationStatus === "idle" && !verified) {
      dispatch(verify());
      previousValue.current = null;
      return null;
    }
    if (verificationStatus === "idle" && verified) {
      previousValue.current = "member";
      return "member";
    }
    if (verificationStatus === "failed" && verified) {
      previousValue.current = "guest";
      return "guest";
    }
    return previousValue.current;
  }, [reloadApp, authenticated, verified, verificationStatus, dispatch]);

  return routeName;
};

export default useRoutesGuard;
