import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { orange, yellow } from "@material-ui/core/colors";

interface StyleKey {
  [key: string]: string;
}

const styleKeys: StyleKey = {
  m: "margin",
  mt: "marginTop",
  mb: "marginBottom",
  ml: "marginLeft",
  mr: "marginRight",
  p: "padding",
  pt: "paddingTop",
  pb: "paddingBottom",
  pl: "paddingLeft",
  pr: "paddingRight",
};

const useGlobalStyles = makeStyles((theme: Theme) => {
  const clsNames = Object.keys(styleKeys);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cls: { [key: string]: any } = {};
  for (let i = -10; i <= 10; i = i + 0.5) {
    clsNames.forEach((n) => {
      cls[`.${n}-${i.toString().replace(".", "\\.")}`] = {
        [`${styleKeys[n]}`]: theme.spacing(i / 2),
      };
    });
  }

  for (let i = 100; i >= 0; i = i - 5) {
    cls[`.opacity-${i}`] = {
      opacity: i / 100,
    };
  }

  for (let i = 5; i <= 100; i = i + 5) {
    cls[`.w-${i}`] = {
      width: `${i}%`,
    };
  }

  return createStyles({
    "@global": {
      html: {
        scrollBehavior: "smooth",
        fontSize: 14,
      },
      a: {
        color: theme.palette.text.primary,
      },
      ".MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      ".MuiDataGrid-columnHeaderTitle": {
        fontWeight: "600 !important",
      },
      ".MuiDataGrid-cell": {
        "& .MuiDataGrid-booleanCell[data-value='true']": {
          color: theme.palette.success.main,
        },
      },
      "@keyframes blink": {
        "0%": {
          opacity: 0.2,
          backgroundColor: theme.palette.success.main,
        },
        "100%": {
          opacity: 1,
        },
      },
      ".blink": {
        animation: `$blink 2.5s ${theme.transitions.easing.easeInOut}`,
      },
      ...cls,
      ".text-center": {
        textAlign: "center",
      },
      ".text-left": {
        textAlign: "left",
      },
      ".text-right": {
        textAlign: "right",
      },
      ".outline-none, .outline-none:focus": {
        outline: "none !important",
      },
      ".border-none": {
        border: "none",
      },
      ".text-light": {
        fontWeight: 300,
      },
      ".text-normal": {
        fontWeight: 400,
      },
      ".text-bold": {
        fontWeight: 600,
      },
      ".text-decoration-none": {
        textDecoration: "none",
      },
      ".text-underline": {
        textDecoration: "underline",
      },
      ".color-success": {
        color: theme.palette.success.main,
      },
      ".color-warning-level-1": {
        color: theme.palette.type === "dark" ? yellow[700] : yellow[800],
      },
      ".color-warning-level-2": {
        color: theme.palette.type === "dark" ? orange[800] : orange[900],
      },
      ".color-error": {
        color: theme.palette.error.main,
      },
      ".color-inherit": {
        color: "inherit",
      },
      ".color-disabled": {
        color: theme.palette.text.disabled,
      },
      em: {
        fontStyle: "normal",
        opacity: 0.5,
      },
      ".flex-row": {
        display: "flex",
        flexDirection: "row",
      },
      ".flex-col": {
        display: "flex",
        flexDirection: "col",
      },
      ".flex-1": {
        flex: 1,
      },
      ".cursor-pointer": {
        cursor: "pointer",
      },
      ".justify-content-center": {
        justifyContent: "center",
      },
      ".align-items-center": {
        alignItems: "center",
      },
      ".text-ellipsis": {
        width: "100%",
        flex: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      ".overflow-hidden": {
        overflow: "hidden",
      },
    },
  });
});

export default useGlobalStyles;
