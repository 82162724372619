import { AssessorStandard, BaseResponse, Standard } from "@app/models";
import axiosBaseQuery from "@app/rtk-query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

export interface StandardListResponse {
  message: string;
  standards?: Standard[];
}

export interface CreateStandardsArg {
  assessorId: string | number;
  standards: Partial<AssessorStandard>[];
}

export interface AssessorStandardListResponse {
  message: string;
  standards: AssessorStandard[];
}

export interface UpdateAssessorStandardArg {
  assessorStandardId: string | number;
  standard: Partial<AssessorStandard>;
}

const standardQuery = createApi({
  reducerPath: "standardQuery",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Standard"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getStandardList: build.query<StandardListResponse, void>({
      query: () => ({
        method: "get",
        url: "/standards",
      }),
    }),
    getStandardsByAssessorId: build.query<
      AssessorStandardListResponse,
      number | string
    >({
      query: (assessorId) => ({
        method: "get",
        url: `/assessor-standards/${assessorId}`,
      }),
      providesTags: [{ type: "Standard", id: "AssessorStandardList" }],
    }),
    createAssessorStandards: build.mutation<
      AssessorStandardListResponse,
      CreateStandardsArg
    >({
      query: (data) => ({
        method: "post",
        url: `/assessor-standards/${data.assessorId}`,
        data: { standards: data.standards },
      }),
      invalidatesTags: [{ type: "Standard", id: "AssessorStandardList" }],
    }),
    updateAssessorStandard: build.mutation<
      AssessorStandardListResponse,
      UpdateAssessorStandardArg
    >({
      query: (data) => ({
        method: "patch",
        url: `/assessor-standards/${data.assessorStandardId}`,
        data: data.standard,
      }),
      invalidatesTags: [{ type: "Standard", id: "AssessorStandardList" }],
    }),
    deleteAssessorStandard: build.mutation<
      BaseResponse,
      Pick<UpdateAssessorStandardArg, "assessorStandardId">
    >({
      query: (data) => ({
        method: "delete",
        url: `/assessor-standards/${data.assessorStandardId}`,
      }),
      invalidatesTags: [{ type: "Standard", id: "AssessorStandardList" }],
    }),
  }),
});

export const {
  useGetStandardListQuery,
  useLazyGetStandardListQuery,
  useGetStandardsByAssessorIdQuery,
  useLazyGetStandardsByAssessorIdQuery,
  useCreateAssessorStandardsMutation,
  useUpdateAssessorStandardMutation,
  useDeleteAssessorStandardMutation,
} = standardQuery;

export default standardQuery;
