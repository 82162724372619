import { usePasswordDialog } from "@app/contexts";
import { useNotification } from "@app/hooks";
import NSButton from "@components/NSButton";
import NSTextField from "@components/NSTextField";
import { useUpdatePasswordMutation } from "@features/auth/authAPI";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { memo, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

const required = "This field is required";

const defaultValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const PasswordUpdate = () => {
  const { closePasswordDialog } = usePasswordDialog();
  const { showNotification } = useNotification();

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const [updatePassword, { isLoading, isSuccess, isError }] =
    useUpdatePasswordMutation();

  useEffect(() => {
    if (isError) {
      setError("currentPassword", { message: "Incorrect current password" });
    }
  }, [isError, setError]);

  useEffect(() => {
    if (isSuccess) {
      closePasswordDialog();
      showNotification("Password is updated successfully");
    }
  }, [isSuccess, closePasswordDialog, showNotification]);

  const onSubmit = useCallback(
    ({ currentPassword, newPassword }: typeof defaultValues) => {
      updatePassword({ currentPassword, newPassword });
    },
    [updatePassword]
  );

  return (
    <>
      <Box display="flex" alignItems="center" paddingRight={3}>
        <DialogTitle className="flex-1">Update password</DialogTitle>
        <IconButton
          edge="end"
          size="small"
          className="ml-3"
          onClick={closePasswordDialog}
          disabled={isLoading}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <Controller
          control={control}
          name="currentPassword"
          rules={{ required }}
          render={({ field }) => {
            return (
              <NSTextField
                label="Current password"
                error={!!errors.currentPassword}
                helperText={errors.currentPassword?.message}
                type="password"
                {...field}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="newPassword"
          rules={{ required }}
          render={({ field }) => {
            return (
              <NSTextField
                label="New password"
                error={!!errors.newPassword}
                helperText={errors.newPassword?.message}
                type="password"
                {...field}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="confirmPassword"
          rules={{
            required,
            validate: (value) => {
              if (getValues("newPassword") !== value) {
                return "Incorrect password confirmation";
              }
              return true;
            },
          }}
          render={({ field }) => {
            return (
              <NSTextField
                label="Confirm password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                type="password"
                {...field}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions className="pl-6 pr-6 pb-5">
        <NSButton
          color="primary"
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </NSButton>
      </DialogActions>
    </>
  );
};

export default memo(PasswordUpdate);
