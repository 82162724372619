import { memo } from "react";
import { ReactElement } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ERAInfo from "./pages/ERAInfo";
import ERAList from "./pages/ERAList";
import NewERA from "./pages/NewERA";

const ERA = (): ReactElement => {
  return (
    <Switch>
      <Route exact path="/era/list" component={ERAList} />
      <Route exact path="/era/info/:id" component={ERAInfo} />
      <Route exact path="/era/new" component={NewERA} />
      <Route>
        <Redirect to="/era/list" />
      </Route>
    </Switch>
  );
};

export default memo(ERA);
