import { PageSizeOptions } from "@app/constants";
import { useAppSelector } from "@app/hooks";
import NSBreadcrumbs from "@components/NSBreadcrumbs";
import NSDataGrid from "@components/NSDataGrid";
import NSFab from "@components/NSFab";
import { useGetUserListQuery } from "@features/user/userAPI";
import AddIcon from "@material-ui/icons/Add";
import {
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";

const makeRenderCell = (field: string) => {
  const renderCell = (params: GridCellParams) => (
    <Link to={`/admin/user/info/${params.id}`} className="color-inherit">
      {params.row[field]}
    </Link>
  );
  return renderCell;
};

const useUserListColumns = () => {
  const roleList = useAppSelector((state) => state.general.roles);

  const columns = useMemo<GridColDef[]>(() => {
    const defaultCols: GridColDef[] = [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        width: 80,
        filterable: false,
        disableColumnMenu: true,
        renderCell: makeRenderCell("id"),
      },
      {
        field: "name",
        headerName: "Name",
        width: 150,
        renderCell: makeRenderCell("name"),
      },
      {
        field: "email",
        headerName: "Email",
        width: 300,
      },
      {
        field: "roleId",
        headerName: "Role",
        width: 120,
      },
    ];
    if (!roleList) {
      return defaultCols;
    }
    const valueOptions = roleList.map((r) => ({
      value: r.id,
      label: r.name,
    }));
    return defaultCols.map((col) => {
      if (col.field === "roleId") {
        return {
          ...col,
          type: "singleSelect",
          valueOptions,
          valueGetter: (params: GridValueGetterParams) =>
            valueOptions.find((opt) => opt.value === params.row.roleId)?.label,
        };
      }
      return col;
    });
  }, [roleList]);

  return columns;
};

const UserList = () => {
  const columns = useUserListColumns();
  const [pageSize, setPageSize] = useState(PageSizeOptions[0]);
  const history = useHistory();

  const { data: queryResponse, isLoading, isFetching } = useGetUserListQuery();

  const goToCreateUser = useCallback(() => {
    history.push("/admin/user/new");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [history]);

  return (
    <>
      <NSBreadcrumbs isLoading={!isLoading && isFetching} />
      <NSDataGrid
        loading={isLoading}
        rows={queryResponse?.users || []}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={PageSizeOptions}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
      />
      <NSFab onClick={goToCreateUser}>
        <AddIcon />
      </NSFab>
    </>
  );
};

export default UserList;
