import { AxiosInstance } from "@app/controllers";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { resetAuth } from "@features/auth/authSlice";
import { useEffect, useState } from "react";

const useAuthAxios = () => {
  const token = useAppSelector((state) => state.auth.token);
  const dispatch = useAppDispatch();

  const [done, setDone] = useState(false);

  useEffect(() => {
    const setUp = () => {
      AxiosInstance.init();
      AxiosInstance.addUnauthorizedInterceptor(() => {
        dispatch(resetAuth());
      });
    };
    const cleanUp = () => {
      AxiosInstance.cancel();
      AxiosInstance.ejectUnauthorizedInterceptor();
    };
    if (!token) {
      cleanUp();
      setDone(false);
    } else {
      setUp();
      setDone(true);
    }
    return () => {
      cleanUp();
    };
  }, [token, dispatch]);

  return done;
};

export default useAuthAxios;
