import { useNotification } from "@app/hooks";
import { ERA } from "@app/models";
import buildForm, { convertLabelToId } from "@components/FormBuilder";
import NSButton from "@components/NSButton";
import NSFormToolbar from "@components/NSFormToolbar";
import { useGetERAQuery, useUpdateERAMutation } from "@features/era/eraAPI";
import { Box, Paper } from "@material-ui/core";
import useERAFormDataPreparation from "@pages/ERA/hooks/useERAFormDataPreparation";
import useERAFormDefinition from "@pages/ERA/hooks/useERAFormDefinition";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

const ERAInfo: FC = () => {
  const history = useHistory();
  const params = useParams<Record<"id", string>>();
  const id = useMemo(() => Number(params.id), [params.id]);

  const { showNotification } = useNotification();

  const { data, isFetching, isError } = useGetERAQuery(id);
  const [era, setEra] = useState<ERA>();
  const { formFields, formSections } = useERAFormDefinition();
  const { prepareDataToUpdate } = useERAFormDataPreparation(formFields);
  const [
    updateERA,
    {
      data: updateResponse,
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateERAMutation();

  const {
    control,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const setFormData = useCallback(
    (data: ERA & Record<string, any>) => {
      formFields.forEach((f) => {
        if (!f.name) {
          return;
        }
        setValue(f.name, data[f.name] ?? "");
      });
    },
    [setValue, formFields]
  );

  useEffect(() => {
    if (isFetching || !data?.era) {
      return;
    }
    setEra(data.era);
    setFormData(data.era);
  }, [isFetching, setFormData, data]);

  useEffect(() => {
    if (isError) {
      showNotification("Record not found.", "error");
      history.push("/era/list");
    }
  }, [history, isError, showNotification]);

  useEffect(() => {
    if (isUpdateSuccess || isUpdateError) {
      if (updateResponse?.era) {
        showNotification("ERA is updated successfully");
        setEra(updateResponse.era);
        setFormData(updateResponse.era);
        return;
      }
      showNotification(
        updateResponse?.message ||
          (updateError as any)?.error.message ||
          "Unknown error! Cannot update this user.",
        "error"
      );
    }
  }, [
    history,
    isUpdateError,
    isUpdateSuccess,
    setFormData,
    showNotification,
    updateError,
    updateResponse,
  ]);

  const submitUpdateRequest = useCallback(
    (data) => {
      if (!era?.id) {
        return;
      }
      const preparedData = prepareDataToUpdate(
        { ...data, id: era.id },
        { originalData: era }
      );
      // const missingFields = validateMandatoryFields(data, data.status);
      // if (missingFields.length > 0) {
      //   showNotification(
      //     "Mandatory fields are missing, please fill in all of them",
      //     "error"
      //   );
      //   missingFields.forEach((fieldName, idx) => {
      //     setTimeout(() => {
      //       setError(
      //         fieldName,
      //         {
      //           type: "required",
      //           message: "This field is required",
      //         },
      //         {
      //           shouldFocus: idx === 0,
      //         }
      //       );
      //     });
      //   });
      //   return;
      // }
      updateERA(preparedData);
    },
    [era, prepareDataToUpdate, updateERA]
  );

  return (
    <Box display="flex" flexDirection="column">
      <NSFormToolbar title="ERA details" loading={isFetching}>
        {!isFetching && (
          <>
            <NSButton
              loading={isUpdating}
              color="primary"
              onClick={handleSubmit(submitUpdateRequest)}
            >
              Update
            </NSButton>
            {/* <NSButton className="pl-2 pr-2 ml-3" onClick={handleShowMoreClick}>
              <MoreHorizIcon />
            </NSButton>
            <Popover
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleShowMoreClose}
              PaperProps={{
                style: {
                  minWidth: 100,
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List dense>
                <ListItem
                  button
                  onClick={submitDeletionRequest}
                  className="color-error"
                >
                  DELETE ASSESSOR
                </ListItem>
              </List>
            </Popover> */}
          </>
        )}
      </NSFormToolbar>
      <Box display="flex" overflow="auto">
        {formSections.map((fs, idx) => {
          const sectionId = convertLabelToId(`era info ${idx}`);
          return (
            <Box
              id={sectionId}
              key={sectionId}
              component={Paper}
              p={2}
              style={
                isFetching || isUpdating
                  ? { pointerEvents: "none", opacity: 0.7 }
                  : undefined
              }
            >
              {buildForm({ control, errors, sections: fs })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ERAInfo;
