import { Nullable } from "@app/models";
import { alpha, Box, makeStyles, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import { memo, ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      width: `calc(50% - ${theme.spacing(2)}px)`,
      margin: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        width: `calc(33.33% - ${theme.spacing(2)}px)`,
        "&:hover": {
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
        },
        "&:active": {
          opacity: 0.7,
        },
      },
      [theme.breakpoints.up("lg")]: {
        width: `calc(25% - ${theme.spacing(2)}px)`,
      },
    },
    link: {
      color: theme.palette.text.primary,
      width: "100%",
      textDecoration: "none",
    },
  }),
  {
    classNamePrefix: "SummaryCard",
  }
);

export const SummaryCardsContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Box display="flex" flexWrap="wrap" m={-1}>
      {children}
    </Box>
  );
};

interface SummaryCardProps extends LinkProps {
  value?: Nullable<string | number>;
  description?: Nullable<string | number>;
  valueClass?: string;
}

const SummaryCard = ({
  value,
  description,
  valueClass,
  to,
  ...linkProps
}: SummaryCardProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Link to={to} className={classes.link} {...linkProps}>
        <Box p={3}>
          <Typography>{description}</Typography>
          <Typography variant="h4" className={clsx("text-bold", valueClass)}>
            {value}
          </Typography>
        </Box>
      </Link>
    </Paper>
  );
};

export default memo(SummaryCard);
