import { AssessorFormTabProvider } from "../contexts/AssessorFormTab";
import NewAssessorForm from "./NewAssessorForm";

const NewAssessor = () => {
  return (
    <AssessorFormTabProvider>
      <NewAssessorForm />
    </AssessorFormTabProvider>
  );
};

export default NewAssessor;
