import ReactDOM from "react-dom";
import App from "./pages/App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import ReactiveViewport from "@components/ReactiveViewport";
import { ThemeModeProvider } from "@components/ThemeMode";

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ReactiveViewport />
      <ThemeModeProvider>
        <App />
      </ThemeModeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
