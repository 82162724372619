import { PasswordDialogProvider } from "@app/contexts";
import { useDialog, useIsAdmin } from "@app/hooks";
import Layout from "@components/Layout";
import useLoadingIndicator from "@components/LoadingIndicator";
import Admin from "@pages/Admin";
import Assessor from "@pages/Assessor";
import AssessorConflict from "@pages/AssessorConflict";
import ERA from "@pages/ERA";
import Home from "@pages/Home";
import Login from "@pages/Login";

import PasswordUpdateDialog from "@pages/Profile/PasswordUpdateDialog";
import Qualification from "@pages/Qualification";
import { ReactElement, useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import AppSocketProvider from "./AppSocket";
import useAuthAxios from "./hooks/useAuthAxios";
import useRoutesGuard from "./hooks/useRoutesGuard";
import Initialization from "./Initialization";

const GuestRoutes = (): ReactElement => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route>
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};

const MemberRoutes = () => {
  const done = useAuthAxios();
  const isAdmin = useIsAdmin();
  const { open, openDialog, closeDialog } = useDialog();

  /**
   * Only enable these routes for `Admin`
   */
  const adminRoutes = useMemo(() => {
    if (!isAdmin) {
      return [];
    }
    return [
      {
        path: "/admin",
        component: Admin,
      },
    ];
  }, [isAdmin]);

  if (!done) {
    return null;
  }

  return (
    <AppSocketProvider>
      <Initialization>
        <PasswordDialogProvider
          value={{
            isPasswordDialogOpened: open,
            openPasswordDialog: openDialog,
            closePasswordDialog: closeDialog,
          }}
        >
          <Layout>
            <PasswordUpdateDialog />
            <Switch>
              {adminRoutes.map(({ path, component }) => (
                <Route key={path} path={path} component={component} />
              ))}
              <Route path="/home" component={Home} />
              <Route path="/assessor" component={Assessor} />
              <Route path="/assessor-conflict" component={AssessorConflict} />
              <Route
                path={["/qualification", "/qualification-level"]}
                component={Qualification}
              />
              <Route path="/era" component={ERA} />
              <Route>
                <Redirect to="/home" />
              </Route>
            </Switch>
          </Layout>
        </PasswordDialogProvider>
      </Initialization>
    </AppSocketProvider>
  );
};

const AppRouter = (): ReactElement => {
  const allowedRoute = useRoutesGuard();
  const { show, hide } = useLoadingIndicator();

  const routeList = useMemo(() => {
    switch (allowedRoute) {
      case "guest":
        return <GuestRoutes />;
      case "member":
        return <MemberRoutes />;
      default:
        return null;
    }
  }, [allowedRoute]);

  useEffect(() => {
    if (!allowedRoute) {
      show("dark");
    } else {
      hide();
    }
  }, [show, hide, allowedRoute]);

  return <Router>{routeList}</Router>;
};

export default AppRouter;
