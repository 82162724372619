import useThemeMode from "@components/ThemeMode";
import logo from "@assets/logo.png";
import logoLight from "@assets/logo-light.png";
import { ImgHTMLAttributes, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

export interface LogoProps
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> {
  autoChangeWithThemeMode?: boolean;
  /**
   * The logo will be rendered base on `variant` if `autoChangeWithThemeMode === false`
   */
  variant?: "light-content" | "dark-content";
}

const useStyles = makeStyles(
  () => ({
    root: {
      objectFit: "contain",
      maxHeight: "100%",
      maxWidth: "100%",
      paddingTop: 6,
    },
  }),
  {
    classNamePrefix: "Logo",
  }
);

const Logo = ({
  className,
  autoChangeWithThemeMode = true,
  variant = "dark-content",
  ...props
}: LogoProps) => {
  const { mode } = useThemeMode();
  const classes = useStyles();

  const src = useMemo(() => {
    const logoVariant = autoChangeWithThemeMode ? mode : variant;
    switch (logoVariant) {
      case "light":
      case "light-content":
        return logo;
      case "dark":
      case "dark-content":
      default:
        return logoLight;
    }
  }, [mode, autoChangeWithThemeMode, variant]);

  return <img {...props} className={clsx(classes.root, className)} src={src} />;
};

export default Logo;
