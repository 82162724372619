import { useQueryParams } from "@app/hooks";
import { staticFormTabs } from "@pages/Assessor/hooks/useAssessorFormDefinition";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import AssessorFormTabContext from "./AssessorFormTabContext";

const validTabValues = staticFormTabs.map((_, idx) => idx);

const AssessorFormTabProvider: FC = ({ children }) => {
  const queryParams = useQueryParams();
  const history = useHistory();

  const prevTab = useRef<number>();
  const prevHash = useRef<string | undefined>();

  const changeTab = useCallback(
    (nextTab: number, hash?: string) => {
      if (prevTab.current === nextTab && prevHash.current === hash) {
        return;
      }
      prevTab.current = nextTab;
      prevHash.current = hash;
      history.push(
        `${window.location.pathname}?t=${nextTab}${hash ? "#" + hash : ""}`
      );
    },
    [history]
  );

  const tab = useMemo(() => {
    const numericT = Number(queryParams.get("t"));
    if (validTabValues.includes(numericT)) {
      return numericT;
    }
    return -1;
  }, [queryParams]);

  useEffect(() => {
    if (tab === -1) {
      changeTab(0);
    }
  }, [changeTab, tab]);

  return (
    <AssessorFormTabContext.Provider value={{ tab, changeTab }}>
      {children}
    </AssessorFormTabContext.Provider>
  );
};

export default AssessorFormTabProvider;
