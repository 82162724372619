import { createContext, ReactNode } from "react";

export interface ConfirmationArg {
  title: string;
  content: string | ReactNode;
  onConfirm?: () => any;
  onReject?: () => any;
}

export interface ConfirmationContextValue {
  isShowingConfirmation: boolean;
  confirmation: ConfirmationArg | null;
  showConfirmation: (arg: ConfirmationArg) => void;
  closeConfirmation: () => void;
}

export const ConfirmationContext = createContext<ConfirmationContextValue>({
  isShowingConfirmation: false,
  confirmation: null,
  showConfirmation: () => null,
  closeConfirmation: () => null,
});
