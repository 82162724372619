import { getDataPatch, isValidDate } from "@app/helpers";
import { useAppSelector } from "@app/hooks";
import { User } from "@app/models";
import {
  FormDataFieldType,
  FormDataType,
  FormDateField,
  FormFieldType,
  FormSectionType,
} from "@components/FormBuilder";
import { CreateUserArg, UpdateUserArg } from "@features/user/userAPI";
import { format, isValid } from "date-fns";
import { cloneDeep, pick } from "lodash";
import { useMemo } from "react";

const authenticationFields: FormFieldType[] = [
  {
    name: "name",
    label: "Name",
    type: "text",
    rules: { required: "Name is required" },
  },
  { type: "blank" },
  { type: "blank" },
  {
    name: "email",
    label: "Email",
    type: "email",
    rules: { required: "Email is required" },
  },
  {
    name: "roleId",
    label: "Role",
    type: "select",
    selectOptions: [],
    rules: { required: "Role is required" },
  },
  { type: "blank" },
  {
    name: "password",
    label: "Password",
    type: "password",
    rules: { required: "Password is required" },
  },
  {
    name: "retypedPassword",
    label: "Retyped password",
    type: "password",
    rules: { required: "Retyped password is required" },
  },
  { type: "blank" },
];

const personalInfoFields: FormFieldType[] = [
  {
    name: "dob",
    label: "Date of birth",
    type: "date",
    rules: {
      validate: (value) => {
        if (value && !isValidDate(value, { disableFuture: true })) {
          return "Invalid date of birth";
        }
        return true;
      },
    },
    disableFuture: true,
  },
  {
    name: "tel",
    label: "Phone number",
    type: "text",
  },
];

export const formSections: FormSectionType[] = [
  {
    label: "Authentication",
    fields: authenticationFields,
  },
  {
    label: "Personal Information",
    fields: personalInfoFields,
  },
];

const formSectionsWithoutPassword: FormSectionType[] = [
  {
    label: "Authentication",
    fields: authenticationFields.filter(
      (f) =>
        f.type === "blank" ||
        f.type === "title" ||
        f.type === "button" ||
        f.type === "custom" ||
        (f.name !== "password" && f.name !== "retypedPassword")
    ),
  },
  {
    label: "Personal Information",
    fields: personalInfoFields,
  },
];

const getFormFields = (props?: { withoutPassword: boolean } | undefined) => {
  const fieldList = [
    ...(props?.withoutPassword
      ? authenticationFields.filter(
          (f) =>
            f.type === "blank" ||
            f.type === "title" ||
            f.type === "button" ||
            f.type === "custom" ||
            (f.name !== "password" && f.name !== "retypedPassword")
        )
      : authenticationFields),
    ...personalInfoFields,
  ] as FormDataFieldType[];
  return fieldList.filter((f) => f.name).map((f) => f.name);
};

const getDateFields = () => {
  const fields = [...authenticationFields, ...personalInfoFields].filter(
    (f) => f.type === "date"
  ) as FormDateField[];
  return fields.map((f) => f.name);
};

/**
 * Get fields without sections
 */
export const formFields = getFormFields();

export const useUserFormSections = (
  props?:
    | {
        withoutPassword: boolean;
      }
    | undefined
) => {
  const roleList = useAppSelector((state) => state.general.roles);
  const sections = useMemo<FormSectionType[]>(() => {
    const currentSections = props?.withoutPassword
      ? formSectionsWithoutPassword
      : formSections;
    return currentSections.map((sec) => {
      if (sec.label === "Authentication") {
        return {
          ...sec,
          fields: sec.fields.map((secField) => {
            if (secField.type === "select" && secField.name === "roleId") {
              return {
                ...secField,
                selectOptions: roleList,
              };
            }
            return secField;
          }),
        } as FormSectionType;
      }
      return sec;
    });
  }, [props?.withoutPassword, roleList]);

  return sections;
};

/**
 * Data preparation functions
 */

const prepareData = <T>(
  formData: FormDataType,
  options?: { withoutPassword?: boolean; withoutId?: boolean }
): T => {
  const dateFields = getDateFields();
  const data = cloneDeep(formData);
  dateFields.forEach((fieldName) => {
    if (data[fieldName] === undefined) {
      return;
    }
    data[fieldName] = isValid(new Date(data[fieldName]))
      ? format(new Date(data[fieldName]), "yyyy-MM-dd")
      : null;
  });
  if (options?.withoutPassword) {
    delete data.password;
    delete data.retypedPassword;
  }
  if (options?.withoutId) {
    delete data.id;
  }
  return data as T;
};

export const prepareDataToCreate = (formData: FormDataType): CreateUserArg => {
  const preparedData = prepareData<CreateUserArg>(formData, {
    withoutId: true,
  });
  return preparedData;
};

export const prepareDataToUpdate = (
  formData: FormDataType & { id: string | number },
  options: { originalData: User; withoutPassword?: boolean }
): UpdateUserArg => {
  const preparedData = prepareData<User>(
    formData,
    pick(options, ["withoutPassword"])
  );
  const dataPatch = getDataPatch(options.originalData, preparedData);
  return { ...dataPatch, id: formData.id } as UpdateUserArg;
};
